import { Menu } from "@mui/icons-material";
import { Avatar, Badge, Box, Button, ButtonBase, Collapse, Fab, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Tooltip, Typography } from "@mui/material"



import { useSelector, useDispatch } from 'react-redux'

import Profile from "./Profile";

import Logo from "./Logo";
import { Link, useLocation } from "react-router-dom";
import { CenteredBox } from "../OneViewBox";
import { USER_ROLES } from "../../../utils/constants";

const headerStyle = (theme) => ({
    width: "100%",
    position: "sticky",

    top: "0px",
    display: "flex",
    alignItems: "center",
    pt: 3,
    pb: 3,
    pr: 7,
    pl: 7,
    background: ` linear-gradient(180deg, #2D3B67 33.44%, #1D2744 219.5%)`,
    borderBottom: "1px solid " + theme.palette.primary.main,
    zIndex: 111,
    borderRadius: "0px",
    height: "75px",
    [theme.breakpoints.down('lg')]: {
        height: "60px",
    }


})
const logoContainer = (theme) => ({ width: "100%", top: "0px", backgroundColor: theme.palette.light.main })
const Header = () => {
    const location = useLocation()
    const user = useSelector(state => state.user)
    return (
        <Paper elevation={0} sx={headerStyle}>
            <CenteredBox>
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                    <Box>
                        <Link to='/' sx={logoContainer}>
                            <Logo />
                        </Link>
                    </Box>

                    <Profile />
                </Box>
            </CenteredBox>
        </Paper>
    )
}
export default Header