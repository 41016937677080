import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { Avatar, Badge, Box, Button, ButtonBase, Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, MenuItem, MenuList, styled, useTheme } from '@mui/material';
import { AccountCircle, ArrowDownward, ArrowDownwardOutlined, ArrowDropDown, Email, KeyboardArrowDown, Label, People, PersonOutline, PictureAsPdf } from '@mui/icons-material';
// import { center } from '../../assets/css/theme/common';
import { useDispatch, useSelector } from 'react-redux';
import { center } from '../../../assets/css/theme/common';
import { findNameByRole } from '../../../utils/helper';
import SubmitButton from '../../button/SubmitButton';
import { signOutAction } from '../../../store/actions/userReducerAction';
import { openModal } from '../../../store/actions/modalAction';
import ResetTokenController from '../../../pages/reset-token/ResetTokenController';
import { CenteredBox } from './boxes';
import { Link } from 'react-router-dom';


const ProfileButton = styled(ButtonBase)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    lineHeight: "100%",
    padding: theme.spacing(2),

    background: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius

}));

export default function Profile() {
    const { user } = useSelector(state => state)
    const theme =useTheme()
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onResetButtonClick = () => {
        dispatch(openModal(<ResetTokenController />, "xs", false, 'reset-token'))
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <>



            <ProfileButton p={0} aria-describedby={id} onClick={handleClick}>
              
                <CenteredBox sx={{ height: "32px", width: "32px", borderRadius: "100%",background:theme.palette.primary.secondary }}  disabled ml={1} fontWeight={600} variant='button'>
                    <PersonOutline color='light' />
                </CenteredBox>
                <Typography color="light.main" textTransform="capitalize" ml={3} fontWeight={600} variant='h3' >
                    {user.data.name}
                </Typography>

                <IconButton sx={{ height: "32px", width: "32px" }} disabled color="light.main"  ml={2} fontWeight={600} variant='button'>
                    <KeyboardArrowDown color="light" />
                </IconButton>
            </ProfileButton>

            <Popover
                // sx={(theme) => ({ marginTop: theme.spacing(3) })}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

            >
                <Box sx={{ width: "300px", maxWidth: "100%" }}>
                    <MenuList>
                        <MenuItem disableRipple disableTouchRipple disabled sx={{ opacity: 1 + "!important" }} >
                            <ListItemIcon>
                                <AccountCircle color='dark.main' fontSize="small" />
                            </ListItemIcon>
                            <ListItemText sx={{ textTransform: "capitalize" }} >{user.data.name}</ListItemText>

                        </MenuItem>
                        <MenuItem disableRipple disableTouchRipple disabled sx={{ opacity: 1 + "!important" }}>
                            <ListItemIcon>
                                <Email fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{user.data.email}</ListItemText>

                        </MenuItem>

                      

                        <Divider />

                        
                         <MenuItem  >
                            <Button  fullWidth variant="outlined"  LinkComponent={Link}  to="/admin/users">
                                <Typography variant='body1'>
                                   Users
                                </Typography>
                            </Button>

                        </MenuItem>
                        <MenuItem  >
                            <Button  fullWidth variant="outlined"  LinkComponent={Link}  to="/patient-details">
                                <Typography variant='body1'>
                                   Patients
                                </Typography>
                            </Button>

                        </MenuItem>
                        <MenuItem  >
                            <Button  fullWidth variant="outlined"  LinkComponent={Link}  to="/admin/topic">
                                <Typography variant='body1'>
                                   Topic
                                </Typography>
                            </Button>

                        </MenuItem>
                        <MenuItem  >
                            <Button  fullWidth variant="outlined"  LinkComponent={Link}  to="/admin/complain">
                                <Typography variant='body1'>
                                complains
                                </Typography>
                            </Button>

                        </MenuItem>
                       
                        <MenuItem>
                            <Button fullWidth variant="outlined" onClick={onResetButtonClick}>
                                <Typography variant='body1'>
                                    Reset Password
                                </Typography>
                            </Button>

                        </MenuItem>
                        <MenuItem>
                            <Button fullWidth variant="contained" title={""} onClick={() => { dispatch(signOutAction()) }}>
                                <Typography variant='body1'>
                                    Log Out
                                </Typography>
                            </Button>

                        </MenuItem>

                    </MenuList>
                </Box>
            </Popover>
        </>
    );
}