import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import {
  Box,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  styled,
  tableCellClasses,
  Select,
  MenuItem,
  InputAdornment,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import FittingChartArea from "./FittingChartArea";
import { closeModal } from "../../store/actions/modalAction";
import { Add } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  changeByteValue,
  changeDeviceSideSelection,
} from "../../store/actions/fittingAction";
import { DEVICES, LISTENING_SIDE } from "../../utils/patient.constants";
import { findMediunsInBulk } from "../../utils/helper";
import { WDRC_circuit_gain } from "../../utils/j10.helper";
import { ratios } from "../../utils/ratios";

const AdvanceFittingController = ({
  setData8L,
  data8L,
  setData8R,
  data8R,
  setData24L,
  data24L,
  setData24R,
  data24R,
  createDataFor24,
  createDataFor24to8,
  mutedHandle,
}) => {
  const { fittingLeft } = useSelector((state) => state);
  const { fitting } = useSelector((state) => state);
  const [side, setside] = useState(
    fitting.connected ? LISTENING_SIDE.RIGHT : LISTENING_SIDE.LEFT
  );

  const [channel, setChannel] = useState(0);
  const [selectIndex, setSelectIndex] = useState([null, null]);

  const handleClick = (index, index1) => {
    if (selectIndex[0] == index && selectIndex[1] == index1) {
      setSelectIndex([null, null]);
    } else {
      setSelectIndex([index, index1]);
    }
  };

  const handleIncreament = (setData3, data, currside) => {
    const n = channel == 0 ? 8 : 24;

    let ER = 20;
    let EK = 28;
    let LG = 36;
    let CK = 44;
    let CR = 52;
    let LK = 60;
    if (selectIndex[0] == 0 && selectIndex[1] == 0) {
      setData3((prev) => {
        const arr = [...prev];
        for (let j = 1; j <= n; j++) {
          for (let i = 1; i <= 4; i++) {
            arr[i][j] += 1;
          }
        }
        if (n == 8) {
          for (let j = 1; j <= n; j++) {
            const cal = ratios(arr[1][j], arr[2][j], arr[3][j], arr[4][j]);

            if (cal.ER > 0 && cal.ER <= 2) {
              dispatch(
                changeByteValue(ER + j - 1, Math.round(cal.ER * 10), currside)
              );
            }

            dispatch(changeByteValue(EK + j - 1, cal.Eknee, currside));
            dispatch(changeByteValue(LG + j - 1, cal.LG, currside));
            dispatch(changeByteValue(CK + j - 1, cal.Cknee, currside));
            if (cal.CR > 0 && cal.CR <= 3) {
              dispatch(
                changeByteValue(CR + j - 1, Math.round(cal.CR * 10), currside)
              );
            }
            dispatch(changeByteValue(LK + j - 1, cal.MPO, currside));
          }
        } else {
          let k = 2;
          for (let j = 1; j <= n; j++) {
            if (j == 1) {
              const cal = ratios(arr[1][j], arr[2][j], arr[3][j], arr[4][j]);
              if (cal.ER > 0 && cal.ER <= 2) {
                dispatch(
                  changeByteValue(ER + j - 1, Math.round(cal.ER * 10), currside)
                );
              }

              dispatch(changeByteValue(EK + j - 1, cal.Eknee, currside));
              dispatch(changeByteValue(LG + j - 1, cal.LG, currside));
              dispatch(changeByteValue(CK + j - 1, cal.Cknee, currside));
              if (cal.CR > 0 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(CR + j - 1, Math.round(cal.CR * 10), currside)
                );
              }
              dispatch(changeByteValue(LK + j - 1, cal.MPO, currside));
            } else if (j <= 20) {
              // let ERv = 0;
              // let Ekneev = 0;
              // let LGv = 0;
              // let Ckneev = 0;
              // let CRv = 0;
              // let MPOv = 0;

              let G50 = 0;
              let G60 = 0;
              let G80 = 0;
              let MPO = 0;

              for (let i = j; i < j + 3; i++) {
                G50 += arr[1][i];
                G60 += arr[2][i];
                G80 += arr[3][i];
                MPO += arr[4][i];
              }
              const cal = ratios(
                Math.round(G50 / 3),
                Math.round(G60 / 3),
                Math.round(G80 / 3),
                Math.round(MPO / 3)
              );

              if (cal.ER > 0 && cal.ER <= 2) {
                dispatch(
                  changeByteValue(ER + k - 1, Math.round(cal.ER * 10), currside)
                );
              }

              dispatch(
                changeByteValue(EK + k - 1, Math.round(cal.Eknee), currside)
              );
              dispatch(
                changeByteValue(LG + k - 1, Math.round(cal.LG), currside)
              );
              dispatch(
                changeByteValue(CK + k - 1, Math.round(cal.Cknee), currside)
              );
              if (cal.CR > 0 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(CR + k - 1, Math.round(cal.CR * 10), currside)
                );
              }
              dispatch(
                changeByteValue(LK + k - 1, Math.round(cal.MPO), currside)
              );
              k++;
              j += 2;
            } else {
              break;
            }
          }
        }
        return arr;
      });
    } else if (selectIndex[0] == 0) {
      setData3((prev) => {
        const arr = [...prev];
        arr[1][selectIndex[1]] += 1;
        arr[2][selectIndex[1]] += 1;
        arr[3][selectIndex[1]] += 1;
        arr[4][selectIndex[1]] += 1;

        if (selectIndex[1] > 22) {
          return arr;
        }

        if (n == 8) {
          const cal = ratios(
            arr[1][selectIndex[1]],
            arr[2][selectIndex[1]],
            arr[3][selectIndex[1]],
            arr[4][selectIndex[1]]
          );
          if (cal.ER > 0 && cal.ER <= 2) {
            dispatch(
              changeByteValue(
                ER + selectIndex[1] - 1,
                Math.round(cal.ER * 10),
                currside
              )
            );
          }
          dispatch(
            changeByteValue(EK + selectIndex[1] - 1, cal.Eknee, currside)
          );
          dispatch(changeByteValue(LG + selectIndex[1] - 1, cal.LG, currside));
          dispatch(
            changeByteValue(CK + selectIndex[1] - 1, cal.Cknee, currside)
          );
          if (cal.CR > 0 && cal.CR <= 3) {
            dispatch(
              changeByteValue(
                CR + selectIndex[1] - 1,
                Math.round(cal.CR * 10),
                currside
              )
            );
          }
          dispatch(changeByteValue(LK + selectIndex[1] - 1, cal.MPO, currside));
        } else {
          let ERV = 0;
          let EKV = 0;
          let LGV = 0;
          let CKV = 0;
          let CRV = 0;
          let MPOV = 0;
          let index = 0;

          if (selectIndex[1] > 22) {
            return;
          } else if (selectIndex[1] == 1) {
            const cal1 = ratios(
              arr[1][selectIndex[1]],
              arr[2][selectIndex[1]],
              arr[3][selectIndex[1]],
              arr[4][selectIndex[1]]
            );
            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;
          } else if (selectIndex[1] % 3 == 0) {
            const cal1 = ratios(
              Math.round(
                (arr[1][selectIndex[1]] +
                  arr[1][selectIndex[1] - 1] +
                  arr[1][selectIndex[1] + 1]) /
                  3
              ),
              Math.round(
                (arr[2][selectIndex[1]] +
                  arr[2][selectIndex[1] - 1] +
                  arr[2][selectIndex[1] + 1]) /
                  3
              ),
              Math.round(
                (arr[3][selectIndex[1]] +
                  arr[3][selectIndex[1] - 1] +
                  arr[3][selectIndex[1] + 1]) /
                  3
              ),
              Math.round(
                (arr[4][selectIndex[1]] +
                  arr[4][selectIndex[1] - 1] +
                  arr[4][selectIndex[1] + 1]) /
                  3
              )
            );

            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;

            index = selectIndex[1] / 3;
          } else if ((selectIndex[1] - 1) % 3 == 0) {
            const cal1 = ratios(
              Math.round(
                (arr[1][selectIndex[1]] +
                  arr[1][selectIndex[1] - 1] +
                  arr[1][selectIndex[1] - 2]) /
                  3
              ),
              Math.round(
                (arr[2][selectIndex[1]] +
                  arr[2][selectIndex[1] - 1] +
                  arr[2][selectIndex[1] - 2]) /
                  3
              ),
              Math.round(
                (arr[3][selectIndex[1]] +
                  arr[3][selectIndex[1] - 1] +
                  arr[3][selectIndex[1] - 2]) /
                  3
              ),
              Math.round(
                (arr[4][selectIndex[1]] +
                  arr[4][selectIndex[1] - 1] +
                  arr[4][selectIndex[1] - 2]) /
                  3
              )
            );

            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;
            index = (selectIndex[1] - 1) / 3;
          } else {
            const cal1 = ratios(
              Math.round(
                (arr[1][selectIndex[1]] +
                  arr[1][selectIndex[1] + 1] +
                  arr[1][selectIndex[1] + 2]) /
                  3
              ),
              Math.round(
                (arr[2][selectIndex[1]] +
                  arr[2][selectIndex[1] + 1] +
                  arr[2][selectIndex[1] + 2]) /
                  3
              ),
              Math.round(
                (arr[3][selectIndex[1]] +
                  arr[3][selectIndex[1] + 1] +
                  arr[3][selectIndex[1] + 2]) /
                  3
              ),
              Math.round(
                (arr[4][selectIndex[1]] +
                  arr[4][selectIndex[1] + 1] +
                  arr[4][selectIndex[1] + 2]) /
                  3
              )
            );

            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;

            index = (selectIndex[1] + 1) / 3;
          }

          if (ERV > 0 && ERV <= 2) {
            dispatch(
              changeByteValue(ER + index, Math.round(ERV * 10), currside)
            );
          }
          dispatch(changeByteValue(EK + index, EKV, currside));
          dispatch(changeByteValue(LG + index, LGV, currside));
          dispatch(changeByteValue(CK + index, CKV, currside));
          if (CRV > 0 && CRV <= 3) {
            dispatch(
              changeByteValue(CR + index, Math.round(CRV * 10), currside)
            );
          }
          dispatch(changeByteValue(LK + index, MPOV, currside));
        }

        return arr;
      });
    } else if (selectIndex[1] == 0) {
      setData3((prev) => {
        const arr = [...prev];
        for (let j = 1; j <= n; j++) {
          arr[selectIndex[0]][j] += 1;
        }

        if (n == 8) {
          for (let j = 1; j <= n; j++) {
            const cal = ratios(arr[1][j], arr[2][j], arr[3][j], arr[4][j]);

            if (cal.ER > 0 && cal.ER <= 2) {
              dispatch(
                changeByteValue(ER + j - 1, Math.round(cal.ER * 10), currside)
              );
            }

            dispatch(changeByteValue(EK + j - 1, cal.Eknee, currside));
            dispatch(changeByteValue(LG + j - 1, cal.LG, currside));
            dispatch(changeByteValue(CK + j - 1, cal.Cknee, currside));
            if (cal.CR > 0 && cal.CR <= 3) {
              dispatch(
                changeByteValue(CR + j - 1, Math.round(cal.CR * 10), currside)
              );
            }
            dispatch(changeByteValue(LK + j - 1, cal.MPO, currside));
          }
        } else {
          let k = 2;
          for (let j = 1; j <= n; j++) {
            if (j == 1) {
              const cal = ratios(arr[1][j], arr[2][j], arr[3][j], arr[4][j]);
              if (cal.ER > 0 && cal.ER <= 2) {
                dispatch(
                  changeByteValue(ER + j - 1, Math.round(cal.ER * 10), currside)
                );
              }

              dispatch(changeByteValue(EK + j - 1, cal.Eknee, currside));
              dispatch(changeByteValue(LG + j - 1, cal.LG, currside));
              dispatch(changeByteValue(CK + j - 1, cal.Cknee, currside));
              if (cal.CR > 0 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(CR + j - 1, Math.round(cal.CR * 10), currside)
                );
              }
              dispatch(changeByteValue(LK + j - 1, cal.MPO, currside));
            } else if (j <= 20) {
              // let ERv = 0;
              // let Ekneev = 0;
              // let LGv = 0;
              // let Ckneev = 0;
              // let CRv = 0;
              // let MPOv = 0;

              let G50 = 0;
              let G60 = 0;
              let G80 = 0;
              let MPO = 0;

              for (let i = j; i < j + 3; i++) {
                G50 += arr[1][i];
                G60 += arr[2][i];
                G80 += arr[3][i];
                MPO += arr[4][i];
              }
              const cal = ratios(
                Math.round(G50 / 3),
                Math.round(G60 / 3),
                Math.round(G80 / 3),
                Math.round(MPO / 3)
              );

              if (cal.ER > 0 && cal.ER <= 2) {
                dispatch(
                  changeByteValue(ER + k - 1, Math.round(cal.ER * 10), currside)
                );
              }

              dispatch(
                changeByteValue(EK + k - 1, Math.round(cal.Eknee), currside)
              );
              dispatch(
                changeByteValue(LG + k - 1, Math.round(cal.LG), currside)
              );
              dispatch(
                changeByteValue(CK + k - 1, Math.round(cal.Cknee), currside)
              );
              if (cal.CR > 0 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(CR + k - 1, Math.round(cal.CR * 10), currside)
                );
              }
              dispatch(
                changeByteValue(LK + k - 1, Math.round(cal.MPO), currside)
              );
              k++;
              j += 2;
            } else {
              break;
            }
          }
        }

        return arr;
      });
    } else {
      setData3((prev) => {
        const arr = [...prev];
        arr[selectIndex[0]][selectIndex[1]] += 1;
        if (selectIndex[1] > 22) {
          return arr;
        }

        if (n == 8) {
          const cal = ratios(
            arr[1][selectIndex[1]],
            arr[2][selectIndex[1]],
            arr[3][selectIndex[1]],
            arr[4][selectIndex[1]]
          );
          if (cal.ER > 0 && cal.ER <= 2) {
            dispatch(
              changeByteValue(
                ER + selectIndex[1] - 1,
                Math.round(cal.ER * 10),
                currside
              )
            );
          }
          dispatch(
            changeByteValue(EK + selectIndex[1] - 1, cal.Eknee, currside)
          );
          dispatch(changeByteValue(LG + selectIndex[1] - 1, cal.LG, currside));
          dispatch(
            changeByteValue(CK + selectIndex[1] - 1, cal.Cknee, currside)
          );
          if (cal.CR > 0 && cal.CR <= 3) {
            dispatch(
              changeByteValue(
                CR + selectIndex[1] - 1,
                Math.round(cal.CR * 10),
                currside
              )
            );
          }
          dispatch(changeByteValue(LK + selectIndex[1] - 1, cal.MPO, currside));
        } else {
          let ERV = 0;
          let EKV = 0;
          let LGV = 0;
          let CKV = 0;
          let CRV = 0;
          let MPOV = 0;
          let index = 0;

          if (selectIndex[1] > 22) {
            return;
          } else if (selectIndex[1] == 1) {
            const cal1 = ratios(
              arr[1][selectIndex[1]],
              arr[2][selectIndex[1]],
              arr[3][selectIndex[1]],
              arr[4][selectIndex[1]]
            );
            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;
          } else if (selectIndex[1] % 3 == 0) {
            const cal1 = ratios(
              Math.round(
                (arr[1][selectIndex[1]] +
                  arr[1][selectIndex[1] - 1] +
                  arr[1][selectIndex[1] + 1]) /
                  3
              ),
              Math.round(
                (arr[2][selectIndex[1]] +
                  arr[2][selectIndex[1] - 1] +
                  arr[2][selectIndex[1] + 1]) /
                  3
              ),
              Math.round(
                (arr[3][selectIndex[1]] +
                  arr[3][selectIndex[1] - 1] +
                  arr[3][selectIndex[1] + 1]) /
                  3
              ),
              Math.round(
                (arr[4][selectIndex[1]] +
                  arr[4][selectIndex[1] - 1] +
                  arr[4][selectIndex[1] + 1]) /
                  3
              )
            );

            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;

            index = selectIndex[1] / 3;
            console.log("this is index", index);
          } else if ((selectIndex[1] - 1) % 3 == 0) {
            const cal1 = ratios(
              Math.round(
                (arr[1][selectIndex[1]] +
                  arr[1][selectIndex[1] - 1] +
                  arr[1][selectIndex[1] - 2]) /
                  3
              ),
              Math.round(
                (arr[2][selectIndex[1]] +
                  arr[2][selectIndex[1] - 1] +
                  arr[2][selectIndex[1] - 2]) /
                  3
              ),
              Math.round(
                (arr[3][selectIndex[1]] +
                  arr[3][selectIndex[1] - 1] +
                  arr[3][selectIndex[1] - 2]) /
                  3
              ),
              Math.round(
                (arr[4][selectIndex[1]] +
                  arr[4][selectIndex[1] - 1] +
                  arr[4][selectIndex[1] - 2]) /
                  3
              )
            );

            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;
            index = (selectIndex[1] - 1) / 3;
          } else {
            const cal1 = ratios(
              Math.round(
                (arr[1][selectIndex[1]] +
                  arr[1][selectIndex[1] + 1] +
                  arr[1][selectIndex[1] + 2]) /
                  3
              ),
              Math.round(
                (arr[2][selectIndex[1]] +
                  arr[2][selectIndex[1] + 1] +
                  arr[2][selectIndex[1] + 2]) /
                  3
              ),
              Math.round(
                (arr[3][selectIndex[1]] +
                  arr[3][selectIndex[1] + 1] +
                  arr[3][selectIndex[1] + 2]) /
                  3
              ),
              Math.round(
                (arr[4][selectIndex[1]] +
                  arr[4][selectIndex[1] + 1] +
                  arr[4][selectIndex[1] + 2]) /
                  3
              )
            );

            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;
            index = (selectIndex[1] + 1) / 3;
          }

          if (ERV > 0 && ERV <= 2) {
            dispatch(
              changeByteValue(ER + index, Math.round(ERV * 10), currside)
            );
          }
          dispatch(changeByteValue(EK + index, EKV, currside));
          dispatch(changeByteValue(LG + index, LGV, currside));
          dispatch(changeByteValue(CK + index, CKV, currside));
          if (CRV > 0 && CRV <= 3) {
            dispatch(
              changeByteValue(CR + index, Math.round(CRV * 10), currside)
            );
          }
          dispatch(changeByteValue(LK + index, MPOV, currside));
        }
        return arr;
      });
    }
  };

  const handleDecreament = (setData3, data3, currside) => {
    const n = channel == 0 ? 8 : 24;
    let ER = 20;
    let EK = 28;
    let LG = 36;
    let CK = 44;
    let CR = 52;
    let LK = 60;
    if (selectIndex[0] == 0 && selectIndex[1] == 0) {
      setData3((arr) => {
        for (let j = 1; j <= n; j++) {
          for (let i = 1; i <= 4; i++) {
            if (arr[i][j] >= 1) arr[i][j] -= 1;
          }
        }

        if (n == 8) {
          for (let j = 1; j <= n; j++) {
            const cal = ratios(arr[1][j], arr[2][j], arr[3][j], arr[4][j]);

            if (cal.ER > 0 && cal.ER <= 2) {
              dispatch(
                changeByteValue(ER + j - 1, Math.round(cal.ER * 10), currside)
              );
            }

            dispatch(changeByteValue(EK + j - 1, cal.Eknee, currside));
            dispatch(changeByteValue(LG + j - 1, cal.LG, currside));
            dispatch(changeByteValue(CK + j - 1, cal.Cknee, currside));
            if (cal.CR > 0 && cal.CR <= 3) {
              dispatch(
                changeByteValue(CR + j - 1, Math.round(cal.CR * 10), currside)
              );
            }
            dispatch(changeByteValue(LK + j - 1, cal.MPO, currside));
          }
        } else {
          let k = 2;
          for (let j = 1; j <= n; j++) {
            if (j == 1) {
              const cal = ratios(arr[1][j], arr[2][j], arr[3][j], arr[4][j]);
              if (cal.ER > 0 && cal.ER <= 2) {
                dispatch(
                  changeByteValue(ER + j - 1, Math.round(cal.ER * 10), currside)
                );
              }

              dispatch(changeByteValue(EK + j - 1, cal.Eknee, currside));
              dispatch(changeByteValue(LG + j - 1, cal.LG, currside));
              dispatch(changeByteValue(CK + j - 1, cal.Cknee, currside));
              if (cal.CR > 0 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(CR + j - 1, Math.round(cal.CR * 10), currside)
                );
              }
              dispatch(changeByteValue(LK + j - 1, cal.MPO, currside));
            } else if (j <= 20) {
              // let ERv = 0;
              // let Ekneev = 0;
              // let LGv = 0;
              // let Ckneev = 0;
              // let CRv = 0;
              // let MPOv = 0;

              let G50 = 0;
              let G60 = 0;
              let G80 = 0;
              let MPO = 0;

              for (let i = j; i < j + 3; i++) {
                G50 += arr[1][i];
                G60 += arr[2][i];
                G80 += arr[3][i];
                MPO += arr[4][i];
              }
              const cal = ratios(
                Math.round(G50 / 3),
                Math.round(G60 / 3),
                Math.round(G80 / 3),
                Math.round(MPO / 3)
              );

              if (cal.ER > 0 && cal.ER <= 2) {
                dispatch(
                  changeByteValue(ER + k - 1, Math.round(cal.ER * 10), currside)
                );
              }

              dispatch(
                changeByteValue(EK + k - 1, Math.round(cal.Eknee), currside)
              );
              dispatch(
                changeByteValue(LG + k - 1, Math.round(cal.LG), currside)
              );
              dispatch(
                changeByteValue(CK + k - 1, Math.round(cal.Cknee), currside)
              );
              if (cal.CR > 0 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(CR + k - 1, Math.round(cal.CR * 10), currside)
                );
              }
              dispatch(
                changeByteValue(LK + k - 1, Math.round(cal.MPO), currside)
              );
              k++;
              j += 2;
            } else {
              break;
            }
          }
        }
        return [...arr];
      });
    } else if (selectIndex[0] == 0) {
      setData3((prev) => {
        const arr = [...prev];
        if (arr[1][selectIndex[1]] >= 1) {
          arr[1][selectIndex[1]] -= 1;
        }
        if (arr[2][selectIndex[1]] >= 1) {
          arr[2][selectIndex[1]] -= 1;
        }
        if (arr[3][selectIndex[1]] >= 1) {
          arr[3][selectIndex[1]] -= 1;
        }
        if (arr[4][selectIndex[1]] >= 1) {
          arr[4][selectIndex[1]] -= 1;
        }

        if (selectIndex[1] > 22) {
          return arr;
        }

        if (n == 8) {
          const cal = ratios(
            arr[1][selectIndex[1]],
            arr[2][selectIndex[1]],
            arr[3][selectIndex[1]],
            arr[4][selectIndex[1]]
          );
          if (cal.ER > 0 && cal.ER <= 2) {
            dispatch(
              changeByteValue(
                ER + selectIndex[1] - 1,
                Math.round(cal.ER * 10),
                currside
              )
            );
          }
          dispatch(
            changeByteValue(EK + selectIndex[1] - 1, cal.Eknee, currside)
          );
          dispatch(changeByteValue(LG + selectIndex[1] - 1, cal.LG, currside));
          dispatch(
            changeByteValue(CK + selectIndex[1] - 1, cal.Cknee, currside)
          );
          if (cal.CR > 0 && cal.CR <= 3) {
            dispatch(
              changeByteValue(
                CR + selectIndex[1] - 1,
                Math.round(cal.CR * 10),
                currside
              )
            );
          }
          dispatch(changeByteValue(LK + selectIndex[1] - 1, cal.MPO, currside));
        } else {
          let ERV = 0;
          let EKV = 0;
          let LGV = 0;
          let CKV = 0;
          let CRV = 0;
          let MPOV = 0;
          let index = 0;

          if (selectIndex[1] > 22) {
            return;
          } else if (selectIndex[1] == 1) {
            const cal1 = ratios(
              arr[1][selectIndex[1]],
              arr[2][selectIndex[1]],
              arr[3][selectIndex[1]],
              arr[4][selectIndex[1]]
            );
            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;
          } else if (selectIndex[1] % 3 == 0) {
            const cal1 = ratios(
              Math.round(
                (arr[1][selectIndex[1]] +
                  arr[1][selectIndex[1] - 1] +
                  arr[1][selectIndex[1] + 1]) /
                  3
              ),
              Math.round(
                (arr[2][selectIndex[1]] +
                  arr[2][selectIndex[1] - 1] +
                  arr[2][selectIndex[1] + 1]) /
                  3
              ),
              Math.round(
                (arr[3][selectIndex[1]] +
                  arr[3][selectIndex[1] - 1] +
                  arr[3][selectIndex[1] + 1]) /
                  3
              ),
              Math.round(
                (arr[4][selectIndex[1]] +
                  arr[4][selectIndex[1] - 1] +
                  arr[4][selectIndex[1] + 1]) /
                  3
              )
            );

            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;

            index = selectIndex[1] / 3;
          } else if ((selectIndex[1] - 1) % 3 == 0) {
            const cal1 = ratios(
              Math.round(
                (arr[1][selectIndex[1]] +
                  arr[1][selectIndex[1] - 1] +
                  arr[1][selectIndex[1] - 2]) /
                  3
              ),
              Math.round(
                (arr[2][selectIndex[1]] +
                  arr[2][selectIndex[1] - 1] +
                  arr[2][selectIndex[1] - 2]) /
                  3
              ),
              Math.round(
                (arr[3][selectIndex[1]] +
                  arr[3][selectIndex[1] - 1] +
                  arr[3][selectIndex[1] - 2]) /
                  3
              ),
              Math.round(
                (arr[4][selectIndex[1]] +
                  arr[4][selectIndex[1] - 1] +
                  arr[4][selectIndex[1] - 2]) /
                  3
              )
            );

            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;
            index = (selectIndex[1] - 1) / 3;
          } else {
            const cal1 = ratios(
              Math.round(
                (arr[1][selectIndex[1]] +
                  arr[1][selectIndex[1] + 1] +
                  arr[1][selectIndex[1] + 2]) /
                  3
              ),
              Math.round(
                (arr[2][selectIndex[1]] +
                  arr[2][selectIndex[1] + 1] +
                  arr[2][selectIndex[1] + 2]) /
                  3
              ),
              Math.round(
                (arr[3][selectIndex[1]] +
                  arr[3][selectIndex[1] + 1] +
                  arr[3][selectIndex[1] + 2]) /
                  3
              ),
              Math.round(
                (arr[4][selectIndex[1]] +
                  arr[4][selectIndex[1] + 1] +
                  arr[4][selectIndex[1] + 2]) /
                  3
              )
            );

            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;

            index = (selectIndex[1] + 1) / 3;
          }

          if (ERV > 0 && ERV <= 2) {
            dispatch(
              changeByteValue(ER + index, Math.round(ERV * 10), currside)
            );
          }
          dispatch(changeByteValue(EK + index, EKV, currside));
          dispatch(changeByteValue(LG + index, LGV, currside));
          dispatch(changeByteValue(CK + index, CKV, currside));
          if (CRV > 0 && CRV <= 3) {
            dispatch(
              changeByteValue(CR + index, Math.round(CRV * 10), currside)
            );
          }
          dispatch(changeByteValue(LK + index, MPOV, currside));
        }
        return [...arr];
      });
    } else if (selectIndex[1] == 0) {
      setData3((arr) => {
        for (let j = 1; j <= n; j++) {
          if (arr[selectIndex[0]][j] >= 1) {
            arr[selectIndex[0]][j] -= 1;
          }
        }

        if (n == 8) {
          for (let j = 1; j <= n; j++) {
            const cal = ratios(arr[1][j], arr[2][j], arr[3][j], arr[4][j]);

            if (cal.ER > 0 && cal.ER <= 2) {
              dispatch(
                changeByteValue(ER + j - 1, Math.round(cal.ER * 10), currside)
              );
            }

            dispatch(changeByteValue(EK + j - 1, cal.Eknee, currside));
            dispatch(changeByteValue(LG + j - 1, cal.LG, currside));
            dispatch(changeByteValue(CK + j - 1, cal.Cknee, currside));
            if (cal.CR > 0 && cal.CR <= 3) {
              dispatch(
                changeByteValue(CR + j - 1, Math.round(cal.CR * 10), currside)
              );
            }
            dispatch(changeByteValue(LK + j - 1, cal.MPO, currside));
          }
        } else {
          let k = 2;
          for (let j = 1; j <= n; j++) {
            if (j == 1) {
              const cal = ratios(arr[1][j], arr[2][j], arr[3][j], arr[4][j]);
              if (cal.ER > 0 && cal.ER <= 2) {
                dispatch(
                  changeByteValue(ER + j - 1, Math.round(cal.ER * 10), currside)
                );
              }

              dispatch(changeByteValue(EK + j - 1, cal.Eknee, currside));
              dispatch(changeByteValue(LG + j - 1, cal.LG, currside));
              dispatch(changeByteValue(CK + j - 1, cal.Cknee, currside));
              if (cal.CR > 0 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(CR + j - 1, Math.round(cal.CR * 10), currside)
                );
              }
              dispatch(changeByteValue(LK + j - 1, cal.MPO, currside));
            } else if (j <= 20) {
              // let ERv = 0;
              // let Ekneev = 0;
              // let LGv = 0;
              // let Ckneev = 0;
              // let CRv = 0;
              // let MPOv = 0;

              let G50 = 0;
              let G60 = 0;
              let G80 = 0;
              let MPO = 0;

              for (let i = j; i < j + 3; i++) {
                G50 += arr[1][i];
                G60 += arr[2][i];
                G80 += arr[3][i];
                MPO += arr[4][i];
              }
              const cal = ratios(
                Math.round(G50 / 3),
                Math.round(G60 / 3),
                Math.round(G80 / 3),
                Math.round(MPO / 3)
              );

              if (cal.ER > 0 && cal.ER <= 2) {
                dispatch(
                  changeByteValue(ER + k - 1, Math.round(cal.ER * 10), currside)
                );
              }

              dispatch(
                changeByteValue(EK + k - 1, Math.round(cal.Eknee), currside)
              );
              dispatch(
                changeByteValue(LG + k - 1, Math.round(cal.LG), currside)
              );
              dispatch(
                changeByteValue(CK + k - 1, Math.round(cal.Cknee), currside)
              );
              if (cal.CR > 0 && cal.CR <= 3) {
                dispatch(
                  changeByteValue(CR + k - 1, Math.round(cal.CR * 10), currside)
                );
              }
              dispatch(
                changeByteValue(LK + k - 1, Math.round(cal.MPO), currside)
              );
              k++;
              j += 2;
            } else {
              break;
            }
          }
        }
        return [...arr];
      });
    } else {
      setData3((prev) => {
        const arr = [...prev];
        if (arr[selectIndex[0]][selectIndex[1]] >= 1) {
          arr[selectIndex[0]][selectIndex[1]] -= 1;
        }

        if (selectIndex[1] > 22) {
          return arr;
        }

        if (n == 8) {
          const cal = ratios(
            arr[1][selectIndex[1]],
            arr[2][selectIndex[1]],
            arr[3][selectIndex[1]],
            arr[4][selectIndex[1]]
          );
          if (cal.ER > 0 && cal.ER <= 2) {
            dispatch(
              changeByteValue(
                ER + selectIndex[1] - 1,
                Math.round(cal.ER * 10),
                currside
              )
            );
          }
          dispatch(
            changeByteValue(EK + selectIndex[1] - 1, cal.Eknee, currside)
          );
          dispatch(changeByteValue(LG + selectIndex[1] - 1, cal.LG, currside));
          dispatch(
            changeByteValue(CK + selectIndex[1] - 1, cal.Cknee, currside)
          );
          if (cal.CR > 0 && cal.CR <= 3) {
            dispatch(
              changeByteValue(
                CR + selectIndex[1] - 1,
                Math.round(cal.CR * 10),
                currside
              )
            );
          }
          dispatch(changeByteValue(LK + selectIndex[1] - 1, cal.MPO, currside));
        } else {
          let ERV = 0;
          let EKV = 0;
          let LGV = 0;
          let CKV = 0;
          let CRV = 0;
          let MPOV = 0;
          let index = 0;

          if (selectIndex[1] > 22) {
            return;
          } else if (selectIndex[1] == 1) {
            const cal1 = ratios(
              arr[1][selectIndex[1]],
              arr[2][selectIndex[1]],
              arr[3][selectIndex[1]],
              arr[4][selectIndex[1]]
            );
            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;
          } else if (selectIndex[1] % 3 == 0) {
            const cal1 = ratios(
              Math.round(
                (arr[1][selectIndex[1]] +
                  arr[1][selectIndex[1] - 1] +
                  arr[1][selectIndex[1] + 1]) /
                  3
              ),
              Math.round(
                (arr[2][selectIndex[1]] +
                  arr[2][selectIndex[1] - 1] +
                  arr[2][selectIndex[1] + 1]) /
                  3
              ),
              Math.round(
                (arr[3][selectIndex[1]] +
                  arr[3][selectIndex[1] - 1] +
                  arr[3][selectIndex[1] + 1]) /
                  3
              ),
              Math.round(
                (arr[4][selectIndex[1]] +
                  arr[4][selectIndex[1] - 1] +
                  arr[4][selectIndex[1] + 1]) /
                  3
              )
            );

            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;

            index = selectIndex[1] / 3;
            console.log("this is index", index);
          } else if ((selectIndex[1] - 1) % 3 == 0) {
            const cal1 = ratios(
              Math.round(
                (arr[1][selectIndex[1]] +
                  arr[1][selectIndex[1] - 1] +
                  arr[1][selectIndex[1] - 2]) /
                  3
              ),
              Math.round(
                (arr[2][selectIndex[1]] +
                  arr[2][selectIndex[1] - 1] +
                  arr[2][selectIndex[1] - 2]) /
                  3
              ),
              Math.round(
                (arr[3][selectIndex[1]] +
                  arr[3][selectIndex[1] - 1] +
                  arr[3][selectIndex[1] - 2]) /
                  3
              ),
              Math.round(
                (arr[4][selectIndex[1]] +
                  arr[4][selectIndex[1] - 1] +
                  arr[4][selectIndex[1] - 2]) /
                  3
              )
            );

            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;
            index = (selectIndex[1] - 1) / 3;
          } else {
            const cal1 = ratios(
              Math.round(
                (arr[1][selectIndex[1]] +
                  arr[1][selectIndex[1] + 1] +
                  arr[1][selectIndex[1] + 2]) /
                  3
              ),
              Math.round(
                (arr[2][selectIndex[1]] +
                  arr[2][selectIndex[1] + 1] +
                  arr[2][selectIndex[1] + 2]) /
                  3
              ),
              Math.round(
                (arr[3][selectIndex[1]] +
                  arr[3][selectIndex[1] + 1] +
                  arr[3][selectIndex[1] + 2]) /
                  3
              ),
              Math.round(
                (arr[4][selectIndex[1]] +
                  arr[4][selectIndex[1] + 1] +
                  arr[4][selectIndex[1] + 2]) /
                  3
              )
            );

            ERV = cal1.ER;
            EKV = cal1.Eknee;
            LGV = cal1.LG;
            CKV = cal1.Cknee;
            CRV = cal1.CR;
            MPOV = cal1.MPO;
            index = (selectIndex[1] + 1) / 3;
          }

          if (ERV > 0 && ERV <= 2) {
            dispatch(
              changeByteValue(ER + index, Math.round(ERV * 10), currside)
            );
          }
          dispatch(changeByteValue(EK + index, EKV, currside));
          dispatch(changeByteValue(LG + index, LGV, currside));
          dispatch(changeByteValue(CK + index, CKV, currside));
          if (CRV > 0 && CRV <= 3) {
            dispatch(
              changeByteValue(CR + index, Math.round(CRV * 10), currside)
            );
          }
          dispatch(changeByteValue(LK + index, MPOV, currside));
        }
        return [...arr];
      });
    }
  };

  const [Selectitem, setSelectitem] = useState("10");
  const [link, setLink] = useState(false);
  const handleChange = (event) => {
    setSelectitem(event.target.value);
  };

  const dispatch = useDispatch();
  return (
    <Box>
      <Box maxHeight={"60vh"}>
        <Grid xs={12} md={12} container>
          <Grid md={5.7} xs={5.7}>
            <FittingChartArea
              mutedHandle={mutedHandle}
              fitting={fitting}
              // edgeVal={edgeVal}
            />
          </Grid>
          <Grid md={0.6} xs={0.6}>
            <Box
              sx={{
                marginTop: "20vh",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Select value={10} sx={{ height: "5vh", width: "5vw" }}>
                  <MenuItem value={10}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                    >
                      <path
                        d="M21.5 21H5.1C4.53995 21 4.25992 21 4.04601 20.891C3.85785 20.7951 3.70487 20.6422 3.60899 20.454C3.5 20.2401 3.5 19.9601 3.5 19.4V3M20.5 8L16.5811 12.1827C16.4326 12.3412 16.3584 12.4204 16.2688 12.4614C16.1897 12.4976 16.1026 12.5125 16.016 12.5047C15.9179 12.4958 15.8215 12.4458 15.6287 12.3457L12.3713 10.6543C12.1785 10.5542 12.0821 10.5042 11.984 10.4953C11.8974 10.4875 11.8103 10.5024 11.7312 10.5386C11.6416 10.5796 11.5674 10.6588 11.4189 10.8173L7.5 15"
                        stroke="#2D3B67"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.26419 11.3974L20.3044 4.5979M6.15183 9.40057L19.7209 3.12836M6.03947 7.40372L19.1374 1.65882"
                        stroke="#2D3B67"
                        stroke-width="0.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-dasharray="2 2"
                      />
                    </svg>
                  </MenuItem>
                </Select>
              </Box>
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box mr={1}>
                    <Typography
                      sx={{
                        color: "#2D3B67",
                        borderRadius: "86px",
                        width: "1.5vw",
                        height: "4px",
                        background: "#4A4A4A",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{
                        fontFamily: "League spartan",
                        color: "#808080",
                        fontStyle: "normal",
                        fontWeight: "400px",
                      }}
                    >
                      50dB
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box mr={1}>
                    <Typography
                      sx={{
                        color: "#2D3B67",
                        borderRadius: "86px",
                        width: "1.5vw",
                        height: "4px",
                        background: "#969696",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{
                        fontFamily: "League spartan",
                        color: "#808080",
                        fontStyle: "normal",
                        fontWeight: "400px",
                      }}
                    >
                      65dB
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box mr={1}>
                    <Typography
                      sx={{
                        color: "#2D3B67",
                        borderRadius: "86px",
                        width: "1.5vw",
                        height: "4px",
                        background: "#CCCCCC",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{
                        fontFamily: "League spartan",
                        color: "#808080",
                        fontStyle: "normal",
                        fontWeight: "400px",
                      }}
                    >
                      80dB
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid xs={5.7} md={5.7}>
            <FittingChartArea
              mutedHandle={mutedHandle}
              fitting={fittingLeft}
              // edgeVal={edgeVal}
            />
          </Grid>
        </Grid>
      </Box>

      <Grid xs={12} display={"flex"} justifyContent={"center"} width={"60vw"}>
        <Grid
          xs={5}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"row"}
        >
          {((side == LISTENING_SIDE.LEFT &&
            fittingLeft.device_type == DEVICES.BTE_PRIME) ||
            (side == LISTENING_SIDE.RIGHT &&
              fitting.device_type == DEVICES.BTE_PRIME)) && (
            <>
              <Typography
                sx={{
                  color: "#808080",
                  fontSize: "16px",
                  fontFamily: "League spartan",
                }}
              >
                Handles
              </Typography>
              <Box gap={1} ml={2} display={"flex"} alignItems={"center"}>
                <button
                  onClick={() => {
                    setChannel(0);
                    setSelectIndex([null, null]);
                  }}
                  variant="outlined"
                  style={{
                    height: "4vh",
                    width: "3vw",
                    fontSize: "16px",
                    cursor: "pointer",
                    fontFamily: "League spartan",
                    cursor: "pointer",
                    backgroundColor: channel == 0 ? "#EDF0F7" : "#fff",
                    border:
                      channel == 0 ? "1px solid #2D3B67" : "1px solid #CCCCCC",
                    borderRadius: "4px",
                    color: "#2D3B67",
                  }}
                >
                  <Typography variant="h6">8</Typography>
                </button>
                <button
                  onClick={() => {
                    setChannel(1);
                    setData24L((prev) => {
                      const arr = [...prev];
                      arr[1] = ["G50", ...createDataFor24(data8L[1])];
                      arr[4] = ["MPO", ...createDataFor24(data8L[4])];
                      return arr;
                    });

                    setData24R((prev) => {
                      const arr = [...prev];
                      arr[1] = ["G50", ...createDataFor24(data8R[1])];
                      arr[4] = ["MPO", ...createDataFor24(data8R[4])];
                      return arr;
                    });
                    setSelectIndex([null, null]);
                  }}
                  variant="outlined"
                  style={{
                    height: "4vh",
                    width: "3vw",
                    fontSize: "16px",
                    cursor: "pointer",
                    backgroundColor: channel == 1 ? "#EDF0F7" : "#fff",
                    fontFamily: "League spartan",
                    border:
                      channel == 1 ? "1px solid #2D3B67" : "1px solid #CCCCCC",
                    borderRadius: "4px",
                    color: "#2D3B67",
                  }}
                >
                  <Typography variant="h6">24</Typography>
                </button>
              </Box>
            </>
          )}
        </Grid>
        {fitting.connected && fittingLeft.connected && (
          <Grid
            xs={1}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {!link ? (
              <Button
                sx={{ border: "1px solid #DDDDDD", borderRadius: "8px" }}
                onClick={() => {
                  setLink(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="24"
                  viewBox="0 0 33 24"
                  fill="none"
                >
                  <path
                    d="M11.5 17H9.5C6.73858 17 4.5 14.7614 4.5 12C4.5 9.23858 6.73858 7 9.5 7H11.5M21.5 17H23.5C26.2614 17 28.5 14.7614 28.5 12C28.5 9.23858 26.2614 7 23.5 7H21.5"
                    stroke="#CCCCCC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setLink(false);
                }}
                sx={{
                  border: "1px solid #2D3B67",
                  borderRadius: "8px",
                  backgroundColor: "#EDF0F7",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="24"
                  viewBox="0 0 32 24"
                  fill="none"
                >
                  <path
                    d="M13 17H11C8.23858 17 6 14.7614 6 12C6 9.23858 8.23858 7 11 7H13M19 17H21C23.7614 17 26 14.7614 26 12C26 9.23858 23.7614 7 21 7H19M11 12L21 12"
                    stroke="#2D3B67"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Button>
            )}
          </Grid>
        )}
      </Grid>

      {channel == 0 && (
        <Box
          mt={2}
          sx={{
            height: "33vh",
            scrollbarWidth: "none",
            width: "86vw",
          }}
        >
          <Grid
            container
            xs={12}
            mt={2}
            mb={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {side == LISTENING_SIDE.LEFT && fitting.connected && (
              <Grid xs={0}>
                <Box
                  onClick={() => {
                    if (!link) {
                      setSelectIndex([null, null]);
                    }
                    setside(LISTENING_SIDE.RIGHT);
                    if (!fitting.device_type == DEVICES.BTE_PRIME) {
                      setChannel(0);
                    }
                  }}
                  sx={{
                    backgroundColor: "#C24747",
                    height: "18vh",
                    width: "2vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    width="11"
                    height="19"
                    viewBox="0 0 11 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 17.5L1.5 9.5L9.5 1.5"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Box>
              </Grid>
            )}
            <Grid pl={6} pr={6} xs={11.5}>
              {(side === LISTENING_SIDE.LEFT ? data8L : data8R)?.map(
                (arr, index) => (
                  <>
                    <Grid
                      container
                      xs={12}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      {arr?.map((val, index1) => (
                        <Grid xs={index1 == 0 ? 0.66 : 1.33}>
                          <button
                            disabled={
                              fitting.read_only || fittingLeft.read_only
                            }
                            onClick={() => {
                              handleClick(index, index1);
                            }}
                            style={{
                              // color:
                              //   side == LISTENING_SIDE.RIGHT
                              //     ? "#C24747"
                              //     : "#1265A1",

                              color:
                                side == LISTENING_SIDE.RIGHT
                                  ? index1 == 0 || index == 0
                                    ? "#C24747"
                                    : (selectIndex[0] == 0 &&
                                        selectIndex[1] == 0) ||
                                      (selectIndex[0] == 0 &&
                                        selectIndex[1] == index1) ||
                                      (selectIndex[0] == index &&
                                        selectIndex[1] == 0) ||
                                      (selectIndex[0] == index &&
                                        selectIndex[1] == index1)
                                    ? "#2D3B67"
                                    : "#808080"
                                  : index1 == 0 || index == 0
                                  ? "#1265A1"
                                  : (selectIndex[0] == 0 &&
                                      selectIndex[1] == 0) ||
                                    (selectIndex[0] == 0 &&
                                      selectIndex[1] == index1) ||
                                    (selectIndex[0] == index &&
                                      selectIndex[1] == 0) ||
                                    (selectIndex[0] == index &&
                                      selectIndex[1] == index1)
                                  ? "#2D3B67"
                                  : "#808080",

                              backgroundColor:
                                (selectIndex[0] == 0 && selectIndex[1] == 0) ||
                                (selectIndex[0] == 0 &&
                                  selectIndex[1] == index1) ||
                                (selectIndex[0] == index &&
                                  selectIndex[1] == 0) ||
                                (selectIndex[0] == index &&
                                  selectIndex[1] == index1)
                                  ? index == 0 && index1 == 0
                                    ? "#2D3B67"
                                    : "#EDF0F7"
                                  : index == 0 && index1 == 0
                                  ? "#CCCCCC"
                                  : "white",

                              border:
                                (selectIndex[0] == 0 && selectIndex[1] == 0) ||
                                (selectIndex[0] == 0 &&
                                  selectIndex[1] == index1) ||
                                (selectIndex[0] == index &&
                                  selectIndex[1] == 0) ||
                                (selectIndex[0] == index &&
                                  selectIndex[1] == index1)
                                  ? "1px solid #2D3B67"
                                  : index == 0 && index1 == 0
                                  ? "1px solid #CCCCCC"
                                  : index == 0 || index1 == 0
                                  ? "1px solid #808080"
                                  : "1px solid #DDDDDD",
                              width: index1 == 0 ? "4vw" : "8.9vw",
                              height: "6vh",
                              borderRadius: "5px",
                              marginBottom: "3px",
                              cursor: "pointer",
                            }}
                          >
                            {side === LISTENING_SIDE.LEFT
                              ? data8L[index][index1]
                              : data8R[index][index1]}
                          </button>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )
              )}
            </Grid>
            {side == LISTENING_SIDE.RIGHT && fittingLeft.connected && (
              <Grid xs={0} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Box
                  ml={3}
                  onClick={() => {
                    setside(LISTENING_SIDE.LEFT);
                    if (!link) {
                      setSelectIndex([null, null]);
                    }
                    if (!(fitting.device_type == DEVICES.BTE_PRIME)) {
                      setChannel(0);
                    }
                  }}
                  sx={{
                    backgroundColor: "#1265A1",
                    height: "18vh",
                    width: "2vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    width="11"
                    height="19"
                    viewBox="0 0 11 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 17.5L9.5 9.5L1.5 1.5"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      {channel == 1 && (
        <Box
          sx={{
            height: "33vh",
            scrollbarWidth: "none",
            width: "86vw",
          }}
        >
          <Grid
            container
            xs={12}
            mt={2}
            mb={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {side == LISTENING_SIDE.LEFT && fitting.connected && (
              <Grid xs={0}>
                <Box
                  onClick={() => {
                    setside(LISTENING_SIDE.RIGHT);
                    if (!link) {
                      setSelectIndex([null, null]);
                    }
                    if (!(fitting.device_type == DEVICES.BTE_PRIME)) {
                      setChannel(0);
                    }
                  }}
                  sx={{
                    backgroundColor: "#C24747",
                    height: "18vh",
                    width: "2vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    width="11"
                    height="19"
                    viewBox="0 0 11 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 17.5L1.5 9.5L9.5 1.5"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Box>
              </Grid>
            )}
            <Grid pl={3} pr={3} xs={11.5} width={"100%"}>
              {(side === LISTENING_SIDE.LEFT ? data24L : data24R)?.map(
                (arr, index) => (
                  <>
                    <Grid
                      container
                      xs={12}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      {arr?.map((val, index1) => (
                        <Grid xs={0.48}>
                          <button
                            disabled={
                              fitting.read_only || fittingLeft.read_only
                            }
                            onClick={() => {
                              handleClick(index, index1);
                            }}
                            style={{
                              margin: "0px",
                              // color:
                              //   side == LISTENING_SIDE.RIGHT
                              //     ? "#C24747"
                              //     : "#1265A1",

                              color:
                                side == LISTENING_SIDE.RIGHT
                                  ? index1 == 0 || index == 0
                                    ? "#C24747"
                                    : (selectIndex[0] == 0 &&
                                        selectIndex[1] == 0) ||
                                      (selectIndex[0] == 0 &&
                                        selectIndex[1] == index1) ||
                                      (selectIndex[0] == index &&
                                        selectIndex[1] == 0) ||
                                      (selectIndex[0] == index &&
                                        selectIndex[1] == index1)
                                    ? "#2D3B67"
                                    : "#808080"
                                  : index1 == 0 || index == 0
                                  ? "#1265A1"
                                  : (selectIndex[0] == 0 &&
                                      selectIndex[1] == 0) ||
                                    (selectIndex[0] == 0 &&
                                      selectIndex[1] == index1) ||
                                    (selectIndex[0] == index &&
                                      selectIndex[1] == 0) ||
                                    (selectIndex[0] == index &&
                                      selectIndex[1] == index1)
                                  ? "#2D3B67"
                                  : "#808080",

                              backgroundColor:
                                (selectIndex[0] == 0 && selectIndex[1] == 0) ||
                                (selectIndex[0] == 0 &&
                                  selectIndex[1] == index1) ||
                                (selectIndex[0] == index &&
                                  selectIndex[1] == 0) ||
                                (selectIndex[0] == index &&
                                  selectIndex[1] == index1)
                                  ? index == 0 && index1 == 0
                                    ? "#2D3B67"
                                    : "#EDF0F7"
                                  : index == 0 && index1 == 0
                                  ? "#CCCCCC"
                                  : "white",
                              ":hover": { bgcolor: "#2D3B67" },

                              border:
                                (selectIndex[0] == 0 && selectIndex[1] == 0) ||
                                (selectIndex[0] == 0 &&
                                  selectIndex[1] == index1) ||
                                (selectIndex[0] == index &&
                                  selectIndex[1] == 0) ||
                                (selectIndex[0] == index &&
                                  selectIndex[1] == index1)
                                  ? "1px solid #2D3B67"
                                  : index == 0 && index1 == 0
                                  ? "1px solid #CCCCCC"
                                  : index == 0 || index1 == 0
                                  ? "1px solid #808080"
                                  : "1px solid #DDDDDD",

                              width: "3vw",
                              height: "6vh",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                          >
                            {
                              (side == LISTENING_SIDE.LEFT ? data24L : data24R)[
                                index
                              ][index1]
                            }
                          </button>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )
              )}
            </Grid>
            {side == LISTENING_SIDE.RIGHT && fittingLeft.connected && (
              <Grid xs={0} display={"flex"} justifyContent={"flex-end"}>
                <Box
                  ml={3}
                  onClick={() => {
                    if (!link) {
                      setSelectIndex([null, null]);
                    }
                    setside(LISTENING_SIDE.LEFT);
                    if (!(fittingLeft.device_type == DEVICES.BTE_PRIME)) {
                      setChannel(0);
                    }
                  }}
                  sx={{
                    backgroundColor: "#1265A1",
                    height: "18vh",
                    width: "2vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    width="11"
                    height="19"
                    viewBox="0 0 11 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 17.5L9.5 9.5L1.5 1.5"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      <Box ml={5} sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          disabled={selectIndex[0] == null || selectIndex[1] == null}
          onClick={() => {
            if (channel === 0) {
              if (link) {
                handleIncreament(setData8R, data8R, LISTENING_SIDE.RIGHT);
                handleIncreament(setData8L, data8L, LISTENING_SIDE.LEFT);
                setData24L((arr) => {
                  arr[1] = ["G50", ...createDataFor24(data8L[1])];
                  arr[2] = ["G65", ...createDataFor24(data8L[2])];
                  arr[3] = ["G80", ...createDataFor24(data8L[3])];
                  arr[4] = ["G80", ...createDataFor24(data8L[4])];
                  return arr;
                });
                setData24R((arr) => {
                  arr[1] = ["G50", ...createDataFor24(data8R[1])];
                  arr[2] = ["G65", ...createDataFor24(data8R[2])];
                  arr[3] = ["G80", ...createDataFor24(data8R[3])];
                  arr[4] = ["G80", ...createDataFor24(data8R[4])];
                  return arr;
                });
              } else {
                if (side == LISTENING_SIDE.LEFT) {
                  handleIncreament(setData8L, data8L, side);
                  setData24L((arr) => {
                    arr[1] = ["G50", ...createDataFor24(data8L[1])];
                    arr[2] = ["G65", ...createDataFor24(data8L[2])];
                    arr[3] = ["G80", ...createDataFor24(data8L[3])];
                    arr[4] = ["G80", ...createDataFor24(data8L[4])];
                    return arr;
                  });
                } else if (side == LISTENING_SIDE.RIGHT) {
                  handleIncreament(setData8R, data8R, side);
                  setData24R((arr) => {
                    arr[1] = ["G50", ...createDataFor24(data8R[1])];
                    arr[2] = ["G65", ...createDataFor24(data8R[2])];
                    arr[3] = ["G80", ...createDataFor24(data8R[3])];
                    arr[4] = ["G80", ...createDataFor24(data8R[4])];
                    return arr;
                  });
                }
              }
            } else {
              if (link) {
                if (fitting.device_type == DEVICES.BTE_PRIME) {
                  handleIncreament(setData24R, data24R, LISTENING_SIDE.RIGHT);
                  setData8R((arr) => {
                    arr[1] = ["G50", ...createDataFor24to8(data24R[1])];
                    arr[2] = ["G65", ...createDataFor24to8(data24R[2])];
                    arr[3] = ["G80", ...createDataFor24to8(data24R[3])];
                    arr[4] = ["G80", ...createDataFor24to8(data24R[4])];
                    return arr;
                  });
                }
                if (fittingLeft.device_type == DEVICES.BTE_PRIME) {
                  handleIncreament(setData24L, data24L, LISTENING_SIDE.LEFT);
                  setData8L((arr) => {
                    arr[1] = ["G50", ...createDataFor24to8(data24L[1])];
                    arr[2] = ["G65", ...createDataFor24to8(data24L[2])];
                    arr[3] = ["G80", ...createDataFor24to8(data24L[3])];
                    arr[4] = ["G80", ...createDataFor24to8(data24L[4])];
                    return arr;
                  });
                }
              } else {
                if (side == LISTENING_SIDE.LEFT) {
                  handleIncreament(setData24L, data24L, side);
                  setData8L((arr) => {
                    arr[1] = ["G50", ...createDataFor24to8(data24L[1])];
                    arr[2] = ["G65", ...createDataFor24to8(data24L[2])];
                    arr[3] = ["G80", ...createDataFor24to8(data24L[3])];
                    arr[4] = ["G80", ...createDataFor24to8(data24L[4])];
                    return arr;
                  });
                } else if (side == LISTENING_SIDE.RIGHT) {
                  handleIncreament(setData24R, data24R, side);
                  setData8R((arr) => {
                    arr[1] = ["G50", ...createDataFor24to8(data24R[1])];
                    arr[2] = ["G65", ...createDataFor24to8(data24R[2])];
                    arr[3] = ["G80", ...createDataFor24to8(data24R[3])];
                    arr[4] = ["G80", ...createDataFor24to8(data24R[4])];
                    return arr;
                  });
                }
              }
            }
          }}
          sx={{ width: "80px", height: "32px", marginRight: "7px" }}
          variant="outlined"
        >
          <KeyboardArrowUpIcon fontSize="large" />
        </Button>
        <Button
          disabled={selectIndex[0] == null || selectIndex[1] == null}
          onClick={() => {
            if (channel === 0) {
              if (link) {
                handleDecreament(setData8R, data8R, LISTENING_SIDE.RIGHT);
                handleDecreament(setData8L, data8L, LISTENING_SIDE.LEFT);
                setData24L((arr) => {
                  arr[1] = ["G50", ...createDataFor24(data8L[1])];
                  arr[2] = ["G65", ...createDataFor24(data8L[2])];
                  arr[3] = ["G80", ...createDataFor24(data8L[3])];
                  arr[4] = ["G80", ...createDataFor24(data8L[4])];
                  return arr;
                });
                setData24R((arr) => {
                  arr[1] = ["G50", ...createDataFor24(data8R[1])];
                  arr[2] = ["G65", ...createDataFor24(data8R[2])];
                  arr[3] = ["G80", ...createDataFor24(data8R[3])];
                  arr[4] = ["G80", ...createDataFor24(data8R[4])];
                  return arr;
                });
              } else {
                if (side == LISTENING_SIDE.LEFT) {
                  handleDecreament(setData8L, data8L, side);
                  setData24L((arr) => {
                    arr[1] = ["G50", ...createDataFor24(data8L[1])];
                    arr[2] = ["G65", ...createDataFor24(data8L[2])];
                    arr[3] = ["G80", ...createDataFor24(data8L[3])];
                    arr[4] = ["G80", ...createDataFor24(data8L[4])];
                    return arr;
                  });
                } else if (side == LISTENING_SIDE.RIGHT) {
                  handleDecreament(setData8R, data8R, side);
                  setData24R((arr) => {
                    arr[1] = ["G50", ...createDataFor24(data8R[1])];
                    arr[2] = ["G65", ...createDataFor24(data8R[2])];
                    arr[3] = ["G80", ...createDataFor24(data8R[3])];
                    arr[4] = ["G80", ...createDataFor24(data8R[4])];
                    return arr;
                  });
                }
              }
            } else {
              if (link) {
                if (fitting.device_type == DEVICES.BTE_PRIME) {
                  handleDecreament(setData24R, data24R, LISTENING_SIDE.RIGHT);
                  setData8R((arr) => {
                    arr[1] = ["G50", ...createDataFor24to8(data24R[1])];
                    arr[2] = ["G65", ...createDataFor24to8(data24R[2])];
                    arr[3] = ["G80", ...createDataFor24to8(data24R[3])];
                    arr[4] = ["G80", ...createDataFor24to8(data24R[4])];
                    return arr;
                  });
                }
                if (fittingLeft.device_type == DEVICES.BTE_PRIME) {
                  handleDecreament(setData24L, data24L, LISTENING_SIDE.LEFT);
                  setData8L((arr) => {
                    arr[1] = ["G50", ...createDataFor24to8(data24L[1])];
                    arr[2] = ["G65", ...createDataFor24to8(data24L[2])];
                    arr[3] = ["G80", ...createDataFor24to8(data24L[3])];
                    arr[4] = ["G80", ...createDataFor24to8(data24L[4])];
                    return arr;
                  });
                }
              } else {
                if (side == LISTENING_SIDE.LEFT) {
                  handleDecreament(setData24L, data24L, side);
                  setData8L((arr) => {
                    arr[1] = ["G50", ...createDataFor24to8(data24L[1])];
                    arr[2] = ["G65", ...createDataFor24to8(data24L[2])];
                    arr[3] = ["G80", ...createDataFor24to8(data24L[3])];
                    arr[4] = ["G80", ...createDataFor24to8(data24L[4])];
                    return arr;
                  });
                } else if (side == LISTENING_SIDE.RIGHT) {
                  handleDecreament(setData24R, data24R, side);
                  setData8R((arr) => {
                    arr[1] = ["G50", ...createDataFor24to8(data24R[1])];
                    arr[2] = ["G65", ...createDataFor24to8(data24R[2])];
                    arr[3] = ["G80", ...createDataFor24to8(data24R[3])];
                    arr[4] = ["G80", ...createDataFor24to8(data24R[4])];
                    return arr;
                  });
                }
              }
            }
          }}
          sx={{ width: "80px", height: "32px" }}
          variant="outlined"
        >
          <KeyboardArrowDownIcon fontSize="large" />
        </Button>
      </Box>
    </Box>
  );
};

export default memo(AdvanceFittingController);
