import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  Grid,
  Typography,
  Chip,
  TextField,
  Box,
  Button,
} from "@mui/material";
import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../components/inputs/CustomInput";
import { actions, companyDetails, REGIONS_TYPE } from "../../utils/constants";
import { findObjectKeyByValue, titleCase } from "../../utils/helper";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { getComplainApi } from "../../apis/complain.api";
import { getTopicApi } from "../../apis/topic.api";
import SubmitButton from "../../components/button/SubmitButton";

const top100Films = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
  61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
  80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98,
  99, 100,
];
const ActionUi = ({ fields, setFields, index }) => {
  const fixedOptions = [];
  const [value, setValue] = useState([]);
  return (
    <Box mt={2}>
      {fields?.complaint_solution[index]?.actions?.map((val, inx) => {
        return (
          <>
            {" "}
            <Autocomplete
              multiple
              id="fixed-tags-demo"
              value={fields?.complaint_solution[index]?.actions[inx]?.arr_no}
              // onChange={(event, newValue) => {
              //   setValue([
              //     ...fixedOptions,
              //     ...newValue.filter(
              //       (option) => fixedOptions.indexOf(option) === -1
              //     ),
              //   ]);
              // }}
              onChange={(event, newValue) => {
                setFields((data) => {
                  let arr = data.complaint_solution;
                  arr[index].actions[inx].arr_no = newValue.filter(
                    (option) => fixedOptions.indexOf(option) === -1
                  );
                  return { ...data, complaint_solution: arr };
                });
              }}
              options={top100Films}
              getOptionLabel={(option) => option}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      key={key}
                      label={option}
                      {...tagProps}
                      disabled={fixedOptions.indexOf(option) !== -1}
                    />
                  );
                })
              }
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Select index" placeholder="" />
              )}
            />
            <CustomInput
              value={
                fields?.complaint_solution[index]?.actions[inx]?.increase_val
              }
              onChange={(e) => {
                setFields((data) => {
                  let arr = data.complaint_solution;
                  arr[index].actions[inx].increase_val = e.target.value;
                  return { ...data, complaint_solution: arr };
                });
              }}
              type="text"
              label={"Action*"}
            />
          </>
        );
      })}
      <Button
        variant="outlined"
        onClick={() => {
          setFields((data) => {
            let arr = data.complaint_solution;
            arr[index].actions.push({ arr_no: [], increase_val: "" });
            return { ...data, complaint_solution: arr };
          });
        }}
      >
        <Typography variant="h6">Add More Actions</Typography>
      </Button>
    </Box>
  );
};

const CreateUi = ({
  title,
  modalKey,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
  isAddMore,
}) => {
  const { user } = useSelector((state) => state);

  const dispatch = useDispatch();

  return (
    <>
      <CustomDialog
        id={modalKey}
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? (!isAddMore ? "Update" : "") : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            <Grid>
              <AsyncDropDown
                label="Topic*"
                lazyFun={async (params) => {
                  return await getTopicApi({ ...params });
                }}
                defaultVal={
                  fields?.topic_id.length == 0 ? null : fields?.topic_id
                }
                value={fields?.topic_id}
                OptionComponent={({ option, ...rest }) => {
                  return (
                    <>
                      <MenuItem {...rest}>{option.title}</MenuItem>
                    </>
                  );
                }}
                onChange={async (changedVal) => {
                  setFields({
                    ...fields,
                    topic_id: changedVal?._id,
                  });
                }}
                titleKey={"title"}
                valueKey={"_id"}
              />
            </Grid>
            {
              <CustomInput
                disabled={loading}
                value={fields.question}
                onChange={(e) =>
                  setFields({ ...fields, err: "", question: e.target.value })
                }
                type="text"
                label={"Question*"}
              />
            }

            {fields.complaint_solution.map((val, index) => (
              <>
                <CustomInput
                  disabled={loading}
                  value={fields?.complaint_solution[index]?.solution}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      complaint_solution: [
                        {
                          ...fields?.complaint_solution[index],
                          solution: e.target.value,
                        },
                      ],
                    })
                  }
                  type="text"
                  label={"Solution*"}
                />
                <ActionUi fields={fields} setFields={setFields} index={index} />
              </>
            ))}
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);
