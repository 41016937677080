import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import mode1 from "../../assets/images/mode1.svg";
import mode2 from "../../assets/images/mode2.svg";
import mode3 from "../../assets/images/mode3.svg";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import mode4 from "../../assets/images/mode4.svg";
import React, { useState } from "react";
import {
  MANAGE_PROGRAM,
  MANAGE_PROGRAM_LIST,
  MANAGE_PROGRAM_NAME,
} from "../../utils/patient.constants";

const ManageProgramController = () => {
  const [editButton, setEditButton] = useState("");
  const [editButtonList, setEditButtonList] = useState("");

  return (
    <Box mt={4} ml={6}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        <Box>
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: "#808080",
                fontWeight: "500",
                fontFamily: "League Spartan",
              }}
            >
              M1
            </Typography>
          </Box>
          <Box
            sx={{
              border: "1px solid #DDDDDD",
              borderRadius: "5px",
              width: "19vw",
              height: "300px",
            }}
          >
            <Box
              p={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                borderBottom: "1px solid #DDDDDD",
                alignItems: "center",
                height: "50px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#2D3B67",
                  fontWeight: "600",
                  fontFamily: "League Spartan",
                }}
              >
                Common
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "213px",
              }}
            >
              <img src={mode1} />
            </Box>
          </Box>
        </Box>

        {editButton == MANAGE_PROGRAM.M2 ? (
          <Box>
            {editButtonList == MANAGE_PROGRAM_LIST.COMMON ||
            editButtonList == MANAGE_PROGRAM_LIST.ENVIRONMENT ||
            editButtonList == MANAGE_PROGRAM_LIST.MEETING ||
            editButtonList == MANAGE_PROGRAM_LIST.COMFORT ||
            editButtonList == MANAGE_PROGRAM_LIST.TV ||
            editButtonList == MANAGE_PROGRAM_LIST.OUTDOOR ||
            editButtonList == MANAGE_PROGRAM_LIST.MUSIC ? (
              <Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#808080",
                      fontWeight: "500",
                      fontFamily: "League Spartan",
                    }}
                  >
                    M2
                  </Typography>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #DDDDDD",
                    borderRadius: "5px",
                    width: "19vw",
                    height: "300px",
                  }}
                >
                  <Box
                    flexDirection={"column"}
                    height={"35vh"}
                    display={"flex"}
                    alignItems={"space-between"}
                    justifyContent={"center"}
                  >
                    <Box
                      p={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "18px",
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Do you want to change the Mode to{" "}
                        {MANAGE_PROGRAM_NAME[editButtonList]} ?
                      </Typography>
                    </Box>
                    <Box
                      p={2}
                      sx={{
                        width: "18.9vw",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        // alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "16px",
                          color: "#4D4D4D",
                          fontWeight: "500",
                          fontFamily: "League Spartan",
                        }}
                      >
                        If you change the mode, all configuration of this mode
                        will be changed.
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"space-around"}
                    alignItems={"flex-end"}
                  >
                    <Button
                      onClick={() => {
                        setEditButton(true);
                        setEditButtonList("");
                      }}
                      sx={{
                        width: "8.7vw",
                        border: "1px solid #2D3B67",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#2D3B67",
                          fontSize: "18px",
                          fontWeight: "500",
                          fontFamily: "League spartan",
                        }}
                      >
                        Yes
                      </Typography>
                    </Button>
                    <Button
                      onClick={() => {
                        setEditButton(true);
                        setEditButtonList("");
                      }}
                      sx={{
                        width: "8.7vw",
                        border: "1px solid #DDDDDD",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#808080",
                          fontSize: "18px",
                          fontWeight: "500",
                          fontFamily: "League spartan",
                        }}
                      >
                        No
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : (
              <>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#808080",
                      fontWeight: "500",
                      fontFamily: "League Spartan",
                    }}
                  >
                    M2
                  </Typography>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #DDDDDD",
                    borderRadius: "5px",
                    width: "19vw",
                    height: "300px",
                    overflow: "auto",
                    scrollBehavior: "smooth",
                  }}
                >
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.COMMON);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.COMMON
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.COMMON
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Common
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.ENVIRONMENT);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.ENVIRONMENT
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.ENVIRONMENT
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Environment
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.MEETING);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.MEETING
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.MEETING
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Meeting
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.COMFORT);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.COMFORT
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.COMFORT
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Comfort
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.TV);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.TV
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.TV
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        TV
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.OUTDOOR);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.OUTDOOR
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.OUTDOOR
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Outdoor
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.MUSIC);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.MUSIC
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.MUSIC
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Music
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Box>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: "#808080",
                  fontWeight: "500",
                  fontFamily: "League Spartan",
                }}
              >
                M2
              </Typography>
            </Box>
            <Box
              sx={{
                border: "1px solid #DDDDDD",
                borderRadius: "5px",
                width: "19vw",
                height: "300px",
              }}
            >
              <Box
                p={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  borderBottom: "1px solid #DDDDDD",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2D3B67",
                    fontWeight: "600",
                    fontFamily: "League Spartan",
                  }}
                >
                  Outdoor
                </Typography>
                <IconButton
                  onClick={() => {
                    setEditButton(MANAGE_PROGRAM.M2);
                  }}
                >
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "213px",
                }}
              >
                <img src={mode2} />
              </Box>
            </Box>
          </Box>
        )}

        {editButton == MANAGE_PROGRAM.M3 ? (
          <Box>
            {editButtonList == MANAGE_PROGRAM_LIST.COMMON ||
            editButtonList == MANAGE_PROGRAM_LIST.ENVIRONMENT ||
            editButtonList == MANAGE_PROGRAM_LIST.MEETING ||
            editButtonList == MANAGE_PROGRAM_LIST.COMFORT ||
            editButtonList == MANAGE_PROGRAM_LIST.TV ||
            editButtonList == MANAGE_PROGRAM_LIST.OUTDOOR ||
            editButtonList == MANAGE_PROGRAM_LIST.MUSIC ? (
              <Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#808080",
                      fontWeight: "500",
                      fontFamily: "League Spartan",
                    }}
                  >
                    M3
                  </Typography>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #DDDDDD",
                    borderRadius: "5px",
                    width: "19vw",
                    height: "300px",
                  }}
                >
                  <Box
                    flexDirection={"column"}
                    height={"35vh"}
                    display={"flex"}
                    alignItems={"space-between"}
                    justifyContent={"center"}
                  >
                    <Box
                      p={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "18px",
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Do you want to change the Mode to{" "}
                        {MANAGE_PROGRAM_NAME[editButtonList]} ?
                      </Typography>
                    </Box>
                    <Box
                      p={2}
                      sx={{
                        width: "18.9vw",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        // alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "16px",
                          color: "#4D4D4D",
                          fontWeight: "500",
                          fontFamily: "League Spartan",
                        }}
                      >
                        If you change the mode, all configuration of this mode
                        will be changed.
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"space-around"}
                    alignItems={"flex-end"}
                  >
                    <Button
                      onClick={() => {
                        setEditButton(true);
                        setEditButtonList("");
                      }}
                      sx={{
                        width: "8.7vw",
                        border: "1px solid #2D3B67",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#2D3B67",
                          fontSize: "18px",
                          fontWeight: "500",
                          fontFamily: "League spartan",
                        }}
                      >
                        Yes
                      </Typography>
                    </Button>
                    <Button
                      onClick={() => {
                        setEditButton(true);
                        setEditButtonList("");
                      }}
                      sx={{
                        width: "8.7vw",
                        border: "1px solid #DDDDDD",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#808080",
                          fontSize: "18px",
                          fontWeight: "500",
                          fontFamily: "League spartan",
                        }}
                      >
                        No
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : (
              <>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#808080",
                      fontWeight: "500",
                      fontFamily: "League Spartan",
                    }}
                  >
                    M3
                  </Typography>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #DDDDDD",
                    borderRadius: "5px",
                    width: "19vw",
                    height: "300px",
                    overflow: "auto",
                    scrollBehavior: "smooth",
                  }}
                >
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.COMMON);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.COMMON
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.COMMON
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Common
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.ENVIRONMENT);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.ENVIRONMENT
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.ENVIRONMENT
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Environment
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.MEETING);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.MEETING
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.MEETING
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Meeting
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.COMFORT);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.COMFORT
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.COMFORT
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Comfort
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.TV);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.TV
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.TV
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        TV
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.OUTDOOR);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.OUTDOOR
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.OUTDOOR
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Outdoor
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.MUSIC);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.MUSIC
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.MUSIC
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Music
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Box>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: "#808080",
                  fontWeight: "500",
                  fontFamily: "League Spartan",
                }}
              >
                M3
              </Typography>
            </Box>
            <Box
              sx={{
                border: "1px solid #DDDDDD",
                borderRadius: "5px",
                width: "19vw",
                height: "300px",
              }}
            >
              <Box
                p={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  borderBottom: "1px solid #DDDDDD",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2D3B67",
                    fontWeight: "600",
                    fontFamily: "League Spartan",
                  }}
                >
                  Meeting
                </Typography>
                <IconButton
                  onClick={() => {
                    setEditButton(MANAGE_PROGRAM.M3);
                  }}
                >
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "213px",
                }}
              >
                <img src={mode3} />
              </Box>
            </Box>
          </Box>
        )}

        {editButton == MANAGE_PROGRAM.M4 ? (
          <Box>
            {editButtonList == MANAGE_PROGRAM_LIST.COMMON ||
            editButtonList == MANAGE_PROGRAM_LIST.ENVIRONMENT ||
            editButtonList == MANAGE_PROGRAM_LIST.MEETING ||
            editButtonList == MANAGE_PROGRAM_LIST.COMFORT ||
            editButtonList == MANAGE_PROGRAM_LIST.TV ||
            editButtonList == MANAGE_PROGRAM_LIST.OUTDOOR ||
            editButtonList == MANAGE_PROGRAM_LIST.MUSIC ? (
              <Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#808080",
                      fontWeight: "500",
                      fontFamily: "League Spartan",
                    }}
                  >
                    M4
                  </Typography>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #DDDDDD",
                    borderRadius: "5px",
                    width: "19vw",
                    height: "300px",
                  }}
                >
                  <Box
                    flexDirection={"column"}
                    height={"35vh"}
                    display={"flex"}
                    alignItems={"space-between"}
                    justifyContent={"center"}
                  >
                    <Box
                      p={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "18px",
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Do you want to change the Mode to{" "}
                        {MANAGE_PROGRAM_NAME[editButtonList]} ?
                      </Typography>
                    </Box>
                    <Box
                      p={2}
                      sx={{
                        width: "18.9vw",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        // alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "16px",
                          color: "#4D4D4D",
                          fontWeight: "500",
                          fontFamily: "League Spartan",
                        }}
                      >
                        If you change the mode, all configuration of this mode
                        will be changed.
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"space-around"}
                    alignItems={"flex-end"}
                  >
                    <Button
                      onClick={() => {
                        setEditButton(true);
                        setEditButtonList("");
                      }}
                      sx={{
                        width: "8.7vw",
                        border: "1px solid #2D3B67",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#2D3B67",
                          fontSize: "18px",
                          fontWeight: "500",
                          fontFamily: "League spartan",
                        }}
                      >
                        Yes
                      </Typography>
                    </Button>
                    <Button
                      onClick={() => {
                        setEditButton(true);
                        setEditButtonList("");
                      }}
                      sx={{
                        width: "8.7vw",
                        border: "1px solid #DDDDDD",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#808080",
                          fontSize: "18px",
                          fontWeight: "500",
                          fontFamily: "League spartan",
                        }}
                      >
                        No
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : (
              <>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#808080",
                      fontWeight: "500",
                      fontFamily: "League Spartan",
                    }}
                  >
                    M4
                  </Typography>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #DDDDDD",
                    borderRadius: "5px",
                    width: "19vw",
                    height: "300px",
                    overflow: "auto",
                    scrollBehavior: "smooth",
                  }}
                >
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.COMMON);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.COMMON
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.COMMON
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Common
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.ENVIRONMENT);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.ENVIRONMENT
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.ENVIRONMENT
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Environment
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.MEETING);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.MEETING
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.MEETING
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Meeting
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.COMFORT);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.COMFORT
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.COMFORT
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Comfort
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.TV);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.TV
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.TV
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        TV
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.OUTDOOR);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.OUTDOOR
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.OUTDOOR
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Outdoor
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      borderBottom: "1px solid #DDDDDD",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setEditButtonList(MANAGE_PROGRAM_LIST.MUSIC);
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          editButtonList == MANAGE_PROGRAM_LIST.MUSIC
                            ? "#EDF0F7"
                            : "",
                        border:
                          editButtonList == MANAGE_PROGRAM_LIST.MUSIC
                            ? "1px solid #2D3B67"
                            : "",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: "600",
                          fontFamily: "League Spartan",
                        }}
                      >
                        Music
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Box>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: "#808080",
                  fontWeight: "500",
                  fontFamily: "League Spartan",
                }}
              >
                M4
              </Typography>
            </Box>
            <Box
              sx={{
                border: "1px solid #DDDDDD",
                borderRadius: "5px",
                width: "19vw",
                height: "300px",
              }}
            >
              <Box
                p={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  borderBottom: "1px solid #DDDDDD",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2D3B67",
                    fontWeight: "600",
                    fontFamily: "League Spartan",
                  }}
                >
                  Balanced
                </Typography>
                <IconButton
                  onClick={() => {
                    setEditButton(MANAGE_PROGRAM.M4);
                  }}
                >
                  <DriveFileRenameOutlineIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "213px",
                }}
              >
                <img src={mode4} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ManageProgramController;
