import { Autocomplete, Box, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { memo, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'

import CustomInput from "../../components/inputs/CustomInput"
import { GENDER, USER_ROLES } from "../../utils/constants"
import { findNameByRole, getAllowedRoles, titleCase } from "../../utils/helper"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { LEVEL_OF_HEARING_LOSS, TYPE_OF_HEARING_LOSS } from "../../utils/patient.constants"
import { Line } from "react-chartjs-2"
import ConnectDevice from "../../components/bluetooth/CoonectDeviceModule"



const FrequencyBox = ({ title, value, onChange, onlyView }) => {
    return <Grid item xs={1.33}>

        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", border: "1px solid #f2f2f2" }}>
            <Box p={2} sx={{ borderBottom: "1px solid #f2f2f2" }}>
                <Typography align="center" variant="body1" fontWeight="bold" >
                    {title}
                </Typography>
            </Box>
            <Box sx={{}}>

                {onlyView ?
                    <Typography align="center" variant="body1" >{value}</Typography> :
                    <TextField
                        InputProps={{
                            textAlign: 'center !important',
                            width: "unset",
                            height: "80px !important",
                            "::-webkit-outer-spin-button": {
                                '-webkit-appearance': 'none',
                                margin: 0
                            },
                            "::-webkit-inner-spin-button": {
                                '-webkit-appearance': 'none',
                                margin: 0
                            }
                        }}
                        sx={{
                            textAlign: 'center !important',
                            width: "unset",
                            "*": {
                                // width:"100%",
                                // height:"100%",
                                textAlign: 'center !important',
                                padding: "0px !important",
                                border: "none !important",
                                fontSize: "15px !important"

                            }
                        }}
                        size="small"
                        type="number"
                        value={value}
                        onChange={(e) => onChange(e.target.value)} />
                }

            </Box>
        </Box>
    </Grid>
}

const ChartComponent = ({ data, title, onValueChange }) => {

    const [editingIndex, setEditingIndex] = useState(undefined)

    const labels = ['125', '250', '500', '1k', '2k', '4k', '6k', '8k'];


    const dataFields = {
        labels,
        datasets: [
            {
                label: title,
                data: data.map((item) => -1 * item),
                fill: "start",
                // backgroundColor:"white",
                // hoverBackgroundColor:"white",
                pointBackgroundColor: "white",
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(50, 0, 0, 200);
                    gradient.addColorStop(0, "rgba(250,174,50,1)");
                    gradient.addColorStop(1, "rgba(250,174,50,0.3)");
                    return gradient;
                },
            },
        ]
    }

    return <Line
        width="500px"
        height="400px"

        options={{
            interaction: {
                // Overrides the global setting
                mode: 'index'
            },
            plugins: {
                datalabels: {
                    display: 0,
                    backgroundColor: "red"
                },
                tooltip: {
                    enabled: false // <-- this option disables tooltips
                },
                legend: {
                    enabled: false
                }
            },
            tooltips: { enabled: false },
            responsive: true,
            animation: false,
            maintainAspectRatio: true,
            bezierCurve: true,
            elements: {
                line: {
                    borderWidth: 1,

                    tension: 0.5,
                    borderColor: "#000",
                },


                point: {
                    pointStyle: title == "Left Ear" ? "circle" : "crossRot",
                    hoverBorderColor: "#3C508B",
                    borderColor: "#3C508B",
                    radius: title == "Left Ear" ? 6 : 10,
                    backgroundColor: "white",
                    hoverBackgroundColor: "white",
                    hoverRadius: title == "Left Ear" ? 8 : 12,
                    borderWidth: title == "Left Ear" ? 1 : 3,
                    hoverBorderWidth: title == "Left Ear" ? 1 : 3,
                },

            },
            spanGaps: 1,
            aspectRatio: 1.5,
            scales: {
                y: {
                    // display:"none",
                    max: 0,
                    min: -100,
                    suggestedMax: 0,
                    suggestedMin: -100,
                    ticks: {
                        callback: function (value, index, ticks) {

                            return -1 * (value)
                        }
                    }
                }
            },

            onClick: (e, ele, chart) => {
                setEditingIndex(editingIndex == undefined ? chart.scales.x.getValueForPixel(e.x) : undefined)

            },
            onHover: (e, ele, chart) => {

               
                if (editingIndex != undefined)
                    onValueChange(editingIndex, -1 * chart.scales.y.getValueForPixel(e.y))
            }
        }} data={dataFields} />
}

const CreateUi = ({ title, only_view, modalKey, isUpdate, fields, setFields, loading, onSubmit, isRolePredefined }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog

            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={only_view ? undefined : onSubmit}
            dialogProps={{ sx: { height: "1000px", overflow: "hidden" } }}
            title={title}
            closeText={only_view ? undefined : "Close"}
            confirmText={only_view ? undefined : `Configure Device`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :


                <>
                    <Grid container columnSpacing={5} sx={{ height: "100%" }}>
                        <Grid item xs={6}>
                            <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                            <ConnectDevice />
                                <Box sx={{ display: "flex", flex: 1 }} mb={3}>
                                    
                                    <ChartComponent
                                        onValueChange={(x, y) => {
                                            if (!only_view)
                                                setFields({ ...fields, ['f' + (x + 1) + 'l']: Math.round(y) })
                                        }}
                                        data={[
                                            fields.f1l,
                                            fields.f2l,
                                            fields.f3l,
                                            fields.f4l,
                                            fields.f5l,
                                            fields.f6l,
                                            fields.f7l,
                                            fields.f8l

                                        ]}
                                        title="Left Ear"
                                    />

                                </Box>
                                <Grid container spacing={0}>
                                    <FrequencyBox

                                        title="F"
                                        onlyView
                                        value={"G"}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="125"

                                        value={fields.f1l}
                                        onChange={(val) => setFields({ ...fields, f1l: val })}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="250"

                                        value={fields.f2l}
                                        onChange={(val) => setFields({ ...fields, f2l: val })}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="500"

                                        value={fields.f3l}
                                        onChange={(val) => setFields({ ...fields, f3l: val })}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="1k"

                                        value={fields.f4l}
                                        onChange={(val) => setFields({ ...fields, f4l: val })}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="2k"

                                        value={fields.f5l}
                                        onChange={(val) => setFields({ ...fields, f5l: val })}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="4k"

                                        value={fields.f6l}
                                        onChange={(val) => setFields({ ...fields, f6l: val })}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="6k"

                                        value={fields.f7l}
                                        onChange={(val) => setFields({ ...fields, f7l: val })}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="8k"

                                        value={fields.f8l}
                                        onChange={(val) => setFields({ ...fields, f8l: val })}
                                    />

                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>

                            <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                                <Box sx={{ display: "flex", flex: 1 }} mb={3}>
                                    <ChartComponent
                                        onValueChange={(x, y) => {
                                            if (!only_view)
                                                setFields({ ...fields, ['f' + (x + 1) + 'r']: Math.round(y) })
                                        }}
                                        data={[
                                            fields.f1r,
                                            fields.f2r,
                                            fields.f3r,
                                            fields.f4r,
                                            fields.f5r,
                                            fields.f6r,
                                            fields.f7r,
                                            fields.f8r

                                        ]}
                                        title="Right Ear"
                                    />
                                </Box>
                                <Grid container spacing={0}>
                                    <FrequencyBox

                                        title="F"
                                        onlyView
                                        value={"G"}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="125"
                                        value={fields.f1r}
                                        onChange={(val) => setFields({ ...fields, f1r: val })}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="250"

                                        value={fields.f2r}
                                        onChange={(val) => setFields({ ...fields, f2r: val })}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="500"

                                        value={fields.f3r}
                                        onChange={(val) => setFields({ ...fields, f3r: val })}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="1k"

                                        value={fields.f4r}
                                        onChange={(val) => setFields({ ...fields, f4r: val })}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="2k"

                                        value={fields.f5r}
                                        onChange={(val) => setFields({ ...fields, f5r: val })}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="4k"

                                        value={fields.f6r}
                                        onChange={(val) => setFields({ ...fields, f6r: val })}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="6k"

                                        value={fields.f7r}
                                        onChange={(val) => setFields({ ...fields, f7r: val })}
                                    />
                                    <FrequencyBox
                                        onlyView={only_view}
                                        title="8k"

                                        value={fields.f8r}
                                        onChange={(val) => setFields({ ...fields, f8r: val })}
                                    />

                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>







                </>}

        </CustomDialog>


    </>
}
export default memo(CreateUi)