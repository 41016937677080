export const generateValues = (targetAverage) => {
  const targetSum = Math.round(targetAverage * 3);
  let values = [];

  for (let i = 0; i < 2; i++) {
    values.push(Math.floor(Math.random() * 3) + (targetSum / 3 - 1));
  }

  let thirdValue = targetSum - values.reduce((a, b) => a + b, 0);

  while (values.includes(thirdValue)) {
    values = [];
    for (let i = 0; i < 2; i++) {
      values.push(Math.floor(Math.random() * 3) + (targetSum / 3 - 1));
    }
    thirdValue = targetSum - values.reduce((a, b) => a + b, 0);
  }

  values.push(thirdValue);

  return values;
};

export const generateValues4 = (targetAverage) => {
  const targetSum = Math.round(targetAverage * 4);
  let values = [];

  for (let i = 0; i < 3; i++) {
    values.push(Math.floor(Math.random() * 3) + (targetSum / 4 - 1));
  }

  let fourthValue = targetSum - values.reduce((a, b) => a + b, 0);

  while (values.includes(fourthValue)) {
    values = [];
    for (let i = 0; i < 3; i++) {
      values.push(Math.floor(Math.random() * 3) + (targetSum / 4 - 1));
    }
    fourthValue = targetSum - values.reduce((a, b) => a + b, 0);
  }

  values.push(fourthValue);

  return values;
};
