
import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import CreateUi from "./CreateUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import {
  addTopicApi,
  getTopicByIdApi,
  updateTopicField,
} from "../../apis/topic.api";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants";
import { validateInternationPhone, validatePhone } from "../../utils/helper";
import { callSnackBar } from "../../store/actions/snackbarAction";

const CreateController = ({ callBack = () => {}, id, role = null }) => {
  const validate = useValidate();
  const dispatch = useDispatch();
  const params = useParams();
  const modalKey = "topic";
  const title = "Topic";
  const createApi = addTopicApi;
  const updateApi = updateTopicField;
  const getByIdApi = getTopicByIdApi;

  const [loading, setLoading] = useState(false);
  const isRolePredefined = role !== null;
  const [fields, setFields] = useState({
    err: "",
    id,
    
    // email: '',
    // phone:"",
    // password: '',
    // parent_id:null,
    // country_code:"+91",
    // firm_name:"",
    // firm_logo:"",
    // role
  });
  const [originalDocument, setOriginalDocument] = useState({});

  const validationSchemaForCreate = useMemo(
    () => [
      {
        required: true,
        value: fields.title,
        field: "Topic",
      },
    ],
    [fields]
  );

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.title,
        field: "Topic",
      },
    ],
    [fields]
  );

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate);

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createApi(fields),
          async (response) => {
            await callBack(response);
            setLoading(false);
            dispatch(callSnackBar("Topic Created Successfully..",SNACK_BAR_VARIETNS.suceess))
            dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const updateFunction = async () => {
    const validationResponse = validate(validationSchemaForUpdate);
    let updatedData = { id };

    for (let field in fields) {
      if (
        originalDocument[field] &&
        fields[field] &&
        fields[field] != originalDocument[field]
      ) {
        updatedData[field] = fields[field];
      }

      if (!originalDocument[field]) {
        updatedData[field] = fields[field];
      }
    }

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await updateApi(updatedData),
          async (response) => {
            await callBack(response, updatedData);
            setLoading(false);
            dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (id) updateFunction();
    else createFunction();
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });
          setOriginalDocument(response);
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);

  return (
    <CreateUi
      isRolePredefined={isRolePredefined}
      modalKey={modalKey}
      title={title}
      isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(CreateController);
