import { memo, useCallback, useEffect, useState} from "react";
import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal, openModal } from "../../store/actions/modalAction";
import {
  findNameByRole,
  findObjectKeyByValue,
  titleCase,
} from "../../utils/helper";
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import CreateController from "./CreateController";
import MessageDilog from "../../components/MessageDilog";
import CircleIcon from "@mui/icons-material/Circle";
import {
  Add,
  ChangeCircle,
  Delete,
  Edit,
  LockClock,
  LockOpen,
  WhatsApp,
} from "@mui/icons-material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ModulePermissionController from "../user/ModulePermissionController";
import MODULES from "../../utils/module.constant";
import { getSecretsApi, updateSecretsField } from "../../apis/secrets.api";
import {
  getComplainApi,
  addComplainApi,
  deleteComplainField,
} from "../../apis/complain.api";
import { REGIONS_TYPE, SNACK_BAR_VARIETNS } from "../../utils/constants";
import { callSnackBar } from "../../store/actions/snackbarAction";

const ActionComponent = memo(({ params, setParams, deleteApi, modalKey }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateController
          id={params._id}
          // title={params.title}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm",
        false,
        "complain"
      )
    );
  };

  const onAddMore = () => {
    dispatch(
      openModal(
        <CreateController
          id={params._id}
          title={"dfghbj"}
          isAddMore={true}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm",
        false,
        "complain"
      )
    );
  };

  const deleteFun = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };
  const onDelete = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={deleteFun}
          title="Alert!"
          message={`Are you sure to delete "${params.question}" ?`}
        />,
        "sm"
        // dispatch(callSnackBar("Topic Deleted Successfully..",SNACK_BAR_VARIETNS.suceess))
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      <Tooltip title="Edit">
        <IconButton disabled={loading} size="inherit" onClick={onEdit}>
          <Edit color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <>
        {loading && <CircularProgress color="primary" fontSize="inherit" />}
        {!loading && (
          <Tooltip title="Delete">
            <IconButton disabled={loading} size="inherit" onClick={onDelete}>
              <Delete color="error" fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
      </>
      <Tooltip title="Add more">
        <IconButton disabled={loading} size="inherit" onClick={onAddMore}>
          <ControlPointIcon color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Box>
  );
});

const ModulesComponent = memo(({ params, setParams }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);

  return (
    <>
      <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        {params.modules &&
          params.modules.map((item) => (
            <Chip
              size="small"
              sx={{ mr: 1 }}
              key={item}
              label={findObjectKeyByValue(item, MODULES)}
            />
          ))}
        <IconButton disabled={loading} size="inherit">
          <ChangeCircle color="info" fontSize="inherit" />
        </IconButton>
      </Box>
    </>
  );
});

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Complain ";
  // const modalKey = 'topic'
  const fetchApi = getComplainApi;
  const deleteApi = deleteComplainField;

  const columns = useMemo(
    () => [
      {
        id: 1,
        minWidth: "50px",
        fieldName: "topic_id",
        label: "Topic",
        align: "left",
        sort: true,
        renderValue: (params) => (
          <Box>
            <Typography textTransform="capitalize" variant="td">
              {params?.topic_id?.title ?? "NA"}
            </Typography>
          </Box>
        ),
      },
      {
        id: 2,
        minWidth: "50px",
        fieldName: "question",
        label: "Complain",
        align: "left",
        sort: true,
      },
      {
        id: 3,
        minWidth: "50px",
        fieldName: "solution",
        label: "Solution",
        align: "left",
        sort: true,
        renderValue: (params) =>
          params?.complaint_solution.map((val) => (
            <Box display={"flex"} alignItems="center">
              <CircleIcon sx={{ fontSize: "7px", marginRight: "5px" }} />
              <Typography textTransform="capitalize" variant="td">
                {val.solution}
              </Typography>
            </Box>
          )),
      },
      // {
      //   id: 4,
      //   minWidth: "50px",
      //   fieldName: "arr_no",
      //   label: "Array No",
      //   align: "left",
      //   sort: true,
      // },
      // {
      //   id: 5,
      //   minWidth: "50px",
      //   fieldName: "increase_val",
      //   label: "Increase Val",
      //   align: "left",
      //   sort: true,
      // },
      {
        id: 6,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["question,solution"],
    sort: "createdAt",
    sortDirection: -1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateController
          callBack={async () => {
            fetchList();
          }}
        />,
        "sm",
        false,
        "complain"
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;
