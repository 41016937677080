import ModuleContainer from "../../components/layouts/common/ModuleContainer";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Grid,
  Paper,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  useTheme,
  IconButton,
  Select,
} from "@mui/material";
import { CenteredBox } from "../../components/layouts/common/boxes";
import LeftImg from "../../assets/images/Left (1).png";
import RightImg from "../../assets/images/Left (2).png";
import BTEDevice from "../../assets/images/bte.png";
import LeftGlasses from "../../assets/images/left-glasses.png";
import { useDispatch, useSelector } from "react-redux";
import {
  DEVICES,
  DEVICES_NAME,
  FITTING_BUTTON,
  LISTENING_SIDE,
} from "../../utils/patient.constants";
import NoDataComponent from "../../components/layouts/NoDataComponent";
import { CheckCircle, Circle, Error } from "@mui/icons-material";
import CoonectDeviceModule from "../../components/bluetooth/CoonectDeviceModule";
import {
  applyForRemoteFitting,
  autofitDevice,
  changeByteValue,
  changeDeviceCompatiblity,
  changeDeviceSelection,
  changeDeviceSideSelection,
  changeFittingData,
  changeLoadinfMessages,
  changeMode,
  changeProfileId,
  connectDevice,
  disconnectDevice,
  onWriteFunctionChange,
  readDataFromDevice,
  resetDevice,
  resetReducer,
  resetToDefault,
} from "../../store/actions/fittingAction";
import SubmitButton from "../../components/button/SubmitButton";
import BasicFittingAreaController from "./BasicFittingAreaController";
import AutoFitDeviceButton from "./AutoFitDeviceButton";
import {
  findMediunsInBulk,
  findObjectKeyByValue,
  mapValues,
} from "../../utils/helper";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate, useParams } from "react-router-dom";
import {
  addPatientProfileApi,
  getPatientProfileByIdApi,
  updatePatientProfileField,
} from "../../apis/patientprofile.api";
import { callApiAction } from "../../store/actions/commonAction";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getPatientByIdApi } from "../../apis/patient.api";
import { GAIN_POINTS, WDRC_circuit_gain } from "../../utils/j10.helper";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";
import AdvanceFittingController from "./AdvanceFittingController";
import FineTunningController from "./FineTunningController";
import ConfigAssistantController from "./ConfigAssistantController";
import { closeModal, openModal } from "../../store/actions/modalAction";
import CompleteSessionController from "./CompleteSessionController";
import SAVEIMAGE from "../../assets/images/save.svg";
import RESETIMAGE from "../../assets/images/reset.svg";
import READIMAGE from "../../assets/images/read.svg";
import ManageProgramController from "./ManageProgramController";
import { generateValues, generateValues4 } from "../../utils/Average4Values";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ConnectButton = ({ loading, connected, onClick, disconnect }) => {
  if (!connected) {
    return (
      <SubmitButton
        loading={loading}
        disabled={loading}
        variant="outlined"
        onClick={onClick}
        title={"Connect"}
        style={{ backgroundColor: "white" }}
      />
    );
  }
};
const ConncetionScreen = ({ show, disabled, setValue, data }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { fittingLeft } = useSelector((state) => state);
  const { fitting } = useSelector((state) => state);
  // useEffect(() => {
  //   return () => {
  //     if (fitting.connected && !fitting.read_only) {
  //       dispatch(disconnectDevice(fitting.device_side));
  //     } else {
  //       dispatch(resetToDefault(fitting.device_side));
  //     }

  //     if (fittingLeft.connected && !fittingLeft.read_only) {
  //       dispatch(disconnectDevice(fittingLeft.device_side));
  //     } else {
  //       dispatch(resetToDefault(fittingLeft.device_side));
  //     }
  //   };
  // }, []);

  useEffect(() => {
    console.log(
      "this is device side right",
      data?.right_fitted_device?.device_type
    );
    console.log(
      "this is device side left",
      data?.left_fitted_device?.device_type
    );
    dispatch(
      changeDeviceSelection(
        data?.right_fitted_device?.device_type,
        LISTENING_SIDE.RIGHT
      )
    );
    dispatch(
      changeDeviceSelection(
        data?.left_fitted_device?.device_type,
        LISTENING_SIDE.LEFT
      )
    );
  }, []);

  return (
    <>
      <Box p={1} sx={{ height: "100vh" }}>
        <Grid
          container
          md={12}
          xs={12}
          sx={{ border: "1px solid #DDDDDD", borderRadius: "10px" }}
        >
          <Grid xs={6} md={6}>
            <Paper
              sx={{
                backgroundColor: "#FFF6F6",
                minHeight: "80vh",
                maxHeight: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box mt={6}>
                  {data?.right_fitted_device?.selected && (
                    <Typography
                      sx={{
                        color: "#C24747",
                        fontFamily: "League spartan",
                        fontSize: "32px",
                        fontWeight: "bold",
                        lineHeight: "40px",
                      }}
                    >
                      {data?.right_fitted_device?.device_type ===
                      DEVICES.BTE_OPTIMA
                        ? " BTE OPTIMA"
                        : "BTE PRIME"}
                    </Typography>
                  )}
                </Box>
                <Box mt={6}>
                  <img
                    src={RightImg}
                    style={{
                      opacity: fitting.connected ? "1" : " 0.25",
                      width: "280px",
                      height: "33vh",
                    }}
                  />
                </Box>
                <Box mt={6}>
                  <CoonectDeviceModule
                    onConnectWithDevice={(data, deviceInfo, disconnectFun) => {
                      dispatch(
                        connectDevice(
                          data,
                          deviceInfo,
                          disconnectFun,
                          fitting.device_side
                        )
                      );
                    }}
                    Component={
                      data?.right_fitted_device?.selected
                        ? ConnectButton
                        : () => {
                            return <></>;
                          }
                    }
                    onLoadingChange={(loader, message) => {
                      dispatch(
                        changeLoadinfMessages(
                          loader,
                          message,
                          fitting.device_side
                        )
                      );
                    }}
                    onEnableChange={(val) =>
                      dispatch(
                        changeDeviceCompatiblity(val, fitting.device_side)
                      )
                    }
                    onWriteFunctionEnabled={(fun) =>
                      dispatch(onWriteFunctionChange(fun, fitting.device_side))
                    }
                    onDisconnect={() =>
                      dispatch(disconnectDevice(fitting.device_side))
                    }
                    fitting={fitting}
                  />
                </Box>
                {!fitting.connected && (
                  <Typography
                    fontWeight={500}
                    color="gray"
                    variant="h6"
                    mt={2}
                    mb={2}
                  >
                    {fitting.loadingMessage}
                  </Typography>
                )}
                {fitting.connected && (
                  <>
                    <Typography
                      sx={{
                        fontFamily: "League spartan",
                        fontSize: "20px",
                        fontWeight: 400,
                      }}
                    >
                      02567(Serial Number)
                    </Typography>

                    <Box mt={3}>
                      <Typography display={"flex"} justifyContent={"center"}>
                        {/* <CheckCircleIcon/> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="49"
                          viewGrid="0 0 48 49"
                          fill="none"
                        >
                          <path
                            d="M24 44.5C35.0457 44.5 44 35.5457 44 24.5C44 13.4543 35.0457 4.5 24 4.5C12.9543 4.5 4 13.4543 4 24.5C4 35.5457 12.9543 44.5 24 44.5Z"
                            fill="#39AC8A"
                          />
                          <path
                            d="M15 24.5L21 30.5L33 18.5"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Typography>
                      <Typography
                        sx={{
                          color: "#39AC8A",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        Connected
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
              {fitting.connected && (
                <Box>
                  <Button
                    onClick={() => {
                      dispatch(disconnectDevice(fitting.device_side));
                    }}
                    sx={{
                      textDecoration: "underline",
                      fontSize: "14px",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h6">Disconnect</Typography>
                  </Button>
                </Box>
              )}
            </Paper>
          </Grid>
          <Grid xs={6} md={6}>
            <Paper
              sx={{
                backgroundColor: "#F6FBFE",
                minHeight: "80vh",
                maxHeight: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box mt={6}>
                  {data?.right_fitted_device?.selected && (
                    <Typography
                      sx={{
                        color: "#1265A1",
                        fontFamily: "League spartan",
                        fontSize: "32px",
                        fontWeight: "bold",
                        lineHeight: "40px",
                      }}
                    >
                      {data?.right_fitted_device?.device_type ===
                      DEVICES.BTE_OPTIMA
                        ? " BTE OPTIMA"
                        : "BTE PRIME"}
                    </Typography>
                  )}
                </Box>
                <Box mt={6}>
                  <img
                    src={LeftImg}
                    style={{
                      opacity: fittingLeft.connected ? "1" : " 0.25",
                      width: "280px",
                      height: "33vh",
                    }}
                  />
                </Box>
                <Box mt={6}>
                  <CoonectDeviceModule
                    onConnectWithDevice={(data, deviceInfo, disconnectFun) => {
                      dispatch(
                        connectDevice(
                          data,
                          deviceInfo,
                          disconnectFun,
                          fittingLeft.device_side
                        )
                      );
                    }}
                    Component={
                      data?.right_fitted_device?.selected
                        ? ConnectButton
                        : () => {
                            return <></>;
                          }
                    }
                    onLoadingChange={(loader, message) => {
                      dispatch(
                        changeLoadinfMessages(
                          loader,
                          message,
                          fittingLeft.device_side
                        )
                      );
                    }}
                    onEnableChange={(val) =>
                      dispatch(
                        changeDeviceCompatiblity(val, fittingLeft.device_side)
                      )
                    }
                    onWriteFunctionEnabled={(fun) =>
                      dispatch(
                        onWriteFunctionChange(fun, fittingLeft.device_side)
                      )
                    }
                    onDisconnect={() =>
                      dispatch(disconnectDevice(fittingLeft.device_side))
                    }
                    fitting={fittingLeft}
                  />
                </Box>
                {!fittingLeft.connected && (
                  <Typography
                    fontWeight={500}
                    color="gray"
                    variant="h6"
                    mt={2}
                    mb={2}
                  >
                    {fittingLeft.loadingMessage}
                  </Typography>
                )}
                {fittingLeft.connected && (
                  <>
                    <Typography
                      sx={{
                        fontFamily: "League spartan",
                        fontSize: "20px",
                        fontWeight: 400,
                      }}
                    >
                      02567(Serial Number)
                    </Typography>

                    <Box mt={3}>
                      <Typography display={"flex"} justifyContent={"center"}>
                        {/* <CheckCircleIcon/> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="49"
                          viewGrid="0 0 48 49"
                          fill="none"
                        >
                          <path
                            d="M24 44.5C35.0457 44.5 44 35.5457 44 24.5C44 13.4543 35.0457 4.5 24 4.5C12.9543 4.5 4 13.4543 4 24.5C4 35.5457 12.9543 44.5 24 44.5Z"
                            fill="#39AC8A"
                          />
                          <path
                            d="M15 24.5L21 30.5L33 18.5"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Typography>
                      <Typography
                        sx={{
                          color: "#39AC8A",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        Connected
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
              {fittingLeft.connected && (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    onClick={() => {
                      dispatch(disconnectDevice(fittingLeft.device_side));
                    }}
                    sx={{
                      textDecoration: "underline",
                      fontSize: "14px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography variant="h6">Disconnect</Typography>
                  </Button>
                </Box>
              )}
            </Paper>
          </Grid>
          <Box
            p={4}
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              disabled={!(fitting.connected || fittingLeft.connected)}
              variant="contained"
              onClick={() => {
                setValue(1);
              }}
              sx={{
                fontSize: "16px",
                width: "150px",
                height: "40px",
                color: "white",
                fontFamily: "League spartan",
              }}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

const FittingMainController = () => {
  const dispatch = useDispatch();
  const { fitting } = useSelector((state) => state);
  const { fittingLeft } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [value, setValue] = useState(1);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const [isRest, setIsReset] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !fitting.connected &&
      !fitting.read_only &&
      !fitting.remote &&
      params.fitid == null &&
      !fittingLeft.connected &&
      !fittingLeft.read_only &&
      !fittingLeft.remote &&
      params.fitid == null
    ) {
      navigate(`/patient-details/${params.id}`);
    }
  }, [fitting, fittingLeft]);

  const [data, setData] = useState({});

  // const fetchDataById = (id) => {
  //   setLoading(true);
  //   dispatch(
  //     callApiAction(
  //       async () => await getPatientByIdApi({ id }),
  //       async (response) => {
  //         setData({ ...response });

  //         setLoading(false);
  //       },
  //       (err) => {
  //         setLoading(false);
  //       }
  //     )
  //   );
  // };

  const mutedHandle = async (fittingData) => {
    dispatch({ type: "MUTED_ACTION", side: fittingData.device_side });
    await fittingData?.writeFun?.writeData?.(fittingData.data);
  };

  const onResetBtnClick = () => {
    console.log("this is call for reset");
    dispatch(resetDevice(LISTENING_SIDE.BOTH));
    updateData();
    setIsReset(true);
  };

  const onAutoFit = (Newfitting, setAutoFitloading, selectAlgo) => {
    dispatch(
      autofitDevice(
        params.id,
        () => {
          dispatch(
            callSnackBar(
              "Device fitted as per patient audiogram.",
              SNACK_BAR_VARIETNS.suceess
            )
          );
          setAutoFitloading(false);
        },
        (err) => {
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
        },
        Newfitting,
        selectAlgo,
        () => {
          setShouldUpdate(true);
        }
      )
    );
  };

  useEffect(() => {
    if (shouldUpdate) {
      updateData();
      setShouldUpdate(false);
    }
  }, [shouldUpdate, fitting, fittingLeft]);

  const updateData = () => {
    const mpoLeft = [
      fittingLeft?.data[60],
      fittingLeft?.data[61],
      fittingLeft?.data[62],
      fittingLeft?.data[63],
      fittingLeft?.data[64],
      fittingLeft?.data[65],
      fittingLeft?.data[66],
      fittingLeft?.data[67],
    ];
    const mpo = [
      fitting?.data[60],
      fitting?.data[61],
      fitting?.data[62],
      fitting?.data[63],
      fitting?.data[64],
      fitting?.data[65],
      fitting?.data[66],
      fitting?.data[67],
    ];

    setData8L([
      ["", 125, 250, 500, "1k", "2k", "4k", "6k", "8k"],
      ["G50", ...createData(50, fittingLeft)],
      ["G65", ...createData(65, fittingLeft)],
      ["G80", ...createData(80, fittingLeft)],
      ["MPO", ...mpoLeft],
    ]);

    setData8R([
      ["", 125, 250, 500, "1k", "2k", "4k", "6k", "8k"],
      ["G50", ...createData(50, fitting)],
      ["G65", ...createData(65, fitting)],
      ["G80", ...createData(80, fitting)],
      ["MPO", ...mpo],
    ]);
    setData24L([
      [
        "",
        125,
        250,
        317,
        367,
        420,
        500,
        596,
        700,
        800,
        891,
        "1.1k",
        "1.2k",
        "1.4k",
        "1.7k",
        "2k",
        "2.3k",
        "2.8k",
        "3.2k",
        "3.5k",
        "4.5k",
        "5.1k",
        "5.5k",
        "6.3k",
        "8k",
      ],
      ["G50", ...createDataFor24(["G50", ...createData(50, fittingLeft)])],
      ["G65", ...createDataFor24(["G65", ...createData(65, fittingLeft)])],
      ["G80", ...createDataFor24(["G80", ...createData(80, fittingLeft)])],
      ["MPO", ...createDataFor24(["MPO", ...mpoLeft])],
    ]);

    setData24R([
      [
        "",
        125,
        250,
        317,
        367,
        420,
        500,
        596,
        700,
        800,
        891,
        "1.1k",
        "1.2k",
        "1.4k",
        "1.7k",
        "2k",
        "2.3k",
        "2.8k",
        "3.2k",
        "3.5k",
        "4.5k",
        "5.1k",
        "5.5k",
        "6.3k",
        "8k",
      ],
      ["G50", ...createDataFor24(["G50", ...createData(50, fitting)])],
      ["G65", ...createDataFor24(["G65", ...createData(65, fitting)])],
      ["G80", ...createDataFor24(["G80", ...createData(80, fitting)])],
      ["MPO", ...createDataFor24(["MPO", ...mpo])],
    ]);

    setFreShapR32([
      [...createDataFor32([...createData(50, fitting)])],
      [...createDataFor32([...createData(65, fitting)])],
      [...createDataFor32([...createData(80, fitting)])],
      [...createDataFor32([...mpo])],
    ]);

    setFreShapL32([
      [...createDataFor32([...createData(50, fittingLeft)])],
      [...createDataFor32([...createData(65, fittingLeft)])],
      [...createDataFor32([...createData(80, fittingLeft)])],
      [...createDataFor32([...mpoLeft])],
    ]);

    setMPOL32([...createDataFor32(mpoLeft)]);
    setMPOR32([...createDataFor32(mpo)]);
  };

  const [edgeVal, setEdgeVal] = useState();
  const createData = (envDb, currentFitting) => {
    let fittingData = currentFitting?.data;
    let deviceType = currentFitting?.device_type;
    const defaultDSummyValueSet = [0.6, 48, edgeVal, 70, 2, 100];

    const data = [];

    const round =
      deviceType == DEVICES.BTE_32 || deviceType == DEVICES.BTE_24
        ? 2
        : deviceType == DEVICES.BTE_16
        ? 1
        : 0;

    // for (let i = 0; i < 8; i++) {

    //     const startVal = 20

    //     const val = WDRC_circuit_gain(
    //         envDb,
    //         fittingData[startVal + 0 + i],
    //         fittingData[startVal + 8 + i],
    //         fittingData[startVal + 16 + i],
    //         fittingData[startVal + 24 + i],
    //         fittingData[startVal + 32 + i],
    //         fittingData[startVal + 40 + i],
    //     )

    //     data.push(val[0] - envDb)
    // }

    const startVal = 20;

    const temp = [[], [], [], [], [], []];
    for (let i = 0; i < 8; i++) {
      temp[0].push(fittingData[startVal + 0 + i]);
      temp[1].push(fittingData[startVal + 8 + i]);
      temp[2].push(fittingData[startVal + 16 + i]);
      temp[3].push(fittingData[startVal + 24 + i]);
      temp[4].push(fittingData[startVal + 32 + i]);
      temp[5].push(fittingData[startVal + 40 + i]);
    }

    const finalValArray = [
      findMediunsInBulk(temp[0], defaultDSummyValueSet[0], round),
      findMediunsInBulk(temp[1], defaultDSummyValueSet[1], round),
      findMediunsInBulk(temp[2], defaultDSummyValueSet[2], round),
      findMediunsInBulk(temp[3], defaultDSummyValueSet[3], round),
      findMediunsInBulk(temp[4], defaultDSummyValueSet[4], round),
      findMediunsInBulk(temp[5], defaultDSummyValueSet[5], round),
    ];
    if (deviceType == DEVICES.BTE_24) {
      for (let i = 0; i < finalValArray.length; i++) {
        // for (let subArr of finalValArray[i]) {

        const temp = [...finalValArray[i]];

        const newArray = temp.filter((item, index) => (index + 1) % 4 != 0);
        finalValArray[i] = newArray;

        // }
      }
    }

    for (let i = 0; i < finalValArray[0].length; i++) {
      const val = WDRC_circuit_gain(
        envDb,
        finalValArray[0][i] / 10,
        finalValArray[1][i],
        finalValArray[2][i],
        finalValArray[3][i],
        finalValArray[4][i] / 10,
        finalValArray[5][i]
      );

      data.push(Math.round(val[0] - envDb));
    }

    return data;
  };

  let mpoLeft = [
    fittingLeft?.data[60],
    fittingLeft?.data[61],
    fittingLeft?.data[62],
    fittingLeft?.data[63],
    fittingLeft?.data[64],
    fittingLeft?.data[65],
    fittingLeft?.data[66],
    fittingLeft?.data[67],
  ];
  let mpo = [
    fitting?.data[60],
    fitting?.data[61],
    fitting?.data[62],
    fitting?.data[63],
    fitting?.data[64],
    fitting?.data[65],
    fitting?.data[66],
    fitting?.data[67],
  ];
  const [data8L, setData8L] = useState([
    ["", 125, 250, 500, "1k", "2k", "4k", "6k", "8k"],
    ["G50", ...createData(50, fittingLeft)],
    ["G65", ...createData(65, fittingLeft)],
    ["G80", ...createData(80, fittingLeft)],
    ["MPO", ...mpoLeft],
  ]);

  const [data8R, setData8R] = useState([
    ["", 125, 250, 500, "1k", "2k", "4k", "6k", "8k"],
    ["G50", ...createData(50, fitting)],
    ["G65", ...createData(65, fitting)],
    ["G80", ...createData(80, fitting)],
    ["MPO", ...mpo],
  ]);

  const createDataFor24 = (currentData) => {
    let data = [];
    currentData.map((val, index) => {
      if (index == 1) {
        data.push(val);
      } else if (index >= 1) {
        let values = generateValues(val);
        data.push(values[0]);
        data.push(values[1]);
        data.push(values[2]);
      }
    });
    data.push(data[21] + 1);
    data.push(data[20] + 1);
    return data;
  };

  const createDataFor24to8 = (currentData) => {
    let data = [];
    data.push(currentData[1]);
    for (let i = 2; i <= 22; i += 3) {
      data.push(
        Math.round(
          (currentData[i] + currentData[i + 1] + currentData[i + 2]) / 3
        )
      );
    }
    return data;
  };

  const createDataFor32 = (currentData) => {
    let data = [];
    currentData.map((val, index) => {
      if (index == 0) {
        data.push(val);
      } else if (index >= 1) {
        let values = generateValues4(val);
        data.push(values[0]);
        data.push(values[1]);
        data.push(values[2]);
        data.push(values[3]);
      }
    });
    data.push(data[26] + 1);
    data.push(data[27] + 1);
    data.push(data[28] + 1);
    return data;
  };

  const createDataFor32to8 = (currentData) => {
    let data = [];
    data.push(currentData[0]);
    for (let i = 1; i <= 28; i += 4) {
      data.push(
        Math.round(
          (currentData[i] +
            currentData[i + 1] +
            currentData[i + 2] +
            currentData[i + 3]) /
            4
        )
      );
    }
    return data;
  };

  const [mpoL32, setMPOL32] = useState([...createDataFor32(mpoLeft)]);
  const [mpoR32, setMPOR32] = useState([...createDataFor32(mpo)]);
  const [freShapL32, setFreShapL32] = useState([
    [
      ...createDataFor32([
        data8L[1][1],
        data8L[1][2],
        data8L[1][3],
        data8L[1][4],
        data8L[1][5],
        data8L[1][6],
        data8L[1][7],
        data8L[1][8],
      ]),
    ],
    [
      ...createDataFor32([
        data8L[2][1],
        data8L[2][2],
        data8L[2][3],
        data8L[2][4],
        data8L[2][5],
        data8L[2][6],
        data8L[2][7],
        data8L[2][8],
      ]),
    ],
    [
      ...createDataFor32([
        data8L[3][1],
        data8L[3][2],
        data8L[3][3],
        data8L[3][4],
        data8L[3][5],
        data8L[3][6],
        data8L[3][7],
        data8L[3][8],
      ]),
    ],
    [
      ...createDataFor32([
        data8L[4][1],
        data8L[4][2],
        data8L[4][3],
        data8L[4][4],
        data8L[4][5],
        data8L[4][6],
        data8L[4][7],
        data8L[4][8],
      ]),
    ],
  ]);
  const [freShapR32, setFreShapR32] = useState([
    [
      ...createDataFor32([
        data8R[1][1],
        data8R[1][2],
        data8R[1][3],
        data8R[1][4],
        data8R[1][5],
        data8R[1][6],
        data8R[1][7],
        data8R[1][8],
      ]),
    ],
    [
      ...createDataFor32([
        data8R[2][1],
        data8R[2][2],
        data8R[2][3],
        data8R[2][4],
        data8R[2][5],
        data8R[2][6],
        data8R[2][7],
        data8R[2][8],
      ]),
    ],
    [
      ...createDataFor32([
        data8R[3][1],
        data8R[3][2],
        data8R[3][3],
        data8R[3][4],
        data8R[3][5],
        data8R[3][6],
        data8R[3][7],
        data8R[3][8],
      ]),
    ],
    [
      ...createDataFor32([
        data8R[4][1],
        data8R[4][2],
        data8R[4][3],
        data8R[4][4],
        data8R[4][5],
        data8R[4][6],
        data8R[4][7],
        data8R[4][8],
      ]),
    ],
  ]);

  const [data24L, setData24L] = useState([
    [
      "",
      125,
      250,
      317,
      367,
      420,
      500,
      596,
      700,
      800,
      891,
      "1.1k",
      "1.2k",
      "1.4k",
      "1.7k",
      "2k",
      "2.3k",
      "2.8k",
      "3.2k",
      "3.5k",
      "4.5k",
      "5.1k",
      "5.5k",
      "6.3k",
      "8k",
    ],
    ["G50", ...createDataFor24(data8L[1])],
    ["G65", ...createDataFor24(data8L[2])],
    ["G80", ...createDataFor24(data8L[3])],
    ["MPO", ...createDataFor24(["MPO", ...mpoLeft])],
  ]);

  const [data24R, setData24R] = useState([
    [
      "",
      125,
      250,
      317,
      367,
      420,
      500,
      596,
      700,
      800,
      891,
      "1.1k",
      "1.2k",
      "1.4k",
      "1.7k",
      "2k",
      "2.3k",
      "2.8k",
      "3.2k",
      "3.5k",
      "4.5k",
      "5.1k",
      "5.5k",
      "6.3k",
      "8k",
    ],
    ["G50", ...createDataFor24(data8R[1])],
    ["G65", ...createDataFor24(data8R[2])],
    ["G80", ...createDataFor24(data8R[3])],
    ["MPO", ...createDataFor24(["MPO", ...mpo])],
  ]);

  const onAdvanceFitting = () => {
    mpoLeft = [
      fittingLeft?.data[60],
      fittingLeft?.data[61],
      fittingLeft?.data[62],
      fittingLeft?.data[63],
      fittingLeft?.data[64],
      fittingLeft?.data[65],
      fittingLeft?.data[66],
      fittingLeft?.data[67],
    ];
    mpo = [
      fitting?.data[60],
      fitting?.data[61],
      fitting?.data[62],
      fitting?.data[63],
      fitting?.data[64],
      fitting?.data[65],
      fitting?.data[66],
      fitting?.data[67],
    ];

    if (fittingLeft.device_type == DEVICES.BTE_PRIME) {
      setData8L((prev) => {
        const arr = [...prev];
        arr[1] = ["G50", ...createDataFor32to8(freShapL32[0])];
        arr[4] = ["MPO", ...mpoLeft];
        return arr;
      });
    }

    if (fitting.device_type == DEVICES.BTE_PRIME) {
      setData8R((prev) => {
        const arr = [...prev];
        arr[1] = ["G50", ...createDataFor32to8(freShapR32[0])];
        arr[4] = ["MPO", ...mpo];
        return arr;
      });
    }

    // setData24L((prev) => {
    //   const arr = [...prev];
    //   arr[1] = ["G50", ...createDataFor24(data8L[1])];
    //   arr[4] = ["MPO", ...createDataFor24(["MPO", ...mpoLeft])];
    //   return arr;
    // });

    // setData24R((prev) => {
    //   const arr = [...prev];
    //   arr[1] = ["G50", ...createDataFor24(data8R[1])];
    //   arr[4] = ["MPO", ...createDataFor24(["MPO", ...mpo])];
    //   return arr;
    // });
    setselectButton(FITTING_BUTTON.ADVANCE_FITTING);
  };

  const onFineTunning = () => {
    setFreShapL32([
      createDataFor32([
        data8L[1][1],
        data8L[1][2],
        data8L[1][3],
        data8L[1][4],
        data8L[1][5],
        data8L[1][6],
        data8L[1][7],
        data8L[1][8],
      ]),
      createDataFor32([
        data8L[2][1],
        data8L[2][2],
        data8L[2][3],
        data8L[2][4],
        data8L[2][5],
        data8L[2][6],
        data8L[2][7],
        data8L[2][8],
      ]),
      createDataFor32([
        data8L[3][1],
        data8L[3][2],
        data8L[3][3],
        data8L[3][4],
        data8L[3][5],
        data8L[3][6],
        data8L[3][7],
        data8L[3][8],
      ]),
      createDataFor32([
        data8L[4][1],
        data8L[4][2],
        data8L[4][3],
        data8L[4][4],
        data8L[4][5],
        data8L[4][6],
        data8L[4][7],
        data8L[4][8],
      ]),
    ]);
    setFreShapR32([
      createDataFor32([
        data8R[1][1],
        data8R[1][2],
        data8R[1][3],
        data8R[1][4],
        data8R[1][5],
        data8R[1][6],
        data8R[1][7],
        data8R[1][8],
      ]),
      createDataFor32([
        data8R[2][1],
        data8R[2][2],
        data8R[2][3],
        data8R[2][4],
        data8R[2][5],
        data8R[2][6],
        data8R[2][7],
        data8R[2][8],
      ]),
      createDataFor32([
        data8R[3][1],
        data8R[3][2],
        data8R[3][3],
        data8R[3][4],
        data8R[3][5],
        data8R[3][6],
        data8R[3][7],
        data8R[3][8],
      ]),
      createDataFor32([
        data8R[4][1],
        data8R[4][2],
        data8R[4][3],
        data8R[4][4],
        data8R[4][5],
        data8R[4][6],
        data8R[4][7],
        data8R[4][8],
      ]),
    ]);
    setMPOL32([...createDataFor32(mpoLeft)]);
    setMPOR32([...createDataFor32(mpo)]);
    setselectButton(FITTING_BUTTON.FINE_TUNNING);
  };

  const [completeLoading, setCompleteLoading] = useState(false);
  const [saveLoadin, setSaveLoading] = useState(false);

  const onSaveFittingHelper = async (
    complete,
    callBack = () => {},
    callBack1 = () => {},
    nextstep,
    Fittingdata
  ) => {
    if (complete === true) setCompleteLoading(true);
    else {
      setSaveLoading(true);
    }
    const finalFittingData = Fittingdata.data;
    console.log("before finalFittingData", finalFittingData);
    if (Fittingdata.device_type == DEVICES.BTE_32) {
      console.log("inside");
      finalFittingData[10] = 66;
      finalFittingData[11] = 84;
      finalFittingData[12] = 69;
      finalFittingData[13] = 51;
      finalFittingData[14] = 50;
      finalFittingData[15] = 95;
      if (Fittingdata.device_side == LISTENING_SIDE.LEFT) {
        finalFittingData[16] = 76;
      } else {
        finalFittingData[16] = 82;
      }
      finalFittingData[17] = 0;
      finalFittingData[18] = 0;
      finalFittingData[19] = 0;
    }
    console.log("after finalFittingData", finalFittingData);
    finalFittingData[GAIN_POINTS.MASTER_GAIN] = mapValues(
      Fittingdata.master_gain
    );
    finalFittingData[GAIN_POINTS.NOISE_REDUCTION] = mapValues(
      Fittingdata.noise_reduction
    );
    finalFittingData[GAIN_POINTS.LOUDNESS_COMPRESSION] = mapValues(
      Fittingdata.loudness_compression
    );
    finalFittingData[GAIN_POINTS.SELF_SPEECH_REDUCTION] = mapValues(
      Fittingdata.self_speech_reduction
    );
    finalFittingData[GAIN_POINTS.SHARPNESS] = mapValues(Fittingdata.sharpness);
    finalFittingData[GAIN_POINTS.WIND_NOISEREDUCTION] = mapValues(
      Fittingdata.wind_noise_reduction
    );

    await Fittingdata?.writeFun?.writeData?.(finalFittingData);

    const passData = {
      patient_id: params.id,
      device_id: Fittingdata.deviceInfo?.id,
      device_name: Fittingdata.deviceInfo?.name,
      device_type: Fittingdata.device_type,
      device_side: Fittingdata.device_side,
      master_gain: Fittingdata.master_gain,
      noise_reduction: Fittingdata.noise_reduction,
      loudness_compression: Fittingdata.loudness_compression,
      self_speech_reduction: Fittingdata.self_speech_reduction,
      sharpness: Fittingdata.sharpness,
      wind_noise_reduction: Fittingdata.wind_noise_reduction,
      fitting_data: Fittingdata.data,
      remarks: Fittingdata.remarks,
    };

    if (Fittingdata?.mode_data) {
      passData["mode_data"] = Fittingdata.mode_data;
    }

    if (Fittingdata.profile_id) {
      dispatch(
        callApiAction(
          async () =>
            await updatePatientProfileField({
              id: Fittingdata.profile_id,
              ...passData,
            }),
          async (response) => {
            if (nextstep) {
              callBack();
              callBack1();
              dispatch(
                callSnackBar(
                  " Fitting Saved Successfully",
                  SNACK_BAR_VARIETNS.suceess
                )
              );
              // if (complete === true) {
              //   navigate(`/patient-details/${params.id}`);
              // }
            }
            if (complete === true) setCompleteLoading(false);
            else {
              setSaveLoading(false);
            }
          },
          (err) => {
            if (complete === true) setCompleteLoading(false);
            else {
              setSaveLoading(false);
            }
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
          }
        )
      );
    } else {
      console.log("2");
      dispatch(
        callApiAction(
          async () => await addPatientProfileApi(passData),
          async (response) => {
            dispatch(changeProfileId(response._id, Fittingdata.device_side));

            if (nextstep) {
              callBack();
              callBack1();
              dispatch(
                callSnackBar(
                  " Fitting Saved Successfully",
                  SNACK_BAR_VARIETNS.suceess
                )
              );
              // if (complete === true) {
              //   navigate(`/patient-details/${params.id}`);
              // }
            }
            if (complete === true) setCompleteLoading(false);
            else {
              setSaveLoading(false);
            }
          },
          (err) => {
            if (complete === true) setCompleteLoading(false);
            else {
              setSaveLoading(false);
            }
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
          }
        )
      );
    }
  };

  const [readLoading, setReadLoading] = useState(false);

  const onReadFromDeviceBtnClick = async () => {
    setReadLoading(true);
    const data1 = await fittingLeft.writeFun?.readData(fittingLeft.data);
    const data = await fitting.writeFun?.readData(fitting.data);
    console.log("this is data 1 ", data1);
    console.log("this is data ", data);

    setReadLoading(false);

    if (fitting.connected) {
      dispatch(
        readDataFromDevice(data, fitting.device_side, (fittingData) => {
          mpo = [
            fittingData?.data[60],
            fittingData?.data[61],
            fittingData?.data[62],
            fittingData?.data[63],
            fittingData?.data[64],
            fittingData?.data[65],
            fittingData?.data[66],
            fittingData?.data[67],
          ];
          setData8R([
            ["", 125, 250, 500, "1k", "2k", "4k", "6k", "8k"],
            ["G50", ...createData(50, fittingData)],
            ["G65", ...createData(65, fittingData)],
            ["G80", ...createData(80, fittingData)],
            ["MPO", ...mpo],
          ]);
          setData24R([
            [
              "",
              125,
              250,
              317,
              367,
              420,
              500,
              596,
              700,
              800,
              891,
              "1.1k",
              "1.2k",
              "1.4k",
              "1.7k",
              "2k",
              "2.3k",
              "2.8k",
              "3.2k",
              "3.5k",
              "4.5k",
              "5.1k",
              "5.5k",
              "6.3k",
              "8k",
            ],
            [
              "G50",
              ...createDataFor24(["G50", ...createData(50, fittingData)]),
            ],
            [
              "G65",
              ...createDataFor24(["G65", ...createData(65, fittingData)]),
            ],
            [
              "G80",
              ...createDataFor24(["G80", ...createData(80, fittingData)]),
            ],
            ["MPO", ...createDataFor24(["MPO", ...mpo])],
          ]);
          setFreShapR32([
            [...createDataFor32([...createData(50, fittingData)])],
            [...createDataFor32([...createData(65, fittingData)])],
            [...createDataFor32([...createData(80, fittingData)])],
            [...createDataFor32([...mpo])],
          ]);
          setMPOR32([...createDataFor32(mpo)]);
        })
      );
    }

    if (fittingLeft.connected) {
      dispatch(
        readDataFromDevice(data1, fittingLeft.device_side, (fittingData) => {
          mpoLeft = [
            fittingData?.data[60],
            fittingData?.data[61],
            fittingData?.data[62],
            fittingData?.data[63],
            fittingData?.data[64],
            fittingData?.data[65],
            fittingData?.data[66],
            fittingData?.data[67],
          ];

          console.log("this is mpo", mpo);

          setData8L([
            ["", 125, 250, 500, "1k", "2k", "4k", "6k", "8k"],
            ["G50", ...createData(50, fittingData)],
            ["G65", ...createData(65, fittingData)],
            ["G80", ...createData(80, fittingData)],
            ["MPO", ...mpoLeft],
          ]);

          setData24L([
            [
              "",
              125,
              250,
              317,
              367,
              420,
              500,
              596,
              700,
              800,
              891,
              "1.1k",
              "1.2k",
              "1.4k",
              "1.7k",
              "2k",
              "2.3k",
              "2.8k",
              "3.2k",
              "3.5k",
              "4.5k",
              "5.1k",
              "5.5k",
              "6.3k",
              "8k",
            ],
            [
              "G50",
              ...createDataFor24(["G50", ...createData(50, fittingData)]),
            ],
            [
              "G65",
              ...createDataFor24(["G65", ...createData(65, fittingData)]),
            ],
            [
              "G80",
              ...createDataFor24(["G80", ...createData(80, fittingData)]),
            ],
            ["MPO", ...createDataFor24(["MPO", ...mpoLeft])],
          ]);
          setFreShapL32([
            [...createDataFor32([...createData(50, fittingData)])],
            [...createDataFor32([...createData(65, fittingData)])],
            [...createDataFor32([...createData(80, fittingData)])],
            [...createDataFor32([...mpoLeft])],
          ]);
          setMPOL32([...createDataFor32(mpoLeft)]);
        })
      );
    }
  };

  const [complete, setComplete] = useState(false);

  const onSaveFitting = async () => {
    fitting.connected &&
      (await onSaveFittingHelper(
        false,
        () => {},
        () => {},
        !fittingLeft.connected,
        fitting
      ));
    fittingLeft.connected &&
      (await onSaveFittingHelper(
        false,
        () => {},
        () => {},
        true,
        fittingLeft
      ));
  };

  const onCompleteSession = async (e, fittingR, fittingL) => {
    e.preventDefault();
    fittingR.connected &&
      (await onSaveFittingHelper(
        true,
        () => {
          fitting.disconnectFun?.();
          dispatch(disconnectDevice(fitting.device_side));
        },
        () => {
          fittingLeft.disconnectFun?.();
          dispatch(disconnectDevice(fittingLeft.device_side));
        },
        !fittingL.connected,
        fittingR
      ));
    fittingL.connected &&
      (await onSaveFittingHelper(
        true,
        () => {
          fittingLeft.disconnectFun?.();
        },
        () => {
          fitting.disconnectFun?.();
        },
        true,
        fittingL
      ));
    dispatch(closeModal("complete"));
    setComplete(true);
  };

  // useEffect(() => {
  //   fetchDataById(params.id);
  // }, []);

  useEffect(() => {
    if (complete) {
      navigate(`/patient-details/${params.id}`);
      if (
        (fitting.connected && !fitting.read_only && !fitting.remote) ||
        (fittingLeft.connected && !fittingLeft.read_only && !fittingLeft.remote)
      ) {
        dispatch(disconnectDevice(LISTENING_SIDE.BOTH));
      }
      dispatch(resetReducer());
      setComplete(false);
    }
  }, [complete]);

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getPatientProfileByIdApi({ id }),
        async (response) => {
          if (response["_id"]) {
            dispatch(
              changeFittingData(response, params.readonly, response.device_side)
            );
            if (response.device_side === LISTENING_SIDE.LEFT) {
              if (!fittingLeft.connected && !params.readonly) {
                console.log(
                  "this is run for fetchbyid left",
                  fittingLeft.read_only
                );
                dispatch(applyForRemoteFitting(LISTENING_SIDE.LEFT));
              }
            } else {
              if (!fitting.connected && !params.readonly) {
                console.log("this is run for fetchbyid left");
                dispatch(applyForRemoteFitting(LISTENING_SIDE.RIGHT));
              }
            }
          }

          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (params.fitid) {
      fetchById(params.fitid);
    }

    if (params.fitid1) {
      fetchById(params.fitid1);
    }
  }, [params.fitid]);

  const [isModeChange, setIsModeChange] = useState(false);

  const handleChangee = async (event) => {
    if (fitting.read_only || fittingLeft.read_only || params.fitid) {
      console.log("this is fitting and data is", fitting);
      console.log("this is fitting and data is", fittingLeft);
      if (fitting.read_only || (fitting.connected && params.fitid)) {
        dispatch(
          changeMode(
            fitting?.mode_data[event.target.value],
            LISTENING_SIDE.RIGHT
          )
        );
        setShouldUpdate(true);
      }

      if (fittingLeft.read_only || (fittingLeft.connected && params.fitid)) {
        dispatch(
          changeMode(
            fittingLeft?.mode_data[event.target.value],
            LISTENING_SIDE.LEFT
          )
        );
        setShouldUpdate(true);
      }
    } else {
      if (fitting.remote || fittingLeft.remote) {
        dispatch(changeByteValue(0, event.target.value, LISTENING_SIDE.BOTH));
      } else {
        await fitting?.writeFun?.writeData?.([170, 0, 0, event.target.value]);
        await fittingLeft?.writeFun?.writeData?.([
          170,
          0,
          0,
          event.target.value,
        ]);
        setIsModeChange(true);
      }
    }
  };

  useEffect(() => {
    if (isModeChange) {
      onReadFromDeviceBtnClick();
      setIsModeChange(false);
    }
  }, [fitting, fittingLeft, isModeChange]);

  const [selectButton, setselectButton] = useState(
    FITTING_BUTTON.BASIC_FITTING
  );

  return (
    <>
      {loading ? (
        <Box sx={{ paddingTop: "50vh" }}>
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", width: "42%" }}>
              <IconButton
                sx={{ marginRight: "5px" }}
                onClick={() => {
                  if (
                    fitting.connected &&
                    !fitting.read_only &&
                    !fitting.remote
                  ) {
                    dispatch(disconnectDevice(fitting.device_side));
                  } else {
                    dispatch(resetReducer());
                  }

                  if (
                    fittingLeft.connected &&
                    !fittingLeft.read_only &&
                    !fittingLeft.remote
                  ) {
                    dispatch(disconnectDevice(fittingLeft.device_side));
                  } else {
                    dispatch(resetReducer());
                  }
                  navigate(`/patient-details/${params.id}`);
                }}
              >
                <ArrowBackIcon sx={{ color: "#2D3B67" }} fontSize="medium" />
              </IconButton>
              <Tabs
                value={value}
                // onChange={handleChange}
                aria-label="basic tabs example"
                sx={{ height: "0px" }}
              >
                {/* <Tab
                  onClick={() => {
                    if (fitting.connected && !fitting.read_only) {
                      fitting.disconnectFun?.();
                      dispatch(disconnectDevice(fitting.device_side));
                    } else {
                      dispatch(resetToDefault(fitting.device_side));
                    }

                    if (fittingLeft.connected && !fittingLeft.read_only) {
                      fittingLeft.disconnectFun?.();
                      dispatch(disconnectDevice(fittingLeft.device_side));
                    } else {
                      dispatch(resetToDefault(fittingLeft.device_side));
                    }
                  }}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="h6" fontSize="27px">
                        Connect
                      </Typography>
                      {(fitting.connected || fittingLeft.connected) && (
                        <CheckCircle sx={{ color: "green" }} />
                      )}
                    </Box>
                  }
                  {...a11yProps(0)}
                /> */}

                <Tab
                  label="Fitting"
                  {...a11yProps(1)}
                  // disabled={!(value >= 1)}
                />
              </Tabs>
            </Box>

            <Box width={"28%"} display={"flex"} justifyContent={"center"}>
              <Select
                sx={{
                  height: "7.6vh",
                  fontSize: "16px",
                  fontFamily: "League spartan",
                  color: "#4D4D4D",
                  width: "11vw",
                  display: "flex",
                  backgroundColor: "#EDF0F7",
                  flexDirection: "row",
                  borderRadius: "0px",
                }}
                value={
                  fitting.connected ? fitting.data[0] : fittingLeft.data[0]
                }
                onChange={handleChangee}
              >
                <MenuItem
                  sx={{
                    // fontSize: "12px",
                    // fontFamily: "League spartan",
                    color: "#4D4D4D",
                  }}
                  value={0}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography
                      sx={{
                        color: "#2D3B67",
                        fontWeight: "500",
                        fontFamilymily: "League spartan",
                        fontSize: "14px",
                      }}
                    >
                      Mode 1 : Default
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem
                  sx={{
                    fontSize: "16px",
                    fontFamily: "League spartan",
                    color: "#4D4D4D",
                  }}
                  value={1}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography
                      sx={{
                        color: "#2D3B67",
                        fontWeight: "500",
                        fontFamilymily: "League spartan",
                        fontSize: "14px",
                      }}
                    >
                      Mode 2 : Outdoor
                    </Typography>
                  </Box>
                </MenuItem>

                <MenuItem
                  sx={{
                    fontSize: "16px",
                    fontFamily: "League spartan",
                    color: "#4D4D4D",
                  }}
                  value={2}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography
                      sx={{
                        color: "#2D3B67",
                        fontWeight: "500",
                        fontFamilymily: "League spartan",
                        fontSize: "14px",
                      }}
                    >
                      Mode 3 : Meeting
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem
                  sx={{
                    fontSize: "16px",
                    fontFamily: "League spartan",
                    color: "#4D4D4D",
                  }}
                  value={3}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography
                      sx={{
                        color: "#2D3B67",
                        fontWeight: "500",
                        fontFamilymily: "League spartan",
                        fontSize: "14px",
                      }}
                    >
                      Mode 4 : Balanced
                    </Typography>
                  </Box>
                </MenuItem>
              </Select>
            </Box>

            <Box width="30%" display={"flex"} justifyContent="flex-end">
              {!(fitting.read_only || fittingLeft.read_only) && (
                <Box mt={2}>
                  <Button
                    onClick={onSaveFitting}
                    sx={{
                      textDecoration: "underline",
                      fontFamily: "League spartan",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    <img src={SAVEIMAGE} alt="SAVEIMAGE" />
                  </Button>
                  {!(fitting.remote || fittingLeft.remote) && (
                    <Button
                      onClick={onReadFromDeviceBtnClick}
                      sx={{
                        textDecoration: "underline",
                        fontFamily: "League spartan",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      <img src={READIMAGE} alt="READIMAGE" />
                    </Button>
                  )}
                  <Button
                    onClick={onResetBtnClick}
                    sx={{
                      textDecoration: "underline",
                      fontFamily: "League spartan",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    <img src={RESETIMAGE} alt="RESETIMAGE" />
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          {!loading && fitting.enabled === false && (
            <NoDataComponent
              message={"Your browser does'nt support this software."}
              Icon={Error}
            />
          )}
          {
            <>
              <CustomTabPanel value={value} index={0} sx={{ height: "0px" }}>
                <ConncetionScreen
                  disabled={params.fitid && params.fitid != ""}
                  show={
                    !fitting.connected && fitting.enabled !== false && !loading
                  }
                  setValue={setValue}
                  data={data}
                />
              </CustomTabPanel>
            </>
          }
          <CustomTabPanel value={value} index={1} sx={{ height: "0px" }}>
            <Grid container>
              <Grid md={1.7} xs={1.7}>
                <Box
                  p={2}
                  height={"100vh"}
                  sx={{ borderRight: "1px solid #DDDDDD" }}
                >
                  <Box>
                    <Button
                      sx={{
                        fontFamily: "League spartan",
                        width: "13vw",
                        height: "6vh",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          selectButton === FITTING_BUTTON.BASIC_FITTING
                            ? "#EDF0F7"
                            : "white",
                        border:
                          selectButton === FITTING_BUTTON.BASIC_FITTING
                            ? "1px solid #2D3B67"
                            : "1px solid #F2F2F2",
                      }}
                      onClick={() => {
                        setselectButton(FITTING_BUTTON.BASIC_FITTING);
                      }}
                    >
                      <Typography variant="h6">Basic Fitting</Typography>
                    </Button>
                  </Box>
                  <Box mt={2}>
                    <Button
                      sx={{
                        fontFamily: "League spartan",
                        fontSize: "16px",
                        width: "13vw",
                        fontWeight: "large",
                        height: "6vh",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          selectButton === FITTING_BUTTON.FINE_TUNNING
                            ? "#EDF0F7"
                            : "white",
                        border:
                          selectButton === FITTING_BUTTON.FINE_TUNNING
                            ? "1px solid #2D3B67"
                            : "1px solid #F2F2F2",
                      }}
                      onClick={onFineTunning}
                    >
                      <Typography variant="h6">Fine Tunning</Typography>
                    </Button>
                  </Box>
                  <Box mt={2}>
                    <Button
                      sx={{
                        fontFamily: "League spartan",
                        fontSize: "16px",
                        width: "13vw",
                        fontWeight: "large",
                        height: "6vh",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          selectButton === FITTING_BUTTON.ADVANCE_FITTING
                            ? "#EDF0F7"
                            : "white",
                        border:
                          selectButton === FITTING_BUTTON.ADVANCE_FITTING
                            ? "1px solid #2D3B67"
                            : "1px solid #F2F2F2",
                      }}
                      onClick={onAdvanceFitting}
                    >
                      <Typography variant="h6">Advance Fitting</Typography>
                    </Button>
                  </Box>
                  {!(fitting.read_only || fittingLeft.read_only) && (
                    <Box mt={2}>
                      <Button
                        sx={{
                          fontFamily: "League spartan",
                          fontSize: "16px",
                          width: "13vw",
                          fontWeight: "large",
                          height: "6vh",
                          display: "flex",
                          justifyContent: "flex-start",
                          backgroundColor:
                            selectButton === FITTING_BUTTON.CONFIG_ASSISTANT
                              ? "#EDF0F7"
                              : "white",
                          border:
                            selectButton === FITTING_BUTTON.CONFIG_ASSISTANT
                              ? "1px solid #2D3B67"
                              : "1px solid #F2F2F2",
                        }}
                        onClick={() => {
                          setselectButton(FITTING_BUTTON.CONFIG_ASSISTANT);
                        }}
                      >
                        <Typography variant="h6">Config Assistant</Typography>
                      </Button>
                    </Box>
                  )}
                  <Box mt={2}>
                    <Button
                      sx={{
                        fontFamily: "League spartan",
                        fontSize: "16px",
                        width: "13vw",
                        fontWeight: "large",
                        height: "6vh",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          selectButton === FITTING_BUTTON.MANAGE_PROGRAM
                            ? "#EDF0F7"
                            : "white",
                        border:
                          selectButton === FITTING_BUTTON.MANAGE_PROGRAM
                            ? "1px solid #2D3B67"
                            : "1px solid #F2F2F2",
                      }}
                      onClick={() => {
                        setselectButton(FITTING_BUTTON.MANAGE_PROGRAM);
                      }}
                    >
                      <Typography variant="h6"> Manage Program</Typography>
                    </Button>
                  </Box>

                  {!(fitting.read_only || fittingLeft.read_only) && (
                    <Box mt={2}>
                      <Button
                        sx={{
                          fontFamily: "League spartan",
                          fontSize: "16px",
                          width: "13vw",
                          fontWeight: "large",
                          height: "6vh",
                          display: "flex",
                          justifyContent: "flex-start",
                          backgroundColor:
                            selectButton === FITTING_BUTTON.COMPLETE_SESSION
                              ? "#EDF0F7"
                              : "white",
                          border:
                            selectButton === FITTING_BUTTON.COMPLETE_SESSION
                              ? "1px solid #2D3B67"
                              : "1px solid #F2F2F2",
                        }}
                        onClick={() => {
                          setselectButton(FITTING_BUTTON.COMPLETE_SESSION);
                          dispatch(
                            openModal(
                              <CompleteSessionController
                                onCompleteSession={onCompleteSession}
                                id="complete"
                              />,
                              "sm",
                              false,
                              "complete"
                            )
                          );
                        }}
                      >
                        <Typography variant="h6">Complete Session</Typography>
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>
              {value == 1 && selectButton === FITTING_BUTTON.BASIC_FITTING && (
                <Grid item xs={10}>
                  <BasicFittingAreaController
                    mutedHandle={mutedHandle}
                    onAutoFit={onAutoFit}
                  />
                </Grid>
              )}
              {value == 1 && selectButton === FITTING_BUTTON.FINE_TUNNING && (
                <Grid item xs={10}>
                  <FineTunningController
                    mutedHandle={mutedHandle}
                    data8R={data8R}
                    setData8R={setData8R}
                    data8L={data8L}
                    setData8L={setData8L}
                    setData24R={setData24R}
                    setData24L={setData24L}
                    data24L={data24L}
                    mpoR32={mpoR32}
                    mpoL32={mpoL32}
                    setMPOL32={setMPOL32}
                    setMPOR32={setMPOR32}
                    data24R={data24R}
                    setFreShapR32={setFreShapR32}
                    freShapR32={freShapR32}
                    setFreShapL32={setFreShapL32}
                    freShapL32={freShapL32}
                  />
                </Grid>
              )}
              {value == 1 && selectButton === FITTING_BUTTON.MANAGE_PROGRAM && (
                <Grid item xs={10}>
                  <ManageProgramController />
                </Grid>
              )}
              {value == 1 &&
                selectButton === FITTING_BUTTON.ADVANCE_FITTING && (
                  <Grid item xs={10}>
                    <AdvanceFittingController
                      mutedHandle={mutedHandle}
                      data8R={data8R}
                      setData8R={setData8R}
                      data8L={data8L}
                      setData8L={setData8L}
                      setData24R={setData24R}
                      setData24L={setData24L}
                      data24L={data24L}
                      data24R={data24R}
                      createDataFor24={createDataFor24}
                      createDataFor24to8={createDataFor24to8}
                    />
                  </Grid>
                )}
              {value == 1 &&
                selectButton === FITTING_BUTTON.CONFIG_ASSISTANT && (
                  <Grid item xs={10}>
                    <ConfigAssistantController
                      isRest={isRest}
                      setIsReset={setIsReset}
                      mutedHandle={mutedHandle}
                    />
                  </Grid>
                )}
            </Grid>
          </CustomTabPanel>
        </>
      )}
    </>
  );
};

export default FittingMainController;
