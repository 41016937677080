import { Box, Collapse, Paper, SwipeableDrawer, useMediaQuery } from "@mui/material"
import { useDispatch } from 'react-redux'
import { Outlet, useLocation, useParams, } from "react-router-dom"



import { useState } from "react"
import { memo } from "react"
import AppModeLabel from "../../texts/AppModeLabel"
import PopUpModal from "../../Modal"
import Logo from "./Logo"
import Header from "./Header"





const containerStyle = (theme) => ({ height: "100%", width: "100%", display: "flex", background: theme.palette.light.main, overflow: "hidden" })



const rightContainerStyle = (theme) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  overflow: "hidden",

})
const workAreaStyle = (theme) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  flex: 1,
  overflow: "hidden",
 // background: theme.palette.grey.main,
  borderTopLeftRadius: theme.shape.borderRadius * 1

})


const AppContainer = (props) => {

  const dispatch = useDispatch()
  const params = useParams();
  const location = useLocation();

  const [navBar, setNavbar] = useState(true)


  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"))


  return (
    <>
      {
        process.env.REACT_APP_APP_MODE != 'production' && <AppModeLabel />
      }

      <PopUpModal />

      <Box sx={containerStyle}>


        <Box sx={rightContainerStyle}>
          {(location.pathname == "/patient-details/" || location.pathname == "/patient-details" ||location.pathname == "/admin/users" || location.pathname == "/admin/topic" || location.pathname == "/admin/complain") && <Header open={navBar} setOpen={setNavbar} />}
          <Box sx={workAreaStyle}>
            {props.children}
            <Outlet />
          </Box>
        </Box>
      </Box>


    </>
  )
}
export default memo(AppContainer)