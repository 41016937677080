import { useDispatch, useSelector } from "react-redux";

import CustomDialog from "../../components/layouts/common/CustomDialog";
import { closeModal } from "../../store/actions/modalAction";
import { Box, Typography } from "@mui/material";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import CustomInput from "../../components/inputs/CustomInput";
import { getUserApi } from "../../apis/user.api";
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants";
import { useState } from "react";
import { assignAudiologist } from "../../apis/patient.api";
import { callApiAction } from "../../store/actions/commonAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { useNavigate, useParams } from "react-router-dom";

const ReassignComponent = ({ id }) => {
  const dispatch = useDispatch();
  const title = "Reassigned AudioLogist ";
  const modalKey = "reassign";

  const params = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [dataNow, setDataNow] = useState({
    id: id,
    assigned: "",
    err: "",
  });

  const updateFunction = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await assignAudiologist(dataNow),
        async (response) => {
          setLoading(false);
          dispatch(closeModal(modalKey));
          dispatch(closeModal("information"));
          navigate(`/patient-details/${params.id}`);
          dispatch(
            callSnackBar(
              "Audiologist Assign Successfully",
              SNACK_BAR_VARIETNS.suceess
            )
          );
        },
        (err) => {
          setLoading(false);
          setDataNow({ ...dataNow, err });
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
        }
      )
    );
  };

  const updateClick = async (e) => {
    e.preventDefault();
    updateFunction();
  };

  const { user } = useSelector((state) => state);
  return (
    <>
      <CustomDialog
        id="reassign"
        loading={loading}
        onSubmit={updateClick}
        title={title}
        closeText="Close"
        confirmText={"Update"}
      >
        <Box>
          {user.data.role === USER_ROLES.ADMIN && (
            <AsyncDropDown
              InputComponent={(props) => (
                <CustomInput
                  label
                  placeholder="Select Audiologist"
                  {...props}
                />
              )}
              lazyFun={async (props) => {
                return await getUserApi({
                  ...props,
                  role: USER_ROLES.AUDIO_LOGIST,
                });
              }}
              OptionComponent={({ option, ...rest }) => {
                return (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                    {...rest}
                  >
                    <Typography ml={3} variant="h5">
                      {option.name}
                    </Typography>
                  </Box>
                );
              }}
              onChange={async (changedVal) => {
                setDataNow({ ...dataNow, err: "", assigned: changedVal?._id });
              }}
              titleKey={"name"}
              valueKey={"_id"}
            />
          )}
        </Box>
      </CustomDialog>
    </>
  );
};
export default ReassignComponent;
