function calculateExpCr(env_dBex, expEndKnee, tkgn, tk, cr, bolt, output_dBex) {
  let pdb = env_dBex;
  let tkTmp = tk;

  if (tkTmp + tkgn > bolt) {
    tkTmp = bolt - tkgn;
  }

  let tkgo = tkgn + tkTmp * (1.0 - 1.0 / cr);
  let pblt = cr * (bolt - tkgo);
  let crConst = 1.0 / cr - 1.0;

  let gainAtExpEndKnee = tkgn;
  if (tkTmp < expEndKnee) {
    gainAtExpEndKnee = crConst * expEndKnee + tkgo;
  }

  let gdb = output_dBex - pdb;

  let expCr;
  if (pdb < expEndKnee) {
    let expCrConst = (gainAtExpEndKnee - gdb) / (expEndKnee - pdb);
    expCr = 1.0 / (expCrConst + 1.0);
  } else {
    expCr = 1.0;
  }

  return expCr;
}

function WDRCCircuitGain(env_dBcr, expCr, expEndKnee, tkgn, tk, cr, bolt) {
  let gdb = 0.0;
  let tkgo = 0.0;
  let pblt = 0.0;
  let pdb = env_dBcr;
  let tkTmp = tk;

  if (tkTmp + tkgn > bolt) {
    tkTmp = bolt - tkgn;
  }

  tkgo = tkgn + tkTmp * (1.0 - 1.0 / cr);
  pblt = cr * (bolt - tkgo);
  let crConst = 1.0 / cr - 1.0;

  let gainAtExpEndKnee = tkgn;
  if (tkTmp < expEndKnee) {
    gainAtExpEndKnee = crConst * expEndKnee + tkgo;
  }

  let expCrConst = 1.0 / Math.max(0.01, expCr) - 1.0;
  let regime = 0;
  if (pdb < expEndKnee) {
    gdb = gainAtExpEndKnee - (expEndKnee - pdb) * expCrConst;
    regime = 0;
  } else if (pdb < tkTmp && cr >= 1.0) {
    gdb = tkgn;
    regime = 1;
  } else if (pdb > pblt) {
    gdb = bolt + (pdb - pblt) / 10.0 - pdb;
    regime = 3;
  } else {
    gdb = crConst * pdb + tkgo;
    regime = 2;
  }

  let output_dB = pdb + gdb;
  return [output_dB, regime];
}

function findBestCr(
  desiredOutput_dB,
  env_dBcr,
  expCr,
  expEndKnee,
  tkgn,
  initialTk,
  bolt
) {
  let bestCr = null;
  let bestDifference = Infinity;
  let bestOutput = null;
  let tk = initialTk;
  let tkLimit = 59;
  let bestTk = tk;

  while (tk >= tkLimit) {
    for (let cr = 0.1; cr <= 3.0; cr += 0.1) {
      let output = WDRCCircuitGain(
        env_dBcr,
        expCr,
        expEndKnee,
        tkgn,
        tk,
        cr,
        bolt
      );
      let output_dB = output[0];
      let difference = Math.abs(output_dB - desiredOutput_dB);

      if (difference < bestDifference) {
        bestDifference = difference;
        bestCr = cr;
        bestOutput = output;
        bestTk = tk;
      }

      if (difference === 0) {
        return [bestCr, bestOutput, tk];
      }
    }
    tk -= 1;
  }

  return [bestCr, bestOutput, bestTk];
}

export const ratios = (G50, G65, G80, MPO) => {
  let env_dBex = 40;
  let env_dBcr = 80;
  let expCr = 1.8;
  let expEndKnee = 55;
  let initialTk = 70;
  let tk = initialTk;
  let cr = 0.8;
  let bolt = 130;

  let gainex = G50;
  let tkgn = G65;
  let Gaincr = G80;

  console.log("G50", G50);
  console.log("G65", G65);
  console.log("G80", G80);

  let output_dBex = env_dBex + gainex;
  let desiredOutput_dB = env_dBcr + Gaincr;

  expCr = calculateExpCr(env_dBex, expEndKnee, tkgn, tk, cr, bolt, output_dBex);
  console.log(output_dBex);
  console.log(expCr);

  let [bestCr, bestOutput, finalTk] = findBestCr(
    desiredOutput_dB,
    env_dBcr,
    expCr,
    expEndKnee,
    tkgn,
    initialTk,
    bolt
  );

  console.log(
    "--------------------------------------------------------------------"
  );
  console.log(`ER: ${expCr}`);
  console.log(`Eknee: ${expEndKnee}`);
  console.log(`LG: ${tkgn}`);
  console.log(`Cknee: ${finalTk}`);
  console.log(`CR: ${bestCr}`);
  return {
    ER: expCr,
    Eknee: expEndKnee,
    LG: tkgn,
    Cknee: finalTk,
    CR: bestCr,
    MPO: MPO,
  };
};
