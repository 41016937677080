import { Box, Divider, Paper, Typography } from "@mui/material"

import { Link, useLocation, useParams } from "react-router-dom"
import { memo, useEffect, useState } from "react"
import BreadCrumbComponent from "./BreadCrumbComponent"

const ModuleContainer = ({ breadCrumbList = [], HeaderComponent = () => <></>, containerProps = {}, headrComponentProps = {}, ...props }) => {

    const location = useLocation()

    const params = useParams()
    

    return <>
    <Paper sx={{ display: "flex", flexDirection: "column",maxHeight:"100%", overflowY: "hidden", flex: 1, borderRadius:"18px",margin:"18px"}}  {...containerProps} >
        <Box p={3} pl={3} pr={3} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >

            <Box sx={{ display: "flex" }}>
            {(location.pathname == "/patient-details/" || location.pathname == "/patient-details" ) && <BreadCrumbComponent breadCrumbList={breadCrumbList} />}
            </Box>
            <Box sx={{ display: "flex", flex: 1, justifyContent:(location.pathname == "/patient-details/" || location.pathname == "/patient-details" || location.pathname == "/admin/users" ? "flex-end" : "" )}}>
                <HeaderComponent {...headrComponentProps} />
            </Box>
        </Box>
        {/* <Divider /> */}
        {props.children}    
    </Paper>
    </>
}
export default memo(ModuleContainer)