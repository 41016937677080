import { memo, useCallback, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch ,useSelector} from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import { closeModal, openModal } from "../../store/actions/modalAction";
import {
  findNameByRole,
  findObjectKeyByValue,
  titleCase,
} from "../../utils/helper";
// import { Box, CircularProgress, IconButton, Switch, Typography } from "@mui/material"
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import CreateController from "./CreateController";
// import { Delete, Edit } from "@mui/icons-material"
import MessageDilog from "../../components/MessageDilog";
import {
  ChangeCircle,
  Delete,
  Edit,
  LockClock,
  LockOpen,
  WhatsApp,
} from "@mui/icons-material";
import ModulePermissionController from "../user/ModulePermissionController";
import MODULES from "../../utils/module.constant";
import { getSecretsApi, updateSecretsField } from "../../apis/secrets.api";
import {
  getTopicApi,
  deleteTopicField,
  addTopicApi,
} from "../../apis/topic.api";
import { REGIONS_TYPE, SNACK_BAR_VARIETNS } from "../../utils/constants";
import { callSnackBar } from "../../store/actions/snackbarAction";

const ActionComponent = memo(({ params, setParams, deleteApi, modalKey }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateController
          id={params._id}
          title={params.title}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm",
        false,
        "topic"
      )
    );
  };

  const deleteFun = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };
  const onDelete = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={deleteFun}
          title="Alert!"
          message={`Are you sure to delete "${params.name || params.title}" ?`}
        />,
        "sm"
        // dispatch(callSnackBar("Topic Deleted Successfully..",SNACK_BAR_VARIETNS.suceess))
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      <IconButton disabled={loading} size="inherit" onClick={onEdit}>
        <Edit color="info" fontSize="inherit" />
      </IconButton>

      <>
        {loading && <CircularProgress color="primary" fontSize="inherit" />}
        {!loading && (
          <IconButton disabled={loading} size="inherit" onClick={onDelete}>
            <Delete color="error" fontSize="inherit" />
          </IconButton>
        )}
      </>
    </Box>
  );
});

const ModulesComponent = memo(({ params, setParams }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);

  //   const  = () => {
  //     dispatch(
  //       openModal(
  //         <ModulePermissionController
  //           id={params._id}
  //           title={params.title}
  //           {...params}
  //           callBack={(response, updatedData) => {
  //             setParams({ ...params, ...updatedData });
  //           }}
  //         />,
  //         "sm",
  //         false,
  //         "modules"
  //       )
  //     );
  //   };

  return (
    <>
      <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        {params.modules &&
          params.modules.map((item) => (
            <Chip
              size="small"
              sx={{ mr: 1 }}
              key={item}
              label={findObjectKeyByValue(item, MODULES)}
            />
          ))}
        <IconButton disabled={loading} size="inherit">
          <ChangeCircle color="info" fontSize="inherit" />
        </IconButton>
      </Box>
    </>
  );
});

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Topic ";
  // const modalKey = 'topic'
  const fetchApi = getTopicApi;
  const deleteApi = deleteTopicField;

  const columns = useMemo(
    () => [
      {
        id: 1,
        minWidth: "50px",
        fieldName: "title",
        label: "Topic",
        align: "left",
        sort: true,
      },

      {
        id: 2,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["title"],
    sort: "createdAt",
    sortDirection: -1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateController
          callBack={async () => {
            fetchList();
          }}
        />,
        "sm",
        false,
        "topic"
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;
