
import { Add, Remove } from "@mui/icons-material"
import { Box, Slider, SliderThumb, Typography, styled, useTheme } from "@mui/material"
import { memo, useEffect, useState } from "react"

// const { Box, ButtonBase, styled, Slider } = require("@mui/material")

// const CustomizeButton = styled(ButtonBase)(({ theme, disabled }) => ({
//     height: "36px",
//     width: "36px",
//     display: "flex",
//     alignItems: 'center',
//     justifyContent: "center",
//     background: disabled ? theme.palette.grey[200] : theme.palette.secondary.light,
//     border: disabled ? "1px solid transparent" : "1px solid " + theme.palette.primary.main,
//     cursor: disabled ? "not-allowed !important" : "pointer",
//     borderRadius: theme.shape.borderRadius,
//     boxShadow:theme.shadows[1]
// }))

// const CustomSlider = styled(Slider)(({ theme }) => ({
   
//     height: "35vh",
//     width: "5px",
        
//     '& .MuiSlider-track': {
//         height: 1,
//     },
//     '& .MuiSlider-rail': {
//         color: theme.palette.mode === 'dark' ? '#F5F4F4' : '#F5F4F4',
//         opacity: theme.palette.mode === 'dark' ? undefined : 1,
//         // height: 4
//     },
// }));

const CustomSlider = styled(Slider)(({ theme }) => ({
    maxHeight:"45vh",
     height: "35vh",
     minHeight:"20vh",
     minWidth:"2px",
     maxWidth:"10px",
     width: "5px",
         
     '& .MuiSlider-track': {
         height: 1,
     },
     '& .MuiSlider-rail': {
         color: theme.palette.mode === 'dark' ? '#F5F4F4' : '#F5F4F4',
         opacity: theme.palette.mode === 'dark' ? undefined : 1,
         // height: 4
     },
 }));
const CustomThumb = memo((props) => {


    const { children, style, attualVal, baseVal, ...other } = props;

    const activeDataIndex = attualVal < baseVal ? 1 : 0
    return <SliderThumb  {...other} style={{
        ...style, display: other['data-index'] == activeDataIndex ? "none" : "block", ":before": {
            display: "none"       

        }
    }} >
        {children}
    </SliderThumb>
})


const BothSideSlider = ({ value, baseVal = 0, onChange, min = 0, max = 10, step = 1, disabled }) => {

    const calculateVal = (arr) => {

        if (arr[0] < baseVal) {
            return arr[0]
        }
        if (arr[1] > baseVal) {
            return arr[1]
        }
        return baseVal

    }

    const [val, setVal] = useState(value < baseVal ? [value, baseVal] : [baseVal, value])


    useEffect(() => {
        setVal(value < baseVal ? [value, baseVal] : [baseVal, value])
    }, [value])


    return <Box >

        <Box  flexDirection="row" >
            <CustomSlider
            
               orientation="vertical"
                disabled={disabled}
                slots={{
                    thumb: (props) => {
                        return <CustomThumb {...props} valueArr={val} baseVal={baseVal} attualVal={calculateVal(val)} />
                    }
                }}
                max={max}
                onChange={(e, newVal) => {
                    const temp = [...newVal]

                    if (newVal[0] > baseVal) {
                        temp[0] = baseVal
                        temp[1] = newVal[0]
                    } else if (newVal[0] < baseVal && temp[1] > baseVal) {
                        temp[1] = baseVal

                    }

                    if (newVal[1] < baseVal) {
                        temp[1] = baseVal
                        temp[0] = newVal[1]
                    } else if (newVal[1] > baseVal && temp[0] < baseVal) {
                        temp[0] = baseVal

                    }

                    onChange?.(calculateVal(temp))
                    setVal(temp)
                }}
                min={min}
                value={val}

            />
        

        </Box>
    </Box>


}
export default memo(BothSideSlider)