import { memo } from "react"

import CreateController from "./CreateController"
import { useNavigate, useParams } from "react-router-dom"

const UpdatePatientDetails = ({  value}) => {
  const params = useParams()
const navigate = useNavigate()
    return <CreateController id={params.id} value={value} callBack={()=>{
        navigate("/patient-details/"+params.id)
    }} />
}
export default memo(UpdatePatientDetails)