import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"
import SubmitButton from "../../button/SubmitButton"
import { useDispatch } from "react-redux"
import { closeModal } from "../../../store/actions/modalAction"
import { Close, DriveFileRenameOutlineOutlined } from "@mui/icons-material"

const CustomDialog = ({ loading, id, title, err, children, disabledSubmit, onSubmit, onClose = () => { },onEdit, disableDirectClose = false, confirmText = "Submit", closeText = "Close", dialogProps = {},hideSubmit }) => {
    const dispatch = useDispatch()

    const handleClose = () => {
        onClose()
        if (!disableDirectClose)
            dispatch(closeModal(id))

    }

    return <Box component="form" sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }} maxHeight="100%" onSubmit={onSubmit ?? handleClose} autoComplete="off" >
        <DialogTitle variant="h4" sx={{borderBottom:1,borderColor:"divider"}}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                <Box component={'div'}>
                    {title}
                   {onEdit && <IconButton onClick={onEdit} sx={{ padding: 0, ml: 2 }} >
                        <DriveFileRenameOutlineOutlined fontSize="inherit" color="primary" />
                    </IconButton>}
                    <Typography variant="body2" color={'red'}>
                        {err}
                    </Typography>
                </Box>
                {!disableDirectClose && <IconButton onClick={handleClose} size="small">
                    <Close />
                </IconButton>}
            </Box>
        </DialogTitle>
        <DialogContent  {...dialogProps}>
            {children}
        </DialogContent>
        {onSubmit && <DialogActions sx={{borderTop:1,borderColor:"divider"}} >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }} >
            {<SubmitButton sx={{minWidth:"150px",textDecoration:"underline"}} variant='text' onClick={handleClose} title={closeText} />}
            {!hideSubmit &&<SubmitButton sx={{minWidth:"150px"}}  loading={loading} disabled={disabledSubmit || loading} type="submit" title={confirmText} />}
            </Box>
         
        </DialogActions>}
    </Box>
}
export default CustomDialog