import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import CreateUi from "./CreateUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import {
  addComplainApi,
  getComplainByIdApi,
  updateComplainField,
  getComplain,
} from "../../apis/complain.api";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants";
import { validateInternationPhone, validatePhone } from "../../utils/helper";
import { callSnackBar } from "../../store/actions/snackbarAction";

const CreateController = ({
  callBack = () => {},
  id,
  role = null,
  isAddMore,
}) => {
  const validate = useValidate();
  const dispatch = useDispatch();
  const params = useParams();
  const modalKey = "complain";
  let title = !isAddMore ? "Complain" : "Add More Solution";
  const createApi = addComplainApi;
  const updateApi = updateComplainField;
  const getByIdApi = getComplain;

  const [loading, setLoading] = useState(false);
  const isRolePredefined = role !== null;
  const [visible, setVisible] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    id,
    topic_id: "",
    question: "",
    complaint_solution: [
      {
        solution: "",
        actions: [{ arr_no: [], increase_val: "" }],
      },
    ],
  });
  const [originalDocument, setOriginalDocument] = useState({});

  const validationSchemaForCreate = useMemo(
    () => [
      {
        required: true,
        value: fields.topic_id,
        field: "Topic",
      },
      {
        required: true,
        value: fields.question,
        field: "Question",
      },
      {
        required: true,
        value: fields.complaint_solution,
        field: "Solution",
      },
    ],
    [fields]
  );

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.topic_id,
        field: "Topic",
      },
      {
        required: true,
        value: fields.question,
        field: "Question",
      },
      {
        required: true,
        value: fields.complaint_solution,
        field: "Solution",
      },
    ],
    [fields]
  );

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate);

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createApi(fields),
          async (response) => {
            await callBack(response);
            setLoading(false);
            dispatch(
              callSnackBar(
                "Complain Created Successfully..",
                SNACK_BAR_VARIETNS.suceess
              )
            );
            dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const updateFunction = async () => {
    const validationResponse = validate(validationSchemaForUpdate);
    let updatedData = { id };

    // for (let field in fields) {
    //   if (
    //     originalDocument[field] &&
    //     fields[field] &&
    //     fields[field] != originalDocument[field]
    //   ) {
    //     updatedData[field] = fields[field];
    //   }

    //   if (!originalDocument[field]) {
    //     updatedData[field] = fields[field];
    //   }
    // }

    if (isAddMore) {
      updatedData["complaint_solution"] = [
        ...originalDocument.complaint_solution,
        ...fields.complaint_solution,
      ];
    } else {
      updatedData = fields;
    }
    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await updateApi(updatedData),
          async (response) => {
            await callBack(response, updatedData);
            setLoading(false);
            dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    console.log("this is original data", originalDocument);
    console.log("this is uodatedd data", fields);
    e.preventDefault();
    if (id) updateFunction();
    else createFunction();
  };

  // const onSubmit = async (action) => {
  // if (action === 'close') {
  //   // Handle close action, e.g., dispatch an action to close the modal
  //   dispatch(closeModal(modalKey));
  // } else {
  //   // Handle the submit action as before
  //   e.preventDefault();
  //   if (id)
  //     updateFunction();
  //   else
  //     createFunction();
  // }
  // };

  const fetchById = (id) => {
    setLoading(true);
    console.log("call this API");
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          if (isAddMore) {
            setFields({
              ...fields,
              topic_id: response.topic_id,
              question: response.question,
            });
          } else {
            setFields({ ...fields, ...response });
          }
          setOriginalDocument(response);
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);

  return (
    <CreateUi
      modalKey={modalKey}
      title={title}
      isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
      setVisible={setVisible}
      visible={visible}
      isAddMore={isAddMore}
    />
  );
};

export default memo(CreateController);
