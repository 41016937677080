import { Box, Divider, Paper, Typography, useTheme } from "@mui/material"

import { Link, useLocation, useParams } from "react-router-dom"
import { memo, useEffect, useState } from "react"

const BreadCrumbComponent = ({ breadCrumbList = [], ...props }) => {

    const location = useLocation()

    const params = useParams()


    const [breadCrumb, setBreadCrumb] = useState(breadCrumbList)


    const createBreadCrumb = () => {
        if (breadCrumbList.length == 0) {
            const path = location.pathname.split('/')

            const breadcrumTempArr = []

            let redirect = '/'

            path.forEach((item, index) => {
                if (item && item != '') {

                    redirect += (item + "/")

                    if (item != params.fitid
                        // && item != params.step
                    )
                        breadcrumTempArr.push({
                            title: item.replace(/-/g, ' '),
                            url: index == path.length - 1 ? null : redirect
                        })
                }

            })

            setBreadCrumb(breadcrumTempArr)
        }
    }
    useEffect(() => {
        createBreadCrumb()
    }, [])

    return <Box sx={{ display: "flex", marginLeft:"24px", padding:"16px" }} {...props}>
        {breadCrumb?.map((item, index) => <Link to={item.url}  >
            <Typography textTransform="capitalize" variant="h3" fontWeight={500} color="primary" >
                <span dangerouslySetInnerHTML={{ __html: `${decodeURI(item.title)} ${(index == breadCrumb.length - 1) ? " " : " > "}&nbsp;` }}></span>

            </Typography>
        </Link>)}
    </Box>
}
export const BreadCrumbAsTitle = ({ breadCrumbList = [], ...props }) => {
const theme = useTheme()
    return <Box p={3} px={7} sx={{background:theme.palette.secondary.main}} >
        <BreadCrumbComponent breadCrumbList={breadCrumbList}{...props} />
    </Box>
}
export default memo(BreadCrumbComponent)