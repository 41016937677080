import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";

export const getTopicApi = async () => {
  const callResponse = await axios({
    url: endpoints.fetchTopic,
    method: "get",
    headers: getHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addTopicApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.createTopic,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const deleteTopicField = async (data) => {
  const callResponse = await axios({
    url: endpoints.deleteTopic,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateTopicField = async (data) => {
  const callResponse = await axios({
    url: endpoints.updateTopic,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getTopicByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchTopicById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
