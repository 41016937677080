export const calculateIGI = (dbVal) => {
    let dB = Number(dbVal);
    if (dB < 20) return 0;
    if (dB >= 20 && dB < 60) return Math.round(0.6 * (dB - 20) * 0.8);
  
    // if (dB > 80) {
    //     dB = 80
    // }
  
    return Math.round(0.8 * (dB - 23) * 0.8);
  };
  
  export function WDRC_circuit_gain(
    env_dB,
    exp_cr,
    exp_end_knee,
    tkgn,
    tk,
    cr,
    bolt
  ) {
    var gdb = 0.0;
    var tkgo = 0.0;
    var pblt = 0.0;
    var k = 0.0;
    var pdb = env_dB; //just rename it to keep the code below unchanged (input SPL dB)
    var tk_tmp = tk; //temporary, threshold for start of compression (input SPL dB)
  
    if (tk_tmp + tkgn > bolt) {
      //after gain, would the compression threshold be above the output-limitting threshold ("bolt")
      tk_tmp = bolt - tkgn; //if so, lower the compression threshold to be the pre-gain value resulting in "bolt"
    }
  
    tkgo = tkgn + tk_tmp * (1.0 - 1.0 / cr); //intermediate calc
    pblt = cr * (bolt - tkgo); //calc input level (dB) where we need to start limiting, not just compression
    var cr_const = 1.0 / cr - 1.0; //pre-calc a constant that we'll need later
  
    //compute gain at transition between expansion and linear/compression regions
    var gain_at_exp_end_knee = tkgn;
    if (tk_tmp < exp_end_knee) {
      gain_at_exp_end_knee = cr_const * exp_end_knee + tkgo;
    }
  
    var exp_cr_const = 1.0 / Math.max(0.01, exp_cr) - 1.0;
    var regime = 0;
    if (pdb < exp_end_knee) {
      //if below the expansion threshold, do expansion expansion region.
      gdb = gain_at_exp_end_knee - (exp_end_knee - pdb) * exp_cr_const; //reduce gain the farther down you are from the end of the expansion region
      regime = 0;
    } else if (pdb < tk_tmp && cr >= 1.0) {
      //if below the compression threshold, go linear
      gdb = tkgn; //we're in the linear region.  Apply linear gain.
      regime = 1;
    } else if (pdb > pblt) {
      //we're beyond the compression region into the limitting region
      gdb = bolt + (pdb - pblt) / 10.0 - pdb; //10:1 limiting!
      regime = 3;
    } else {
      gdb = cr_const * pdb + tkgo; //compression
      regime = 2;
    }
  
    var output_dB = pdb + gdb;
    var output = [output_dB, regime];
    return output;
  }
  
  export const GAIN_POINTS = {
    MASTER_GAIN: 94,
    NOISE_REDUCTION: 95,
    LOUDNESS_COMPRESSION: 96,
    SELF_SPEECH_REDUCTION: 97,
    SHARPNESS: 98,
    WIND_NOISEREDUCTION: 99,
  };
  