import { Box, Button, FormControl, Grid, MenuItem, Select, Typography, styled, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import BTEDevice from "../../assets/images/bte.png";
import { useSelector } from "react-redux";
import {
  DEVICES,
  DEVICES_NAME,
  LISTENING_SIDE,
} from "../../utils/patient.constants";
import { WDRC_circuit_gain } from "../../utils/j10.helper";
import { findMediun, findMediunsInBulk } from "../../utils/helper";
import RightEar from "../../assets/images/Left (1).png";
import LeftEar from "../../assets/images/Left (2).png";

const BorderSquareChip = styled(Typography)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: "1px solid " + theme.palette.success.main,
  color: theme.palette.success.main,
  padding: theme.spacing(2),
  background: theme.palette.success.light,
}));

const createData = (envDb, fittingData, deviceType, edgeVal) => {
  const defaultDSummyValueSet = [0.6, 48, edgeVal, 70, 2, 100];

  const data = [];

  const round =
    deviceType == DEVICES.BTE_32 || deviceType == DEVICES.BTE_24
      ? 2
      : deviceType == DEVICES.BTE_16
        ? 1
        : 0;

  // for (let i = 0; i < 8; i++) {

  //     const startVal = 20

  //     const val = WDRC_circuit_gain(
  //         envDb,
  //         fittingData[startVal + 0 + i],
  //         fittingData[startVal + 8 + i],
  //         fittingData[startVal + 16 + i],
  //         fittingData[startVal + 24 + i],
  //         fittingData[startVal + 32 + i],
  //         fittingData[startVal + 40 + i],
  //     )

  //     data.push(val[0] - envDb)
  // }

  const startVal = 20;

  const temp = [[], [], [], [], [], []];
  for (let i = 0; i < 8; i++) {
    temp[0].push(fittingData[startVal + 0 + i]);
    temp[1].push(fittingData[startVal + 8 + i]);
    temp[2].push(fittingData[startVal + 16 + i]);
    temp[3].push(fittingData[startVal + 24 + i]);
    temp[4].push(fittingData[startVal + 32 + i]);
    temp[5].push(fittingData[startVal + 40 + i]);
  }

  const finalValArray = [
    findMediunsInBulk(temp[0], defaultDSummyValueSet[0], round),
    findMediunsInBulk(temp[1], defaultDSummyValueSet[1], round),
    findMediunsInBulk(temp[2], defaultDSummyValueSet[2], round),
    findMediunsInBulk(temp[3], defaultDSummyValueSet[3], round),
    findMediunsInBulk(temp[4], defaultDSummyValueSet[4], round),
    findMediunsInBulk(temp[5], defaultDSummyValueSet[5], round),
  ];
  if (deviceType == DEVICES.BTE_24) {
    for (let i = 0; i < finalValArray.length; i++) {
      // for (let subArr of finalValArray[i]) {

      const temp = [...finalValArray[i]];

      const newArray = temp.filter((item, index) => (index + 1) % 4 != 0);
      finalValArray[i] = newArray;

      // }
    }
  }

  for (let i = 0; i < finalValArray[0].length; i++) {
    const val = WDRC_circuit_gain(
      envDb,
      finalValArray[0][i],
      finalValArray[1][i],
      finalValArray[2][i],
      finalValArray[3][i],
      finalValArray[4][i],
      finalValArray[5][i]
    );

    data.push(val[0] - envDb);
  }

  return data;
};

const ChartComponent = ({ edgeVal }) => {
  const { fitting } = useSelector((state) => state);

  const labels = useMemo(() => {
    if (fitting.device_type == DEVICES.BTE_16)
      return [
        "125",
        "221",
        "317",
        "410",
        "502",
        "650",
        "797",
        "1031",
        "1264",
        "1635",
        "2005",
        "2593",
        "3181",
        "4113",
        "5044",
        // "8000",
      ];
    else if (fitting.device_type == DEVICES.BTE_24)
      return [
        "125",
        "189",
        "253",
        "317",
        "379",
        "441",
        "502",
        "601",
        "700",
        "797",
        "953",
        "1109",
        "1264",
        "1511",
        "1758",
        "2005",
        "2397",
        "2789",
        "3181",
        "3802",
        "4423",
        "5044",
        "6522",
        "8000",
      ];
    else if (fitting.device_type == DEVICES.BTE_32)
      return [
        "125",
        "173",
        "221",
        "269",
        "317",
        "364",
        "410",
        "456",

        "502",
        "576",
        "650",
        "723",
        "797",
        "914",
        "1031",
        "1148",

        "1264",
        "1450",
        "1635",
        "1820",
        "2005",
        "3000",
        "2593",
        "2860",

        "3181",
        "3647",
        "4113",
        "4578",
        "5044",
        "6522",
        "7015",
        "8000",
      ];
    else return ["-10", "250", "500", "1k", "2k", "4k", "6k", "8k"];
  }, [fitting.device_type]);

  const dataFields = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: "40dB",
          data: createData(40, fitting.data, fitting.device_type, edgeVal),

          borderColor: "#2D3B67",
        },
        {
          label: "65dB",
          data: createData(65, fitting.data, fitting.device_type, edgeVal),

          borderColor: "#969696",
        },
        {
          label: "95dB",
          data: createData(95, fitting.data, fitting.device_type, edgeVal),
          // fill: "start",
          borderColor: "#CCCCCC",
          // pointBackgroundColor: "white",
          // backgroundColor: (context) => {
          //     // const ctx = context.chart.ctx;
          //     // const gradient = ctx.createLinearGradient(50, 0, 0, 200);
          //     // gradient.addColorStop(0, "rgba(250,174,50,0.1)");
          //     // gradient.addColorStop(1, "rgba(250,174,50,0.1)");
          //     // return gradient;
          // },
        },
      ],
    }),
    [fitting.data, fitting.device_type, edgeVal]
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 10,
            color: "#666666", // Font size for x-axis labels
          },
          // Padding to move the labels outside the box
        },
      },
      y: {
        min: -10,
        max: 120,
        reverse: false,
        ticks: {
          callback: function (value) {
            return value;
          },
          stepSize: 10,
          autoSkip: false,
          font: {
            size: 8,
          },
        },
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
    elements: {
      line: {
        borderWidth: 1,
        tension: 0.4,
      },
    },
  };

  return (
    <Box sx={{ minHeight: "35vh",height:"45vh", minWidth:"30vw",width:"38vw"}}>
      <Line options={options} data={dataFields} />
    </Box>
  );
};

// const ChartComponent = ({ edgeVal }) => {
//   const { fitting } = useSelector((state) => state);

//   const labels = useMemo(() => {
//     if (fitting.device_type == DEVICES.BTE_16)
//       return [
//         "125",
//         "221",
//         "317",
//         "410",
//         "502",
//         "650",
//         "797",
//         "1031",
//         "1264",
//         "1635",
//         "2005",
//         "2593",
//         "3181",
//         "4113",
//         "5044",
//         // "8000",
//       ];
//     else if (fitting.device_type == DEVICES.BTE_24)
//       return [
//         "125",
//         "189",
//         "253",
//         "317",
//         "379",
//         "441",
//         "502",
//         "601",
//         "700",
//         "797",
//         "953",
//         "1109",
//         "1264",
//         "1511",
//         "1758",
//         "2005",
//         "2397",
//         "2789",
//         "3181",
//         "3802",
//         "4423",
//         "5044",
//         "6522",
//         "8000",
//       ];
//     else if (fitting.device_type == DEVICES.BTE_32)
//       return [
//         "125",
//         "173",
//         "221",
//         "269",
//         "317",
//         "364",
//         "410",
//         "456",

//         "502",
//         "576",
//         "650",
//         "723",
//         "797",
//         "914",
//         "1031",
//         "1148",

//         "1264",
//         "1450",
//         "1635",
//         "1820",
//         "2005",
//         "3000",
//         "2593",
//         "2860",

//         "3181",
//         "3647",
//         "4113",
//         "4578",
//         "5044",
//         "6522",
//         "7015",
//         "8000",
//       ];
//     else return ["-10", "250", "500", "1k", "2k", "4k", "6k", "8k"];
//   }, [fitting.device_type]);

//   const dataFields = useMemo(
//     () => ({
//       labels,
//       datasets: [
//         {
//           label: "40dB",
//           data: createData(40, fitting.data, fitting.device_type, edgeVal),

//           borderColor: "blue",
//         },
//         {
//           label: "65dB",
//           data: createData(65, fitting.data, fitting.device_type, edgeVal),

//           borderColor: "red",
//         },
//         {
//           label: "95dB",
//           data: createData(95, fitting.data, fitting.device_type, edgeVal),
//           // fill: "start",
//           borderColor: "green",
//           // pointBackgroundColor: "white",
//           // backgroundColor: (context) => {
//           //     // const ctx = context.chart.ctx;
//           //     // const gradient = ctx.createLinearGradient(50, 0, 0, 200);
//           //     // gradient.addColorStop(0, "rgba(250,174,50,0.1)");
//           //     // gradient.addColorStop(1, "rgba(250,174,50,0.1)");
//           //     // return gradient;
//           // },
//         },
//       ],
//     }),
//     [fitting.data, fitting.device_type, edgeVal]
//   );

//   return (
//     <Line
//       maxWidth="100%"
//       maxHeight="100%"
//       options={{
//         interaction: {
//           mode: "index",
//         },
//         plugins: {
//           datalabels: {
//             display: false,
//             backgroundColor: "red",
//           },

//           legend: {
//             enabled: false,
//             color: "red",
//             position: "bottom",
//             labels: {
//               // color:"red"
//             },
//           },
//         },

//         responsive: true,
//         animation: false,

//         bezierCurve: true,
//         elements: {
//           // line: {
//           //     borderWidth: 1,
//           //     tension: 0.5,
//           //     // borderColor: "#00F",
//           //     hoverBorderColor: title == "Right Ear" ? "#E62323" : "#3C508B",
//           //     borderColor: title == "Right Ear" ? "#E62323" : "#3C508B",
//           // },
//           // point: {
//           //     pointStyle: title == "Right Ear" ? "circle" : "crossRot",
//           //     hoverBorderColor: title == "Right Ear" ? "#E62323" : "#3C508B",
//           //     borderColor: title == "Right Ear" ? "#E62323" : "#3C508B",
//           //     radius: title == "Right Ear" ? 6 : 10,
//           //     backgroundColor: "white",
//           //     hoverBackgroundColor: "white",
//           //     hoverRadius: title == "Right Ear" ? 8 : 12,
//           //     borderWidth: title == "Right Ear" ? 1 : 3,
//           //     hoverBorderWidth: title == "Right Ear" ? 1 : 3,
//           // },
//         },
//         spanGaps: 1,
//         aspectRatio: 1.5,
//         scales: {
//           y: {
//             title: {
//               display: true,
//               text: "Gain",
//               fontWeight: "600",
//               color: "#3C508B",
//               heigth:"50vh"
//             },
//             max: 120,
//             min: -10,
//             suggestedMax: 0,
//             suggestedMin: 80,
//             // ticks: {
//             //     callback: function (value, index, ticks) {

//             //         return -1 * (value)
//             //     }
//             // }
//           },
//           x: {
//             title: {
//               display: true,
//               text: "Frequency",
//               fontWeight: "6001",
//               color: "#3C508B",
//             },
//           },
//         },

//         onClick: (e, ele, cha1rt) => {},
//         onHover: (e, ele, chart) => {},
//       }}
//       data={dataFields}
//     />
//   );
// };

const FittingChartArea = ({ edgeVal }) => {

  // const { fitting } = useSelector(state => state)
  // console.log("object",fitting);
  return (
    <Box
      sx={{
        // height: "50vh",
        display: "flex",
        flexDirection: "column",
        // height: "100vh",
        // overflow: "hidden",
      }}
    >
      <Grid container>

        <Grid md={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Grid display={"flex"} flexDirection={"row"}  >
            <Box>
              <img src={LeftEar} style={{ height: "4vh" }} />
            </Box>
            <Box mt={1}>
              <Typography sx={{ color: "#1A1A1A", fontFamily: "League spartan", fontSize: '16px', fontWeight: "bold", fontStyle: "normal" }}>BTE OPTIMA</Typography>
            </Box>
            <Box ml={1} display={"flex"}>
              <Typography sx={{ color: "#999", fontFamily: "League spartan", fontSize: '20px', fontWeight: "bold", fontStyle: "normal" }}>-</Typography>
            </Box>
            <Box ml={1} mt={1} display={"flex"}>
              <Typography sx={{ color: "#39AC8A", fontFamily: "League spartan", fontSize: '16px', fontWeight: "400", fontStyle: "normal" }}>• Connected</Typography>
            </Box>
            <Box mt={1} ml={2}>
              <Typography sx={{ color: "#2D3B67", fontFamily: "League Spartan", fontSize: '16px', fontWeight: "400", fontStyle: "normal" }}>02567(Serial Number)</Typography>
            </Box>
            <Box >
        <Button><svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
<path d="M13.1652 3.83345C14.1101 5.14694 14.6666 6.75854 14.6666 8.50011C14.6666 10.2417 14.1101 11.8533 13.1652 13.1668M10.4969 5.83345C11.0241 6.58934 11.3333 7.50862 11.3333 8.50011C11.3333 9.49161 11.0241 10.4109 10.4969 11.1668M6.42286 4.07724L4.3124 6.18769C4.1971 6.303 4.13945 6.36065 4.07217 6.40187C4.01252 6.43843 3.94749 6.46536 3.87947 6.48169C3.80274 6.50011 3.72121 6.50011 3.55815 6.50011H2.39998C2.02661 6.50011 1.83993 6.50011 1.69732 6.57278C1.57188 6.63669 1.46989 6.73868 1.40598 6.86412C1.33331 7.00673 1.33331 7.19341 1.33331 7.56678V9.43345C1.33331 9.80682 1.33331 9.9935 1.40598 10.1361C1.46989 10.2615 1.57188 10.3635 1.69732 10.4275C1.83993 10.5001 2.02661 10.5001 2.39998 10.5001H3.55815C3.72121 10.5001 3.80274 10.5001 3.87947 10.5185C3.94749 10.5349 4.01252 10.5618 4.07217 10.5984C4.13945 10.6396 4.1971 10.6972 4.3124 10.8125L6.42286 12.923C6.70844 13.2086 6.85123 13.3514 6.97383 13.361C7.0802 13.3694 7.18415 13.3263 7.25345 13.2452C7.33331 13.1517 7.33331 12.9497 7.33331 12.5459V4.45436C7.33331 4.05048 7.33331 3.84855 7.25345 3.75504C7.18415 3.6739 7.0802 3.63084 6.97383 3.63921C6.85123 3.64886 6.70844 3.79165 6.42286 4.07724Z" stroke="#2D3B67" stroke-linecap="round" stroke-linejoin="round"/>
</svg></Button>
            </Box>
          </Grid>
        </Grid>
        <Grid md={12} ml={4} mt={1} sx={{ display: "flex", justifyContent: "flex-start" }}>

          <ChartComponent edgeVal={edgeVal}></ChartComponent>
        </Grid>
      </Grid>


    </Box>
  );
};

export default FittingChartArea;  