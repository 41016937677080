const Softtarget_spl = {
  0: [46, 49, 45, 43, 43, 46, 47, 45, 38],
  5: [49, 52, 48, 46, 47, 50, 51, 49, 41],
  10: [53, 55, 52, 50, 50, 54, 53, 50, 45],
  15: [56, 58, 55, 53, 54, 57, 58, 56, 49],
  20: [59, 62, 58, 56, 57, 60, 62, 60, 53],
  25: [63, 65, 62, 60, 61, 64, 65, 64, 57],
  30: [65, 66, 63, 62, 63, 66, 67, 65, 60],
  35: [67, 68, 65, 63, 65, 68, 69, 68, 62],
  40: [70, 70, 67, 66, 67, 70, 72, 70, 65],
  45: [73, 73, 69, 68, 70, 73, 74, 73, 69],
  50: [77, 75, 72, 71, 71, 76, 77, 75, 72],
  55: [81, 79, 76, 75, 76, 79, 79, 78, 76],
  60: [84, 82, 79, 79, 79, 82, 83, 81, 79],
  65: [86, 84, 81, 81, 81, 84, 85, 84, 82],
  70: [89, 86, 83, 83, 84, 87, 88, 87, 85],
  75: [92, 89, 86, 86, 87, 90, 91, 89, 87],
  80: [94, 92, 89, 89, 90, 93, 94, 92, 90],
  85: [98, 94, 92, 92, 93, 95, 96, 95, 94],
  90: [99, 97, 95, 95, 96, 98, 99, 97, 97],
  95: [103, 101, 98, 98, 99, 102, 103, 100, 97],
  100: [107, 105, 102, 102, 103, 106, 106, 103, 101],
  105: [111, 106, 106, 106, 107, 110, 110, 108, 105],
  110: [115, 110, 110, 110, 110, 113, 111, 111, 111],
};
const Mildtarget_spl = {
  0: [56, 59, 55, 53, 53, 56, 57, 55, 48],
  5: [59, 62, 58, 56, 56, 59, 60, 58, 51],
  10: [61, 64, 61, 59, 59, 62, 64, 62, 54],
  15: [64, 67, 64, 62, 62, 66, 67, 65, 58],
  20: [67, 70, 67, 65, 65, 69, 70, 68, 61],
  25: [70, 73, 69, 68, 69, 72, 73, 71, 65],
  30: [71, 74, 71, 69, 70, 73, 75, 73, 67],
  35: [73, 75, 72, 71, 72, 75, 76, 75, 69],
  40: [76, 77, 74, 72, 74, 77, 78, 77, 72],
  45: [78, 79, 76, 75, 75, 79, 81, 79, 75],
  50: [81, 81, 78, 77, 77, 82, 83, 82, 78],
  55: [85, 85, 82, 81, 81, 84, 86, 85, 81],
  60: [89, 88, 85, 84, 85, 88, 90, 89, 85],
  65: [93, 91, 88, 87, 89, 92, 94, 93, 89],
  70: [97, 95, 92, 91, 93, 96, 97, 96, 94],
  75: [101, 99, 96, 95, 97, 100, 101, 100, 98],
  80: [104, 102, 99, 99, 100, 103, 103, 102, 101],
  85: [108, 104, 102, 102, 103, 106, 106, 105, 104],
  90: [109, 107, 105, 105, 106, 108, 110, 109, 107],
  95: [113, 111, 108, 108, 109, 112, 114, 113, 112],
  100: [117, 115, 112, 112, 113, 116, 117, 116, 116],
  105: [121, 116, 116, 116, 117, 119, 119, 118, 118],
  110: [125, 120, 120, 120, 120, 121, 123, 121, 121],
};
const Hightarget_spl = {
  0: [46, 49, 45, 43, 43, 46, 47, 45, 38],
  5: [49, 52, 48, 46, 47, 50, 51, 49, 41],
  10: [53, 55, 52, 50, 50, 54, 53, 50, 45],
  15: [56, 58, 55, 53, 54, 57, 58, 56, 49],
  20: [59, 62, 58, 56, 57, 60, 62, 60, 53],
  25: [63, 65, 62, 60, 61, 64, 65, 64, 57],
  30: [65, 66, 63, 62, 63, 66, 67, 65, 60],
  35: [67, 68, 65, 63, 65, 68, 69, 68, 62],
  40: [70, 70, 67, 66, 67, 70, 72, 70, 65],
  45: [73, 73, 69, 68, 70, 73, 74, 73, 69],
  50: [77, 75, 72, 71, 71, 76, 77, 75, 72],
  55: [81, 79, 76, 75, 76, 79, 79, 78, 76],
  60: [84, 82, 79, 79, 79, 82, 83, 81, 79],
  65: [86, 84, 81, 81, 81, 84, 85, 84, 82],
  70: [89, 86, 83, 83, 84, 87, 88, 87, 85],
  75: [92, 89, 86, 86, 87, 90, 91, 89, 87],
  80: [94, 92, 89, 89, 90, 93, 94, 92, 90],
  85: [98, 94, 92, 92, 93, 95, 96, 95, 94],
  90: [99, 97, 95, 95, 96, 98, 99, 97, 97],
  95: [103, 101, 98, 98, 99, 102, 103, 100, 97],
  100: [107, 105, 102, 102, 103, 106, 106, 103, 101],
  105: [111, 106, 106, 106, 107, 110, 110, 108, 105],
  110: [115, 110, 110, 110, 110, 113, 111, 111, 111],
};

const OSPLtarget_spl = {
  0: [90, 90, 90, 90, 90, 90, 90, 90, 90],
  5: [90, 91, 91, 91, 92, 92, 92, 92, 91],
  10: [91, 92, 93, 93, 93, 94, 94, 94, 93],
  15: [92, 94, 94, 94, 96, 96, 96, 96, 94],
  20: [93, 95, 95, 96, 96, 97, 98, 96, 95],
  25: [94, 97, 97, 98, 98, 99, 99, 98, 97],
  30: [95, 98, 98, 99, 99, 100, 100, 100, 98],
  35: [96, 99, 99, 100, 100, 101, 101, 101, 100],
  40: [98, 100, 100, 102, 102, 104, 104, 103, 101],
  45: [100, 102, 102, 103, 103, 104, 106, 104, 103],
  50: [102, 103, 103, 105, 105, 107, 107, 106, 105],
  55: [104, 105, 105, 107, 107, 109, 110, 108, 107],
  60: [106, 107, 108, 109, 109, 111, 112, 110, 109],
  65: [109, 109, 110, 111, 111, 113, 114, 112, 109],
  70: [111, 112, 112, 113, 114, 115, 117, 115, 113],
  75: [114, 114, 114, 115, 116, 118, 119, 116, 116],
  80: [117, 117, 117, 118, 118, 120, 122, 119, 118],
  85: [120, 119, 119, 120, 121, 123, 124, 122, 121],
  90: [124, 122, 122, 123, 125, 126, 126, 124, 124],
  95: [127, 125, 125, 125, 127, 129, 129, 127, 126],
  100: [131, 127, 127, 128, 128, 129, 131, 129, 129],
  105: [135, 130, 130, 131, 132, 132, 134, 133, 133],
  110: [139, 133, 133, 133, 133, 134, 136, 135, 135],
};

const Softinput_spl = {
  250: 45,
  500: 47,
  750: 42,
  1000: 40,
  1500: 38,
  2000: 34,
  3000: 32,
  4000: 31,
  6000: 30,
};

const Mildinput_spl = {
  250: 55,
  500: 57,
  750: 52,
  1000: 50,
  1500: 48,
  2000: 44,
  3000: 42,
  4000: 41,
  6000: 40,
};

const Highinput_spl = {
  250: 57,
  500: 65,
  750: 66,
  1000: 64,
  1500: 65,
  2000: 61,
  3000: 57,
  4000: 56,
  6000: 50,
};

// microphone effects
const microphone_effects = {
  250: 1,
  500: 1,
  750: 1,
  1000: 1,
  1500: 2,
  2000: 3,
  3000: 4,
  4000: 2,
  6000: 1,
};

// age parameters
const age_Less3 = {
  250: 5,
  500: 9,
  750: 10,
  1000: 12,
  1500: 13,
  2000: 13,
  3000: 11,
  4000: 11,
  6000: 13,
};

const age_more3 = {
  250: 3,
  500: 7,
  750: 9,
  1000: 10,
  1500: 12,
  2000: 11,
  3000: 9,
  4000: 9,
  6000: 9,
};

const age_between = {
  250: 3,
  500: 7,
  750: 8,
  1000: 10,
  1500: 11,
  2000: 11,
  3000: 8,
  4000: 8,
  6000: 8,
};

const age_6 = {
  250: 3,
  500: 6,
  750: 8,
  1000: 9,
  1500: 11,
  2000: 11,
  3000: 7,
  4000: 7,
  6000: 8,
};

const age_more6 = {
  250: 3,
  500: 5,
  750: 5,
  1000: 6,
  1500: 8,
  2000: 6,
  3000: 2,
  4000: 3,
  6000: 8,
};

// input parameters
// const threshold_hl = 50;
// const frequency = 500;
// const age = 50;

export function dslv5(threshold_hl, frequency, age) {
  let age_parameter;

  if (age <= 3) {
    age_parameter = age_Less3;
  } else if (age >= 4 && age <= 5) {
    age_parameter = age_between;
  } else if (age === 6) {
    age_parameter = age_6;
  } else if (age > 6) {
    age_parameter = age_more6;
  } else {
    age_parameter = age_more3;
  }

  function calculate_value(target_spl, input_spl) {
    return (
      target_spl -
      input_spl -
      age_parameter[frequency] -
      microphone_effects[frequency]
    );
  }

  let soft_calculated_value;
  const frequencies = [250, 500, 750, 1000, 1500, 2000, 3000, 4000, 6000];

  if (frequency in Softinput_spl) {
    const softTarget =
      Softtarget_spl[threshold_hl][frequencies.indexOf(frequency)];
    const softInput = Softinput_spl[frequency];
    soft_calculated_value = calculate_value(softTarget, softInput);
    console.log(`Low target @ ${frequency} Hz: ${soft_calculated_value}`);
  } else {
    console.log("Frequency not found in the input SPL dictionary.");
  }

  let mild_calculated_value;

  if (frequency in Mildinput_spl) {
    const mildTarget =
      Mildtarget_spl[threshold_hl][frequencies.indexOf(frequency)];
    const mildInput = Mildinput_spl[frequency];
    mild_calculated_value = calculate_value(mildTarget, mildInput);
    console.log(`Mid Target @ ${frequency} Hz: ${mild_calculated_value}`);
  } else {
    console.log("Frequency not found in the input SPL dictionary.");
  }

  let high_calculated_value;

  if (frequency in Highinput_spl) {
    const highTarget =
      Hightarget_spl[threshold_hl][frequencies.indexOf(frequency)];
    const highInput = Highinput_spl[frequency];
    high_calculated_value = highTarget - highInput;
    console.log(`High Target @ ${frequency} Hz: ${high_calculated_value}`);
  } else {
    console.log("Frequency not found in the input SPL dictionary.");
  }

  let ospl_calculated_value;

  if (frequency in microphone_effects) {
    const osplTarget =
      OSPLtarget_spl[threshold_hl][frequencies.indexOf(frequency)];
    ospl_calculated_value = osplTarget;
    console.log(`Target OSPL @ ${frequency} Hz: ${ospl_calculated_value}`);
  } else {
    console.log("Frequency not found in the input SPL dictionary.");
  }

  function calculate_exp_cr(
    env_dBex,
    exp_end_knee,
    tkgn,
    tk,
    cr,
    bolt,
    output_dBex
  ) {
    let pdb = env_dBex;
    let tk_tmp = tk;

    if (tk_tmp + tkgn > bolt) {
      tk_tmp = bolt - tkgn;
    }

    let tkgo = tkgn + tk_tmp * (1.0 - 1.0 / cr);
    let pblt = cr * (bolt - tkgo);
    let cr_const = 1.0 / cr - 1.0;

    let gain_at_exp_end_knee = tkgn;
    if (tk_tmp < exp_end_knee) {
      gain_at_exp_end_knee = cr_const * exp_end_knee + tkgo;
    }

    // Calculate gdb from output_dB
    let gdb = output_dBex - pdb;

    // Determine regime and back-calculate exp_cr if in expansion region
    let exp_cr;
    if (pdb < exp_end_knee) {
      let exp_cr_const = (gain_at_exp_end_knee - gdb) / (exp_end_knee - pdb);
      exp_cr = 1.0 / (exp_cr_const + 1.0);
    } else {
      exp_cr = 1.0;
    }

    return exp_cr;
  }

  function WDRC_circuit_gain(
    env_dBcr,
    exp_cr,
    exp_end_knee,
    tkgn,
    tk,
    cr,
    bolt
  ) {
    let gdb = 0.0;
    let tkgo = 0.0;
    let pblt = 0.0;
    let k = 0.0;
    let pdb = env_dBcr;
    let tk_tmp = tk;

    if (tk_tmp + tkgn > bolt) {
      tk_tmp = bolt - tkgn;
    }

    tkgo = tkgn + tk_tmp * (1.0 - 1.0 / cr);
    pblt = cr * (bolt - tkgo);
    let cr_const = 1.0 / cr - 1.0;

    let gain_at_exp_end_knee = tkgn;
    if (tk_tmp < exp_end_knee) {
      gain_at_exp_end_knee = cr_const * exp_end_knee + tkgo;
    }

    let exp_cr_const = 1.0 / Math.max(0.01, exp_cr) - 1.0;
    let regime = 0;
    if (pdb < exp_end_knee) {
      gdb = gain_at_exp_end_knee - (exp_end_knee - pdb) * exp_cr_const;
      regime = 0;
    } else if (pdb < tk_tmp && cr >= 1.0) {
      gdb = tkgn;
      regime = 1;
    } else if (pdb > pblt) {
      gdb = bolt + (pdb - pblt) / 10.0 - pdb;
      regime = 3;
    } else {
      gdb = cr_const * pdb + tkgo;
      regime = 2;
    }

    let output_dB = pdb + gdb;
    let output = [output_dB, regime];
    return output;
  }

  function find_best_cr(
    desired_output_dB,
    env_dBcr,
    exp_cr,
    exp_end_knee,
    tkgn,
    initial_tk,
    bolt
  ) {
    let best_cr = null;
    let best_difference = Infinity;
    let best_output = null;
    let best_tk = initial_tk;
    let tk = initial_tk;
    const tk_limit = 59;

    // Loop until tk is greater than or equal to tk_limit
    while (tk >= tk_limit) {
      // Loop through compression ratios from 0.1 to 3.0 with steps of 0.1
      for (let cr = 0.1; cr <= 3.0; cr += 0.1) {
        const output = WDRC_circuit_gain(
          env_dBcr,
          exp_cr,
          exp_end_knee,
          tkgn,
          tk,
          cr,
          bolt
        );
        const output_dB = output[0];
        const difference = Math.abs(output_dB - desired_output_dB);

        // Check if the current difference is the smallest we've found
        if (difference < best_difference) {
          best_difference = difference;
          best_cr = cr;
          best_output = output;
          best_tk = tk;
        }

        // If an exact match is found, return immediately
        if (difference === 0) {
          return { best_cr, best_output, best_tk: tk };
        }
      }

      // Decrease tk and try again
      tk -= 1;
    }

    return { best_cr, best_output, best_tk };
  }

  // Define input parameters
  let env_dBex = 40;
  let env_dBcr = 95;
  let exp_cr = 1.8;
  let exp_end_knee = 55;
  let initial_tk = 70;
  let tk = initial_tk;
  let cr = 0.8;
  let bolt = 130;

  let gainex = soft_calculated_value;
  let tkgn = mild_calculated_value;
  let Gaincr = high_calculated_value;

  // Calculate desired output
  let output_dBex = env_dBex + gainex;
  let desired_output_dB = env_dBcr + Gaincr;

  // Calculate expansion matching data
  exp_cr = calculate_exp_cr(
    env_dBex,
    exp_end_knee,
    tkgn,
    tk,
    cr,
    bolt,
    output_dBex
  );
  console.log("Expansion Matching Data");
  console.log("-----------------------");
  console.log(`Desired Output: ${output_dBex}`);
  console.log(`Best Ex: ${exp_cr}`);
  console.log(" ");

  // Find the best compression ratio
  let {
    best_cr,
    best_output,
    best_tk: final_tk,
  } = find_best_cr(
    desired_output_dB,
    env_dBcr,
    exp_cr,
    exp_end_knee,
    tkgn,
    initial_tk,
    bolt
  );
  console.log("Compression Matching Data");
  console.log("-------------------------");
  console.log(`Desired Output = ${desired_output_dB}`);
  console.log(
    `Best CR: ${best_cr}, Best Output: ${best_output}, Final TK: ${final_tk}`
  );
  console.log(" ");

  console.log("Target Dynamic Range Compression");
  console.log("--------------------------------");
  console.log(`ER: ${exp_cr}`);
  console.log(`Eknee: ${exp_end_knee}`);
  console.log(`LG: ${tkgn}`);
  console.log(`Cknee: ${final_tk}`);
  console.log(`CR: ${best_cr}`);
  console.log(`MPO: ${ospl_calculated_value}`);

  return {
    ER: exp_cr,
    Eknee: exp_end_knee,
    exp_end_knee: tkgn,
    Cknee: final_tk,
    CR: best_cr,
    MPO: ospl_calculated_value,
  };
}
