import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";

export const getComplainApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.fetchComplain,
    method: "get",
    headers: getHeaders(),
    params: data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addComplainApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.createComplain,
    method: "POST",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const deleteComplainField = async (data) => {
  const callResponse = await axios({
    url: endpoints.deleteComplain,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateComplainField = async (data) => {
  const callResponse = await axios({
    url: endpoints.updateComplain,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getComplainByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchComplainById,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getComplain = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchById,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
