import { Box, ButtonBase } from "@mui/material";
import { styled } from "@mui/system";
import { center } from "../../../assets/css/theme/common";

const CenteredBox = styled(Box)(() => ({
    ...center
}))
const FlexedColumnBox = styled(Box)(() => ({
    display: "flex", flexDirection: "column", flex: 1, overflow: "hidden"
}))

export { CenteredBox ,FlexedColumnBox}